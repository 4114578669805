<template>
  <div class="NavigationAnalytics">
    <div class="analytics-content">
      <div class="analytic-dashes">
        <p class="analytic-title">Navigation</p>
        <div class="dash-items">
          <div class="analytic-dash">
            <label for="title" class="title">Navigation behaviour 1</label>
            <div class="stats">
              <p class="stats-item">
                Go to calendar
                <span>10%</span>
              </p>
              <p class="stats-item">
                go to Avatar Shop
                <span>20%</span>
              </p>
              <p class="stats-item">
                go to Achievement
                <span>10%</span>
              </p>
              <p class="stats-item">
                attend bonus game
                <span>20%</span>
              </p>
              <p class="stats-item">
                attend surprise test
                <span>10%</span>
              </p>
              <p class="stats-item">
                attend bonus game/surprise test by scoring part
                <span>20%</span>
              </p>
              <p class="stats-item">
                attend bonus game/surprise test by main page tab
                <span>10%</span>
              </p>
            </div>
          </div>
          <div class="analytic-dash">
            <label for="title" class="title">Navigation behaviour 2</label>
            <div class="stats">
              <p class="stats-item">
                use note display change
                <span>10%</span>
              </p>
              <p class="stats-item">
                use staff display change
                <span>10%</span>
              </p>
              <p class="stats-item">
                use hand selection
                <span>10%</span>
              </p>
              <p class="stats-item">
                use accompaniment
                <span>10%</span>
              </p>
              <p class="stats-item">
                use hight-light function
                <span>10%</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationAnalytics",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>