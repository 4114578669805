<template >
    <div>

</div>
</template>

<script>










export default {
    props: ['rows', 'columns']
}
</script>

<style lang="scss" scoped></style>