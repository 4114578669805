// adminstrator

export const POST_GET_ADMIN_URL = "/administrator/users";
export const POST_ADD_ADMIN_URL = "/administrator/user/create";
export const POST_EDIT_ADMIN_URL = "/administrator/user/edit";
export const POST_UPDATE_ADMIN_URL = "/administrator/user/update";
export const POST_STATUS_ADMIN_URL = "/administrator/user/update/status";
export const POST_EXORT_ADMIN_URL = "/administrator/users/export";
export const POST_DELETE_ADMIN_URL = "/administrator/delete";
export const GET_PERMISSION_URL = "/administrator/permissions";
