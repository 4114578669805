<template>
    <div class="umgt-edit-educator">
        <h1><span @click="backToEducatorListtab"><i class=" fa fa-angle-left"></i></span> ADD NEW SCHOOL/TEACHER
            <span></span>
        </h1>
        <div class="form">
            <form @submit.prevent="AddNewEducator">
                <div class="single">
                    <label for="educatorName">School/Teacher Name</label>
                    <input type="text" required placeholder="Enter name" v-model="addForm
                        .EducatorName">
                </div>
                <div class="group">
                    <div class="single">
                        <label for="Contact Name">Contact Name</label>
                        <input type="text" required placeholder="Enter contact name" v-model="addForm
                            .contactName">
                    </div>
                    <div class="single">
                        <label for="ContactNo">Contact No.</label>
                        <input type="Enter contact number" required v-model="addForm
                            .contactNumber">
                    </div>
                    <div class="single">
                        <label for="ContactEmail">Contact Email</label>
                        <input type="email" required placeholder="Enter email" v-model="addForm
                            .contactEmail">
                    </div>
                </div>
                <div class="group">
                    <div class="single">
                        <label for="">Username</label>
                        <input type="text" required placeholder="John Doe" v-model="addForm
                            .userName">
                    </div>
                    <div class="single">
                        <label for="ContactNo">Password</label>
                        <input type="password" required placeholder="*******" v-model="addForm
                            .password">
                    </div>
                </div>
                <div class="group">
                    <div class="single">
                        <label for="Contact Name">Link to channel</label>
                        <select v-model="addForm
                            .channelId">
                            <option v-for="channel in channelsList" :key="channel.channelId" :value="channel.channelId">{{
                                channel.channelName }}
                            </option>
                        </select>
                    </div>
                    <div class="single">
                        <label for="Contact Name">Region</label>
                        <select region v-model="addForm
                            .region">
                            <option v-for="Region in RegionList" :key="Region.code" :value="Region.region">{{ Region.region
                            }}
                            </option>
                        </select>
                    </div>
                    <div class="single">
                        <label for="">Status</label>
                        <div class="statusCheck">
                            <label for="status" @click="educatorStus" :class="eduStatus ? 'special' : ''">
                                <div></div>
                            </label>
                            <input type="checkbox" id="status">
                        </div>
                    </div>
                </div>
                <div class="single">
                    <label for="ContactEmail">Users</label>
                    <button type="button" @click="showUsers = !showUsers"> + Add user</button>
                    <template v-if="UserList.length > 0">
                        <div class="userAdd" v-if="showUsers">
                            <div class="search">
                                <i class="fa fa-search"></i>
                                <input type="text" v-model="searchTerm" placeholder="Search User">
                            </div>
                            <ul>
                                <template v-for="sch in filteredUser()">
                                    <li :key="sch.studentId" v-if="sch.studentName.length > 2"
                                        @click="selectUser(sch, $event)">
                                        {{
                                            sch.studentName }}</li>
                                </template>

                            </ul>
                        </div>
                    </template>
                    <template v-else>
                        <div class="userAdd" v-if="showUsers">
                            <ul>
                                <li>All users have been assigned to schools/teachers proceed to link them later!</li>
                            </ul>
                        </div>
                    </template>

                </div>
                <div class="single">
                    <label for="ContactEmail">Users Name</label>
                    <table>
                        <tr v-for="newUsers in newUserList" :key='newUsers.id' class="delete_selected_list">
                            <td @click="unselectUser(newUsers, $event)">{{ newUsers.studentName }}
                                <i class="fa fa-trash"></i>
                            </td>
                        </tr>
                    </table>
                </div>


                <div class="action">
                    <button>
                        <Spinner size="18" v-if="isRequesting"></Spinner>
                        <span v-if="!isRequesting">Save changes</span>
                    </button>
                    <button type="button" @click="backToEducatorListtab">Cancel</button>
                </div>
            </form>
        </div>
</div>
</template>

<script>










import Spinner from 'vue-simple-spinner';
export default {
    components: {
        Spinner
    },
    data() {
        return {
            showUsers: false,
            eduStatus: 0,
            RegionList: [],
            searchTerm: '',
            UserList: [],
            isRequesting: false,
            newUserList: [],
            channelsList: [],
            addForm: {
                EducatorName: '',
                contactName: '',
                contactNumber: '',
                contactEmail: '',
                channelId: null,
                password: '',
                users: [],
                status: '',
                students: [],
            }
        }
    },
    created() {
        let vm = this;
        vm.getRegions();
        vm.getAllChannels();
        vm.getUnsignnedUser();
    },
    methods: {
        backToEducatorListtab() {
            let vm = this;
            vm.$store.commit('VIEW_SCHLTEACHER_LIST')
        },
        getRegions() {
            let vm = this;
            vm.$store.dispatch('GET_REGIONS')
                .then(res => {
                    vm.RegionList = res.data.regions
                }).catch(e => {
                    console.log(e)
                })
        },
        getUnsignnedUser() {
            let vm = this;
            vm.$store.dispatch('GET_EDUCATORS_UNASSIGNED_USERS')
                .then(res => {
                    vm.UserList = res.data.students
                }).catch(e => {
                    console.log(e)
                })
        },
        filteredUser(e) {
            let vm = this;
            return vm.UserList.filter(el => el.studentName.toLowerCase().includes(vm.searchTerm.toLowerCase()))
        },
        educatorStus(e) {
            let vm = this;
            if (vm.eduStatus) {
                vm.eduStatus = 0
                e.target.classList.remove('special');
            } else {
                vm.eduStatus = 1;
                e.target.classList.add('special')
            }
        },
        getAllChannels() {
            let vm = this;
            vm.$store.dispatch('POST_GET_CHANNELS', {
                "Page": 1,
                "Size": 100,
                "OrganizationId": 2,
                "searchQuery": ""
            })
                .then(res => {
                    vm.channelsList = res.data?.channels;
                }).catch(e => {
                    console.log(e)
                })
        },
        selectUser(schl, e) {
            let vm = this;
            vm.UserList.splice(vm.UserList.indexOf(schl), 1)
            vm.newUserList.unshift(schl);
        },
        unselectUser(schl, e) {
            let vm = this;
            vm.newUserList.splice(vm.newUserList.indexOf(schl), 1)
            vm.UserList.unshift(schl)
        },
        AddNewEducator() {
            let vm = this;
            let userIds = [];
            vm.isRequesting = true;
            vm.newUserList.forEach(el => {
                userIds.push(el.studentId)
            });
            vm.addForm.students = userIds;
            vm.addForm.status = vm.eduStatus;
            vm.$store.dispatch('POST_EDUCATOR_CREATE', vm.addForm)
                .then(res => {
                    vm.$toast.success("Educator added successful")
                    vm.isRequesting = false;
                    vm.$store.commit('LIST_REFRESHER_FOR_CHANNEL');
                    // vm.$emit('triggered');
                    vm.backToEducatorListtab();
                }).catch(e => {
                    vm.isRequesting = false;
                    if (e.response.status == 400) {
                        this.$toast.error(e.response.data.error)
                    } else {
                        vm.$toast.error("Something went wrong")
                    }

                })
        }

    },
}
</script>

<style lang="scss" scoped>
@import '../stylesConst/fonts.scss';

.umgt-edit-educator {
    padding-left: 30px;
    padding-right: 30px;

    h1 {
        color: $secondary_font_color;
        text-align: center;
        font-size: 16px;
        margin-bottom: 40px;
        margin-top: 40px;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        justify-content: space-between;

        i {
            cursor: pointer
        }
    }

    .form {
        .single {

            position: relative;

            .educatorReport {
                margin-top: -30px;
                font-size: 15px;
                margin-bottom: 32px;

                i {
                    margin-right: 8px;
                }

                span {
                    text-decoration: underline;
                }

                cursor: pointer;
            }

            label {
                display: block;
                font-size: 18px;
                color: $primary_font_color;
                font-weight: bold;
                margin-bottom: 15px;

            }

            button {
                margin-bottom: 50px;
                padding: 10px;
                width: 200px;
                text-align: start;
                cursor: pointer;

            }

            table {
                width: 300px;
                max-height: 200px !important;
                overflow-y: auto;
                font-size: 14px;

                tr:nth-child(odd) {
                    background: rgb(232, 232, 232);
                }

                tr {
                    td {
                        padding: 8px 4px;
                    }
                }

                .delete_selected_list {

                    padding: 0 6px;

                    td {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;

                        i {
                            color: red;
                        }
                    }
                }
            }

            .userAdd {
                height: 200px;
                width: 250px;
                overflow-y: auto;
                overflow-x: hidden;
                border-radius: 4px;
                position: absolute;
                top: 40px;
                left: 200px;
                z-index: 100;
                background: white;
                box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);

                .search {
                    width: 100%;
                    height: 32px;
                    display: flex;
                    padding: 4px;
                    border-bottom: 1px solid rgb(232, 232, 232);
                    position: sticky;
                    top: 0;
                    background: white;

                    i {
                        padding-left: 4px;
                    }

                    input {
                        width: 100%;
                        padding-left: 2px;
                        height: 100%;
                        border: none;
                        font-size: 16px;

                    }
                }

                ul {
                    list-style: none;
                    padding: 4px;

                    li {
                        padding: 4px 6px;
                        font-size: 16px;

                        &:hover {
                            background: $secondary_hover_color;
                        }

                        &.selected {
                            background: $secondary_hover_color;
                        }
                    }
                }
            }
        }

        .action {
            display: flex;
            column-gap: 10px;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 40px;

            button {
                padding: 8px;
                width: 120px;
                border-radius: 4px;
                background: $primary_bg_color;
                font-weight: bold;
                color: $secondary_font_color;
                cursor: pointer;

                &:last-of-type {
                    background: rgb(232, 232, 232);
                }

                border: none;
            }
        }

        .group {
            display: flex;
            flex-wrap: wrap;
            column-gap: 30px;
        }

        input {
            padding: 10px;
            border: none;
            outline: none;
            margin-bottom: 50px;
            border: 1px solid rgb(205, 205, 205);
            cursor: pointer;


            &:focus {
                border: 1px solid $primary_bg_color;

            }


        }

        select {
            padding: 10px;
            border: none;
            outline: none;
            width: 200px;
            border-radius: 4px;
            cursor: pointer;
            border: 1px solid rgb(205, 205, 205);

            &:focus {
                border: 1px solid $primary_bg_color;

            }

            option {
                padding: 10px 2px;
            }
        }
    }
}

.statusCheck {
    display: flex;
    justify-content: center;
    cursor: pointer;

    label {
        height: 20px;
        width: 40px;
        background: rgb(194, 194, 194);
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        div {
            height: 20px;
            width: 20px;
            background: rgb(235, 233, 233);
            border-radius: 50%;
            position: relative;
            pointer-events: none;
            right: 0;
            transition: all 0.2s ease;

        }

    }

    input {
        visibility: hidden;
    }
}

.special {
    background: $primary_bg_color !important;

    div {
        right: -20px !important;
    }
}
</style>