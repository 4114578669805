// Manage Libraries
export const ADD_LIBRARY_URL = "/admin/modules/add";
export const GET_LIBRARIES_URL = "/admin/modules/all";
export const UPDATE_LIBRARY_URL = "/admin/modules/update";
export const DELETE_LIBRARY_URL = "/admin/modules/delete/";

// upload file
export const UPLOAD_FILE_URL = "/files/upload";

// common
export const GET_GRADES_URL = "/academic-standing/grades";
export const GET_LEVELS_URL = "/academic-standing/levels/";
export const GET_STAGES_URL = "/academic-standing/stages/";

// scores
export const ADD_SCORE_URL = "/admin/score/library/add";
export const GET_SCORES_URL = "/admin/score/library/get/all";
export const UPDATE_SCORE_URL = "/admin/score/library/update/";
export const DELETE_SCORE_URL = "/admin/score/library/delete/";
export const DELETE_SELECTED_SCORES_URL = "/admin/score/library/delete/selected";
export const EXPORT_SCORES_URL = "/admin/score/library/export/selected";
export const TOGGLE_SCORE_URL = "/admin/score/library/toggle/";
export const UPLOAD_SCORE_FILE_URL = "/admin/score/library/upload";
