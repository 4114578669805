import {
  AXIOS_POST,
  AXIOS_POST_DOWNLOAD,
} from "./../../Config/Axios_UserMagment/axios.js";
import {
  POST_USERS_GET,
  POST_USER_UPDATE,
  POST_USER_EXPORT,
  POST_USER_UPDATE_STATUS,
  POST_USERS_DELETE,
  POST_USER_EDIT,
} from "./../actions/User.js";
import {
  POST_USERS_GET_URL,
  POST_USER_UPDATE_URL,
  POST_USER_EXPORT_URL,
  POST_USER_UPDATE_STATUS_URL,
  POST_USERS_DELETE_URL,
  POST_USER_EDIT_URL,
} from "./../Variables.js";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [POST_USERS_GET]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_USERS_GET_URL, payload, rootState.token);
  },
  [POST_USER_UPDATE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_USER_UPDATE_URL, payload, rootState.token);
  },
  [POST_USER_EDIT]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_USER_EDIT_URL, payload, rootState.token);
  },
  [POST_USER_EXPORT]: ({ rootState }, payload) => {
    return AXIOS_POST_DOWNLOAD(POST_USER_EXPORT_URL, payload, rootState.token);
  },
  [POST_USER_UPDATE_STATUS]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_USER_UPDATE_STATUS_URL, payload, rootState.token);
  },
  [POST_USERS_DELETE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_USERS_DELETE_URL, payload, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
