import {
  AXIOS_POST,
  AXIOS_POST_DOWNLOAD,
  AXIOS_GET,
} from "../../Config/Axios_UserMagment/axios.js";
import {
  POST_EDUCATOR_CREATE,
  POST_EDUCATOR_UPDATE,
  POST_GET_EDUCATORS,
  POST_EDUCATORS_EXPORT,
  POST_EDUCATORS_DELETE,
  POST_EDUCATOR_STATUS_UPDATE,
  POST_EDUCATOR_REPORT,
  GET_EDUCATORS_UNASSIGNED_USERS,
  POST_EDUCATOR_EDIT,
} from "../actions/Educators.js";
import {
  POST_EDUCATOR_CREATE_URL,
  POST_EDUCATOR_UPDATE_URL,
  POST_GET_EDUCATORS_URL,
  POST_EDUCATORS_EXPORT_URL,
  POST_EDUCATORS_DELETE_URL,
  POST_EDUCATOR_STATUS_UPDATE_URL,
  POST_EDUCATOR_REPORT_URL,
  GET_EDUCATORS_UNASSIGNED_USERS_URL,
  POST_EDUCATOR_EDIT_URL,
} from "../Variables.js";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [POST_EDUCATOR_CREATE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_EDUCATOR_CREATE_URL, payload, rootState.token);
  },
  [POST_EDUCATOR_UPDATE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_EDUCATOR_UPDATE_URL, payload, rootState.token);
  },
  [POST_EDUCATOR_EDIT]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_EDUCATOR_EDIT_URL, payload, rootState.token);
  },
  [POST_GET_EDUCATORS]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_GET_EDUCATORS_URL, payload, rootState.token);
  },
  [POST_EDUCATORS_EXPORT]: ({ rootState }, payload) => {
    return AXIOS_POST_DOWNLOAD(
      POST_EDUCATORS_EXPORT_URL,
      payload,
      rootState.token
    );
  },
  [POST_EDUCATORS_DELETE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_EDUCATORS_DELETE_URL, payload, rootState.token);
  },
  [POST_EDUCATOR_STATUS_UPDATE]: ({ rootState }, payload) => {
    return AXIOS_POST(
      POST_EDUCATOR_STATUS_UPDATE_URL,
      payload,
      rootState.token
    );
  },
  [POST_EDUCATOR_REPORT]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_EDUCATOR_REPORT_URL, payload, rootState.token);
  },
  [GET_EDUCATORS_UNASSIGNED_USERS]: ({ rootState }, payload) => {
    return AXIOS_GET(GET_EDUCATORS_UNASSIGNED_USERS_URL, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
