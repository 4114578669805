import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/admin/Login.vue";
import Dashboard from "../views/admin/Dashboard.vue";
import ScoreLibraryDashboard from "../views/admin/ScoreLibrary/ScoreLibraryDashboard.vue";
import ScoreLibraryLogin from "../views/admin/ScoreLibrary/ScoreLibraryLogin.vue";
import ManagementDashBoard from "../views/admin/UserManagment/ManagementDashBoard.vue";
import ScoreLibraryUpload from "../views/admin/ScoreLibrary/ScoreLibraryUpload.vue";
import EmailSupport from "../views/admin/SupportMailbox/EmailSupport.vue";
// import Kanbanboard from "../views/admin/SupportMailbox/Kanbanboard.vue";
import ManageLibraries from "../views/admin/ScoreLibrary/ManageLibraries.vue";
import Administrator from "../views/admin/Administrator/AdministratorDashboard.vue";
import AdminProfile from "../views/admin/AdminProfile.vue";
import Subscriptions from "../views/admin/UserManagment/Subscriptions/Subscriptions.vue";
import ForgotPassword from "../views/admin/Forgot.vue";


// Analytics
import Overview from "../views/admin/Analytics/Overview.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/settings/profile",
    name: "AdminProfile",
    component: AdminProfile,
  },
  {
    path: "/forgot-password/:token",
    name: "ResetPassword",
    component: ForgotPassword,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },

  // score library
  {
    path: "/score-library/dashboard",
    name: "ScoreLibraryDashboard",
    component: ScoreLibraryDashboard,
  },
  {
    path: "/score-library/:id/upload",
    name: "ScoreLibraryUpload",
    component: ScoreLibraryUpload,
  },
  {
    path: "/score-library/:id/upload",
    name: "ScoreLibraryUpload",
    component: ScoreLibraryUpload,
  },
  {
    path: "/score-library/manage",
    name: "ManageLibraries",
    component: ManageLibraries,
  },

  // user managment
  {
    path: "/user-management",
    name: "user-management",
    component: ManagementDashBoard,
  },
  {
    path: "/manage-subscription",
    name: "manage-subscription",
    component: Subscriptions,
  },
  // email support
  {
    path: "/support/email-support",
    name: "email-support",
    component: EmailSupport,
  },
  // Administrator
  {
    path: "/administrator",
    name: "administrator",
    component: Administrator,
  },
  // Analytics
  {
    path: "/analytics/:item",
    name: "AnalyticsOverview",
    component: Overview,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
