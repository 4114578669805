<template>
  <div class="ExportScores">
    <div class="dialog">
      <!-- close button -->
      <div class="close-button">
        <i class="fa fa-times" @click="$emit('close')"></i>
      </div>
      <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1100"
        filename="EEMusic Scores"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @progress="progress"
        @hasStartedGeneration="progress"
        @hasGenerated="$stopLoader()"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div class="title-section">
            <h3>{{ exportData.module.name }}</h3>
            <p>
              {{ exportData.curriculum }} - {{ exportData.grade.gradeName }},
              {{ exportData.level.levelName }},
              {{ exportData.stage.stageName }} Score<span
                v-if="scores.length > 1"
                >s</span
              >
            </p>
          </div>
          <div v-for="score in scores" :key="score.id" class="scores-preview">
            <hr />
            <h4 class="score-name">{{ score.name }}</h4>
            <PreviewScore
              :filename="scores_base_url + score.filePath"
            ></PreviewScore>
          </div>
        </section>
      </vue-html2pdf>
      <div class="buttons">
        <button @click="generateReport()" class="generate-btn">
          Export PDF <i class="fa fa-print"></i>
        </button>
        <button class="close-btn" @click="$emit('close')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  props: {
    scores: {
      type: Array,
      default: () => [],
    },
    exportData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      scores_base_url: this.$store.state.scores_base_url,
    };
  },
  methods: {
    // generatePdf() {
    //   this.$html2pdf()
    //     .set({
    //       margin: 1,
    //       filename: "myfile.pdf",
    //       image: { type: "jpeg", quality: 0.98 },
    //       html2canvas: { dpi: 192, letterRendering: true },
    //       jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    //     })
    //     .from(this.$refs.pdfContent)
    //     .save();
    // },
    generateReport() {
      this.$startLoader();
      this.$refs.html2Pdf.generatePdf();
    },
    progress(progress) {
      console.log(progress);
      if (progress === 100) {
        this.$stopLoader();
      }
    },
  },
  components: {
    VueHtml2pdf,
  },
};
</script>

<style lang="scss" scoped>
.ExportScores {
  $EEskyblue: #3dd1d1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  background: #0000002d;
  .dialog {
    width: 850px;
    height: 85vh;
    background: white;
    position: relative;
    overflow-y: auto;
    top: 0;
    left: 0;
    padding-top: 3rem;
    margin-top: 3rem;
    .close-button {
      position: fixed;
      top: 10px;
      right: 10px;
      font-size: 20px;
      cursor: pointer;
    }
    .buttons {
      position: fixed;
      top: 10px;
      right: 50%;
      display: flex;
      column-gap: 10px;
      transform: translate(50%);
      button {
        width: 170px;
        transition: all 0.3s ease-in-out;
      }
      .generate-btn {
        font-size: 18px;
        cursor: pointer;
        background: orange;
        color: black;
        padding: 10px 25px;
        border-radius: 5px;
        border: none;
        &:hover {
          background: #249a9a;
        }
      }
      .close-btn {
        font-size: 18px;
        cursor: pointer;
        background: #1f1f1f;
        color: white;
        padding: 10px 25px;
        border-radius: 5px;
        border: none;
        &:hover {
          background: #b3b3b3;
        }
      }
    }
  }
}
.title-section {
  h3 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  p {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}
.score-name {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  width: 100%;
}
.scores-preview {
  margin: 0 2rem;
}
</style>