<template>
  <div class="Dashboard">
    <div class="dashboard-wrapper">
      <div class="top-section">
        <label for class="title" v-if="user.fullName"
          >Welcome back {{ user.fullName }}</label
        >
        <label for class="title" v-else>Welcome back Admin</label>
        <div class="img">
          <img
            :src="$store.state.assets_base_url + user.fileName"
            v-if="user.fileName"
          />
          <img src="@/assets/logo/stamp.png" v-else />
        </div>
        <label for class="name"></label>
      </div>
      <div class="body-section">
        <ul class="modules">
          <li v-if="checkPermission('SL')">
            <router-link :to="{ name: 'ScoreLibraryDashboard' }"
              >Score Library</router-link
            >
          </li>
          <li v-if="checkPermission('CM')">
            <router-link to="#">Content Management</router-link>
          </li>
          <li v-if="checkPermission('UM')">
            <router-link :to="{ name: 'user-management' }"
              >User Management</router-link
            >
          </li>
          <li v-if="checkPermission('DA')">
            <router-link
              :to="{ name: 'AnalyticsOverview', params: { item: 'overview' } }"
              >Data Analytics</router-link
            >
          </li>
          <li v-if="checkPermission('SM')">
            <router-link to="#">Sales Management</router-link>
          </li>
          <li v-if="checkPermission('ES')">
            <router-link :to="{ name: 'email-support' }"
              >Support Mailbox</router-link
            >
          </li>
          <li v-if="checkPermission('AD')">
            <router-link :to="{ name: 'administrator' }"
              >Permissions</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <AdminRegFooter />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Login",
  components: {},
  data() {
    return {
      user: {},
      userId: null,
      permissions: [],
    };
  },
  methods: {
    getProfile() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROFILE", { userId: this.userId })
        .then((res) => {
          this.$stopLoader();
          this.user = res.data.user;
          this.permissions = res.data.user.permissions;
          if (this.permissions.length === 0) {
            this.$showNotification(
              "You are not allowed to access any admin module. Please contact administrator."
            );
            this.$adminlogout();
          }
        })
        .catch((err) => {
          console.log("f");
          // check if error code is 401 and logout
          if (err.response.status === 401) {
            this.$adminlogout();
            this.$stopLoader();
          }
        });
    },
    checkPermission(permission) {
      // check if user has permission where permission.code === permission
      return this.permissions.some((p) => p.code === permission);
    },
  },
  mounted() {
    // check if user is logged in
    if (!this.$loggedAdmin()) {
      this.$adminlogout();
    } else {
      this.userId = this.$loggedAdmin().userId;
      this.getProfile();
    }
  },
};
</script>
<style lang="scss" scoped>
.Dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .dashboard-wrapper {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    flex-wrap: wrap;
    .top-section {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 1.65rem;
        font-weight: 400;
        margin-bottom: 1rem;
      }

      .img {
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        min-height: 100px;
        min-width: 100px;
        img {
          border-radius: 50%;
          margin-top: 2rem;
          margin-bottom: 2rem;
          height: 100%;
          width: 100%;
          object-fit: cover;
          display: block;
          object-position: center;
        }
      }

      .name {
        font-size: 1.5rem;
        font-weight: 500;
        margin-top: 1rem;
      }
    }

    .body-section {
      width: 500px;
      margin-top: 4rem;

      .modules {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
        align-items: center;
        grid-row-gap: 10px;
        grid-column-gap: 10px;

        li {
          height: 10%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          &.disabled {
            opacity: 0.35;
            // pointer-events: none;
            // cursor: not-allowed;
            a {
              cursor: no-drop !important;
            }
          }
          a {
            min-height: 70px;
            border-radius: 10px;
            background: #eee;
            padding: 10px;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            font-size: 1rem;
            font-weight: bold;
          }
        }

        .router-link-active {
          background: #777;
          color: #fff;
        }
      }
    }
  }
}
</style>
