<template>
  <div class="umgt-container">
    <div class="umgt-filter">
      <div class="umgt-action-container">
        <div class="setting">
          <i class="fa-solid fa-sliders"></i>
        </div>
        <div class="umgt-search">
          <input type="text" @change="searchList" placeholder="search" />
          <i class="fa fa-search"></i>
        </div>
      </div>
      <div class="umgt-action">
        <div
          class="umgt-add"
          @click="showAdd = !showAdd"
          style="position: relative"
        >
          <i class="fa-solid fa-square-plus"></i>
          <h6>Add</h6>
          <div class="add-buttons" v-if="showAdd === true">
            <p>Add to which Category</p>
            <div class="buttons">
              <button @click="showAddchannel">Channel</button>
              <button @click="showAddSchTeacher" class="active">
                School/Teacher
              </button>
            </div>
          </div>
        </div>
        <div class="umgt-export" @click="exportModule">
          <i class="fa-solid fa-file-export"></i>
          <h6>Export</h6>
        </div>
        <div class="umgt-export" @click="$router.push('/manage-subscription')">
          <i class="fa fa-gear"></i>
          <h6>Manage Subscriptions</h6>
        </div>
      </div>
    </div>
    <div class="umgt-tables">
      <div class="tables">
        <div class="umgt-table-btn">
          <button
            :class="channelactive ? 'active' : ''"
            @click="compactivate('channel', (activeLink = 'Channel'))"
          >
            Channel Name
          </button>
          <button
            :class="teacherActive ? 'active' : ''"
            @click="compactivate('teacher', (activeLink = 'SchTeacher'))"
          >
            School/Teacher
          </button>
          <button
            :class="userActive ? 'active' : ''"
            @click="compactivate('user'), (activeLink = 'User')"
          >
            Users
          </button>
        </div>
        <keep-alive>
          <Channel
            v-if="activeLink === 'Channel'"
            @specificScTeacher="specificScTeacher"
          />
          <SchTeacher
            v-if="activeLink === 'SchTeacher'"
            :channelId="emittedchannelId"
            @specificUsers="specificUsers"
          />
          <Users
            v-if="activeLink === 'User'"
            :SchlTeacherId="emittedSchlTeacherId"
          />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "./userlist/Users.vue";
import Channel from "./channels/Channel.vue";
import SchTeacher from "./schoolTeachers/SchTeacher.vue";
export default {
  components: { Channel, SchTeacher, Users },
  data() {
    return {
      channelactive: true,
      teacherActive: false,
      userActive: false,
      activeLink: "Channel",
      showAdd: false,
      emittedchannelId: 0,
      emittedSchlTeacherId: 0,
    };
  },
  methods: {
    showAddchannel() {
      let vm = this;
      vm.showAdd = false;
      vm.activeLink = "Channel";
      vm.channelactive = true;
      vm.teacherActive = false;
      vm.userActive = false;
      vm.$store.commit("VIEW_CHANNEL_ADD");
    },
    showAddSchTeacher() {
      let vm = this;
      vm.showAdd = false;
      vm.activeLink = "SchTeacher";
      vm.channelactive = false;
      vm.teacherActive = true;
      vm.userActive = false;
      vm.$store.commit("VIEW_SCHLTEACHER_ADD");
    },
    specificScTeacher(id) {
      let vm = this;
      vm.emittedchannelId = id;
      vm.channelactive = false;
      vm.teacherActive = true;
      vm.userActive = false;
      vm.activeLink = "SchTeacher";
    },
    specificUsers(id) {
      let vm = this;
      vm.emittedSchlTeacherId = id;
      vm.channelactive = false;
      vm.teacherActive = false;
      vm.userActive = true;
      vm.activeLink = "User";
    },
    searchList(e) {
      let vm = this;
      vm.$store.state.searchTerm = e.target.value;
    },
    compactivate(active) {
      let vm = this;
      vm.emittedchannelId = 0;
      vm.emittedSchlTeacherId = 0;
      if (active === "channel") {
        vm.channelactive = true;
        vm.teacherActive = false;
        vm.userActive = false;
        vm.$store.commit("VIEW_CHANNEL_LIST");
      }
      if (active === "teacher") {
        vm.channelactive = false;
        vm.teacherActive = true;
        vm.userActive = false;
        vm.$store.commit("VIEW_SCHLTEACHER_LIST");
      }
      if (active === "user") {
        vm.channelactive = false;
        vm.teacherActive = false;
        vm.userActive = true;
        vm.$store.commit("VIEW_STUDENT_LIST");
      }
    },
    exportModule() {
      let vm = this;
      if (vm.channelactive) {
        vm.$store
          .dispatch("POST_CHANNELS_EXPORT")
          .then((response) => {
            return new Blob([response.data]);
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Channel.xlsx";
            link.click();
          })
          .catch((e) => {
            console.log(e);
          });
      }
      if (vm.teacherActive) {
        vm.$store
          .dispatch("POST_EDUCATORS_EXPORT")
          .then((response) => {
            return new Blob([response.data]);
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Educators.xlsx";
            link.click();
          })
          .catch((e) => {
            console.log(e);
          });
      }
      if (vm.userActive) {
        vm.$store
          .dispatch("POST_USER_EXPORT")
          .then((response) => {
            return new Blob([response.data]);
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Users.xlsx";
            link.click();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./stylesConst/fonts.scss";

.umgt-container {
  height: calc(100% - 100px);
  font-size: 22px;

  .umgt-filter {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
    color: $secondary_font_color;
    padding-left: 20px;
    font-size: 15px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    i {
      margin-right: 16px;
    }

    .umgt-action-container {
      i {
        margin-right: 15px;
      }

      .umgt-search {
        background: white;
        padding: 10px;
        border-radius: 8px;

        input {
          border: none;
          outline: none;
          font-size: 14px;
        }
      }
    }

    .umgt-action {
      display: flex;
      position: relative;

      .umgt-add-box {
        width: 250px;
        padding: 20px;
        position: relative;
        background: rgb(255, 255, 255);
        z-index: 2;
        border-radius: 8px;
        display: block;
        text-align: center;
        color: rgb(136, 133, 133);
        box-shadow: 2px 3px 12px rgb(216, 216, 216),
          -2px -3px 12px rgb(216, 216, 216);
        transition: all 0.5s ease;

        h1 {
          font-size: 14px;
          margin-bottom: 10px;
          color: $secondary_font_color;
        }

        .btn-container {
          display: flex;
          column-gap: 4px;

          button {
            padding: 5px;
            flex: 1;
            border-radius: 4px;
            border: none;
            color: $secondary_font_color;
            box-shadow: 2px 3px 12px rgb(216, 216, 216),
              -2px -3px 12px rgb(216, 216, 216);
            cursor: pointer;
            font-weight: 600;

            &:last-of-type {
              background: $primary_bg_color;
            }
          }
        }

        .arrow {
          position: absolute;
          height: 20px;
          width: 20px;
          background: rgb(255, 255, 255);
          right: -4px;
          transform: rotate(45deg);
          top: 40%;
          z-index: -1;
        }
      }

      .umgt-add {
        cursor: pointer;
        margin-left: 10px;

        i {
          margin: 0;
        }

        h6 {
          margin-left: 8px;
        }
      }

      .umgt-export {
        margin-left: 20px;
        cursor: pointer;

        i {
          margin: 0;
        }

        h6 {
          margin-left: 8px;
        }
      }
    }
  }

  .umgt-tables {
    position: relative;
    width: 100%;
    height: calc(100% - 70px);
    overflow-x: auto;
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      width: 16px;
      background: #d2d0d0;
      margin: 0;
      padding: 0;
    }

    &::-webkit-scrollbar-thumb {
      cursor: pointer !important;
      border-radius: 8px;
      background: $primary_bg_color;
      height: 150px;
      margin: 0;
      padding: 0;
      border: none;
    }

    .tables {
      width: 95%;
      background: rgb(255, 255, 255);
      border: none;
      border-radius: 15px;
      margin: 0 auto;
      padding: 10px;
      box-shadow: 2px 3px 12px rgb(216, 216, 216),
        -2px -3px 12px rgb(216, 216, 216);
      min-height: 100%;
      overflow-x: auto;

      .umgt-table-btn {
        display: flex;
        column-gap: 4px;

        button {
          border: none;
          padding: 10px;
          outline: none;
          background: $primary_bg_color;
          color: $secondary_font_color;
          flex: 1;
          font-weight: bold;
          cursor: pointer;
          border-radius: 4px;
          transition: all 0.1s linear;

          &.active {
            background: $primary_hover_color;
          }

          &:hover {
            background: $primary_hover_color;
          }
        }
      }
    }
  }
}
.add-buttons {
  display: flex;
  column-gap: 4px;
  margin-top: 10px;
  flex-direction: column;
  background: white;
  padding: 20px 10px;
  border-radius: 10px;
  position: absolute;
  box-shadow: 2px 3px 12px rgb(206, 206, 206);
  top: 60%;
  z-index: 999;
  right: 50%;
  width: 215px;
  .buttons {
    display: flex;
    column-gap: 10px;
    margin-top: 10px;
    button {
      border: none;
      padding: 4px 10px;
      outline: none;
      color: $secondary_font_color;
      flex: 1;
      font-weight: bold;
      cursor: pointer;
      border-radius: 4px;
      transition: all 0.1s linear;
      font-weight: 500;
      &.active {
        background: $primary_font_color;
        color: #fff;
      }

      &:hover {
        background: $primary_hover_color;
        color: $secondary_font_color;
      }
    }
  }
}
</style>