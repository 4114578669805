<template>
  <div id="chart" v-if="chartSeries">
    <apexchart
      type="area"
      height="250"
      :options="chartOptions"
      :series="chartSeries"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    series: {
      type: Object,
      default: () => [{}],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    listName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "basic-line",
          width: 350,
        },
        xaxis: {
          categories: [],
        },
        colors: ['#3dd1d1'],
      },
      chartSeries: null,
    };
  },
  mounted() {
    // this.options.series = this.series;
    this.chartOptions.xaxis.categories = this.labels;
    // this.chartSeries[0].name = this.listName;
    // this.chartSeries[0] = this.series;
    // make chartSeries an array of objects
    this.chartSeries = [];
    this.chartSeries.push(this.series);
  },
};
</script>

<style>
</style>