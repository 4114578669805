<template>
    <div class="umgt-report-channel">
        <h1>
            <span @click="backToChannelListtab"><i class=" fa fa-angle-left"></i></span>
            Report
            <span>
                <!-- <i class="fa fa-download"></i> -->
            </span>
        </h1>
        <div class="monthly_user_report">
            <h1>Monthly User Report </h1>
            <table>
                <template v-if="JSON.stringify(ReportData) !== '{}'">
                    <tr>
                        <th></th>
                        <th v-for="(month, i) in ReportData.months" :key="i">{{ month
                        }}</th>

                    </tr>
                    <tr>
                        <td>Commission</td>
                        <td v-for="(commission, i) in ReportData.commission" :key="i">{{ commission }}</td>

                    </tr>
                    <tr>
                        <td>No. of schools</td>
                        <td v-for="(no_of_schools, i) in ReportData.no_of_schools" :key="i">{{ no_of_schools }}</td>

                    </tr>
                    <tr>
                        <td>No. of user</td>
                        <td v-for="(no_of_users, i) in ReportData.no_of_users" :key="i">{{ no_of_users }}</td>

                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <th></th>
                        <th v-for="(month, i) in 12" :key="i"><skeleton-loader :width="60" :height="10" type="rect"
                                animation="fade" style="margin: 0 auto" />
                        </th>

                    </tr>
                    <tr>
                        <td>Commission</td>
                        <td v-for="(month, i) in 12" :key="i"><skeleton-loader :width="60" :height="10" type="rect"
                                animation="fade"
                                style="
                                                                                                                        margin: 0 auto" />
                        </td>

                    </tr>
                    <tr>
                        <td>No. of schools</td>
                        <td v-for="(month, i) in 12" :key="i"><skeleton-loader :width="60" :height="10" type="rect"
                                animation="fade"
                                style="
                                                                                                                        margin: 0 auto" />
                        </td>

                    </tr>
                    <tr>
                        <td>No. of user</td>
                        <td v-for="(month, i) in 12" :key="i"><skeleton-loader :width="50" :height="10" type="rect"
                                animation="fade" style="margin: 0 auto" />
                        </td>

                    </tr>
                </template>

            </table>
        </div>

        <div class="detailed_breakdown">
            <h1>Detailed breakdown </h1>
            <table>
                <tr>
                    <th>School name</th>
                    <th>No. of students</th>
                    <th>Commission</th>

                </tr>
                <template v-if="JSON.stringify(ReportData) !== '{}'">
                    <tr v-for="(data, i) in ReportData.detailed_breakdown" :key="i">
                        <td>{{ data.schoolName }}</td>
                        <td>{{ data.noOfStudents }}</td>
                        <td>{{ data.commission }}</td>
                    </tr>
                </template>
                <template v-else>
                    <tr v-for="(data, i) in 12" :key="i">
                        <td><skeleton-loader :width="50" :height="10" type="rect" animation="fade" style="margin: 0 auto" />
                        </td>
                        <td><skeleton-loader :width="50" :height="10" type="rect" animation="fade" style="margin: 0 auto" />
                        </td>
                        <td><skeleton-loader :width="50" :height="10" type="rect" animation="fade" style="margin: 0 auto" />
                        </td>
                    </tr>
                </template>

            </table>
        </div>

        <div class="action">
            <button @click="backToChannelListtab">Cancel</button>
        </div>

</div>
</template>

<script>















export default {
    data() {
        return {
            ReportchannelId: this.$store.state.ControlTab.channelTabSwitcher.viewChannelReport.id,
            ReportData: {}

        }
    },
    created() {
        let vm = this;
        vm.getChannelReport();
    },
    methods: {
        backToChannelListtab() {
            let vm = this;
            vm.$store.commit('VIEW_CHANNEL_LIST')
        },
        getChannelReport() {
            let vm = this;
            vm.$store.dispatch('POST_CHANNEL_REPORT', {
                channelId: vm.ReportchannelId.id
            })
                .then(res => {
                    vm.ReportData = res.data.channelReport

                })
                .catch(e => {
                    console.log(e)
                })
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../stylesConst/fonts.scss';

.umgt-report-channel {
    padding-left: 30px;
    padding-right: 30px;

    h1 {
        color: $secondary_font_color;
        text-align: center;
        font-size: 16px;
        margin-bottom: 40px;
        margin-top: 40px;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        justify-content: space-between;

        i {
            cursor: pointer
        }
    }

    .monthly_user_report {
        h1 {
            text-align: left;
            color: $primary_font_color;
            font-weight: 700;
        }

        table {
            border-radius: 15px;
            box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
            font-size: 16px;

            tr {
                border-bottom: 1px solid rgba(192, 192, 192, 0.616);

                th {
                    padding: 10px;

                    &:nth-child(even) {
                        background: #dbdbdb9e;
                    }
                }

                td {
                    padding: 10px;

                    &:nth-child(even) {
                        background: #dbdbdb9e;
                    }
                }
            }
        }

    }

    .detailed_breakdown {
        h1 {
            text-align: left;
            color: $primary_font_color;
            font-weight: 700;
        }

        table {
            min-width: 500px;
            font-size: 16px;

            tr {
                &:nth-child(even) {
                    background: #dbdbdb9e;
                }

                th {
                    padding: 6px;
                    border-right: 1px solid white;
                    text-align: center;
                    font-size: 16px;
                }

                td {
                    padding: 6px;
                    border-right: 1px solid white;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }

    }

    .action {
        display: flex;
        column-gap: 10px;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 40px;

        button {
            padding: 8px;
            width: 120px;
            border-radius: 4px;
            background: $primary_bg_color;
            font-weight: bold;
            color: $secondary_font_color;
            cursor: pointer;

            &:last-of-type {
                background: rgb(232, 232, 232);
            }

            border: none;
        }
    }
}
</style>