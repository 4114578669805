<template>
  <div class="umgt-dashboard-container">
    <div class="umgt-left">
      <ScoreLibrarySidebar />
    </div>
    <div class="umgt-right" :class="!toggleftNav ? 'fullversion' : ''">
      <TopNavBar />
      <ContentBody />
    </div>
    <AdminRegFooter class="regfooter" />
  </div>
</template>

<script>
import ContentBody from "./components/UserManagment/ContentBody.vue";
import LeftNavBar from "./components/UserManagment/LeftNavBar.vue";
import TopNavBar from "./components/UserManagment/TopNavBar.vue";

export default {
  components: { LeftNavBar, TopNavBar, ContentBody },
  data() {
    return {
      toggleftNav: true,
    };
  },
  watch: {
    '$store.state.navCollapsed': function () {
      let vm = this;
      if (vm.$store.state.navCollapsed) {
        document.querySelector(".umgt-left").marginLeft = "-200px";
        vm.toggleftNav = false;
      } else {
        document.querySelector(".umgt-left").marginLeft = "200px";
        vm.toggleftNav = true;
      }
    }
  },
  methods: {
    hideLeftNav(event) {
      let vm = this;
      document.querySelector(".umgt-left").marginLeft = "-200px";
      vm.toggleftNav = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.umgt-dashboard-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  text-align: start;
  overflow: hidden;
  background: #f7f7f9;
  transition: width 0.5s ease;

  @media screen and (max-width: 500px) {}

  .umgt-left {
    width: 200px;
    display: flex;
    transition: all 1s ease;
    z-index: 1;
    height: 100%;
  }

  .umgt-right {
    position: fixed;
    right: 0;
    float: right;
    height: 100%;
    width: calc(100% - 200px);
    height: calc(100vh - 50px);
    transition: width 0.2s linear;

    @media (max-width: 700px) {
      width: 100%;
    }

    &.fullversion {
      width: 100%;
    }
  }
}


.regfooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}
</style>