<template>
  <div class="OverviewAnalytics">
    <div class="analytics-content">
      <div class="analytic-dashes">
        <p class="analytic-title">Overview</p>
        <div class="dash-items">
          <div class="analytic-dash">
            <label for="title" class="title">Regions</label>
            <div class="stats">
              <p class="stats-item">
                CN
                <span>100pax</span>
              </p>
              <p class="stats-item">
                SG
                <span>200pax</span>
              </p>
            </div>
          </div>
          <!-- sex -->
          <div class="analytic-dash">
            <label for="title" class="title">Sex</label>
            <div class="stats">
              <p class="stats-item">
                Male
                <span>60pax</span>
              </p>
              <p class="stats-item">
                Female
                <span>80pax</span>
              </p>
            </div>
          </div>
          <!-- age -->
          <div class="analytic-dash">
            <label for="title" class="title">Age</label>
            <div class="stats">
              <p class="stats-item">
                Average
                <span>10 Y.O</span>
              </p>
            </div>
          </div>
          <!-- grade -->
          <div class="analytic-dash">
            <label for="title" class="title">Grade</label>
            <div class="stats">
              <p class="stats-item">
                Average grade
                <span>Grade 7</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="analytic-dashes">
        <p class="analytic-title">Usage</p>
        <div class="dash-items">
          <div class="analytic-dash">
            <label for="title" class="title">Downloads</label>
            <div class="stats">
              <p class="stats-item">
                Total
                <span>60pax</span>
              </p>
              <p class="stats-item">
                APK
                <span>80pax</span>
              </p>
              <p class="stats-item">
                IOS
                <span>80pax</span>
              </p>
            </div>
          </div>
          <div class="analytic-dash">
            <label for="title" class="title">Performance</label>
            <div class="stats">
              <p class="stats-item">
                Average stages completed
                <span>6 stages</span>
              </p>
              <p class="stats-item">
                Average session length
                <span>80pax</span>
              </p>
              <p class="stats-item">
                Average usage frequency
                <span>80pax</span>
              </p>
            </div>
          </div>
          <div class="analytic-dash">
            <label for="title" class="title">Users</label>
            <div class="stats">
              <p class="stats-item">
                Weekly active
                <span>60pax</span>
              </p>
              <p class="stats-item" title="Atleast twice a month">
                Active users
                <span>80pax</span>
              </p>
              <p class="stats-item">
                New users
                <span>80pax</span>
              </p>
              <p class="stats-item">
                Users dropped
                <span>80pax</span>
              </p>
              <p class="stats-item">
                Renewal users
                <span>80%</span>
              </p>
              <p class="stats-item">
                Users percentage
                <span>80%</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverviewAnalytics",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
</style>