<template>
  <div class="channel-containter">
    <!-- Channel List -->
    <table v-if="channelToggle.channelList">
      <tr>
        <th>
          <div class="headerHorizontal">
            <input
              type="checkbox"
              @change="(all = !all), checkAll()"
              :checked="all"
              :value="allchecked"
              ref="allInput"
            />
            <span>Channels</span>
            <div @click="reverseData('channel')">
              <i class="fa-solid fa-arrow-right-arrow-left"></i>
            </div>
          </div>
        </th>
        <th>
          <div class="headerHorizontal">
            <span>Schools</span>
            <i
              @click="reverseData('schools')"
              class="fa-solid fa-arrow-right-arrow-left"
            ></i>
          </div>
        </th>
        <th>Total Users</th>
        <th>
          <div class="headerHorizontal">
            <span>Avg sales/m</span>
            <i
              @click="reverseData('avgSalesMonth')"
              class="fa-solid fa-arrow-right-arrow-left"
            ></i>
          </div>
        </th>
        <th>
          <div class="headerHorizontal">
            <span>Avg Commission/m</span>
            <i
              @click="reverseData()"
              class="fa-solid fa-arrow-right-arrow-left"
            ></i>
          </div>
        </th>
        <th>Commission Rate</th>
        <th>Region</th>
        <th>Status</th>
      </tr>
      <template v-if="isLoading">
        <tr v-for="row in 50" :key="row">
          <td v-for="column in 8" :key="column">
            <skeleton-loader
              :width="100"
              :height="10"
              type="rect"
              animation="fade"
              style="margin: 0 auto"
            />
          </td>
        </tr>
      </template>
      <template v-if="!isLoading">
        <tr v-for="channel in channelsList" :key="channel.channelName">
          <td>
            <div class="headerHorizontal">
              <input
                type="checkbox"
                :checked="all"
                class="Channel"
                name="Channel"
                @change="singlechecked(channel.channelId, $event)"
                :value="channel.channelId"
              />
              <span class="alignListing">{{ channel.channelName }}</span>
              <div>
                <i
                  class="fa-solid fa-info"
                  @click="showChannelEdit(channel.channelId, channel)"
                ></i>
                <!-- <i class="fa  fa-list" @click="showChannelReport(channel.channelId)"></i> -->
              </div>
            </div>
          </td>
          <td
            class="activateNschool"
            @click="$emit('specificScTeacher', channel.channelId)"
          >
            {{ channel.schools }}
          </td>
          <td class="alignListing">11</td>
          <td>{{ channel.avgSalesMonth }}</td>
          <td>{{ channel.avgSalesMonth }}</td>
          <td>10%</td>
          <td>{{ channel.region ? channel.region : "N/A" }}</td>
          <td>
            <div class="statusCheck">
              <label
                for="status"
                @click="
                  updateChannelStatus(channel.channelId, channel.status, $event)
                "
                :class="channel.status ? 'special' : ''"
              >
                <div></div>
              </label>
              <input type="checkbox" id="status" />
            </div>
          </td>
        </tr>
      </template>

      <template v-if="isLoading">
        <tr v-for="row in 50" :key="row + `erer`">
          <td v-for="column in 8" :key="column">
            <skeleton-loader
              :width="100"
              :height="10"
              type="rect"
              animation="fade"
              style="margin: 0 auto"
            />
          </td>
        </tr>
      </template>
    </table>
    <template v-if="!isLoading && channelsList.length == 0">
      <div class="channel_not_found">
        <h3>Channel not found in our collection</h3>
      </div>
    </template>

    <!-- Add channel section -->
    <AddChanel v-if="channelToggle.addNewChannel" @triggered="triggered" />

    <!-- Edit channel section -->
    <EditChannel v-if="channelToggle.editChannel.show" @triggered="triggered" />

    <!-- Channel Report section -->
    <ReportChannel v-if="channelToggle.viewChannelReport.show" />

    <!-- Items delete box -->
    <div class="delete-box delete-show" ref="refDeleteBox">
      <div class="items">
        <div class="cross" @click="resetDeleteAction">
          <i class="fa fa-times"></i>
        </div>
        <div class="selected-number">
          <span>{{ [...new Set(itemToDelete)].length }}</span>
        </div>
        <h3>Items selected</h3>
      </div>
      <div class="actions">
        <button @click="confirmDelete = true">
          <i class="fa fa-trash"></i> Delete
        </button>
      </div>
    </div>

    <!-- delete all confirmation Box -->
    <div class="confirm_container" v-if="confirmDelete">
      <div class="box_conf">
        <p>Are you sure you want to delete all selected users?</p>
        <div class="action">
          <div class="cancel">
            <button class="cancelbtn" @click="confirmDelete = false">
              Cancel
            </button>
          </div>
          <div class="confirm">
            <button class="confirmbtn" @click="deleteChannel">
              <Spinner size="18" v-if="isRequesting"></Spinner>
              <span v-if="!isRequesting">Yes,Delete </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TableSkeleton from "../../../../../../Skeletons/user_mgt_skeleton/Administrator/tables/TableSkeleton.vue";
import AddChanel from "./AddChanel.vue";
import EditChannel from "./EditChannel.vue";
import ReportChannel from "./ReportChannel.vue";
import Spinner from "vue-simple-spinner";
export default {
  components: { AddChanel, EditChannel, ReportChannel, TableSkeleton, Spinner },
  data() {
    return {
      all: false,
      channelToggle: this.$store.state.ControlTab.channelTabSwitcher,
      srchTerm: this.$store.state.searchTerm,
      itemToDelete: [],
      allchecked: false,
      confirmDelete: false,
      channelsList: [],
      isLoading: true,
      isRequesting: false,
      sortType: true,
    };
  },
  mounted() {
    let vm = this;
    vm.getAllChannels();
  },
  watch: {
    "$store.state.ControlTab.channelTabSwitcher": function () {
      this.channelToggle = this.$store.state.ControlTab.channelTabSwitcher;
    },
    "$store.state.ControlTab.refleshTabList": function () {
      this.getAllChannels();
    },
    "$store.state.searchTerm": function () {
      let vm = this;
      vm.$store
        .dispatch("POST_GET_CHANNELS", {
          Page: 1,
          Size: 100,
          OrganizationId: 2,
          searchQuery: vm.$store.state.searchTerm,
        })
        .then((res) => {
          vm.channelsList = res.data?.channels;
          vm.isLoading = false;
        })
        .catch((e) => {
          this.$toast.warning("Something Went wrong!");
        });
    },
  },
  methods: {
    reverseData(item) {
      let vm = this;
      let newList = [];
      switch (item) {
        case "channel":
          // clone the array
          newList = [...vm.channelsList];
          if (vm.sortType == true) {
            vm.channelsList.sort((a, b) => {
              // sort by Channel Name alphabetically
              let fa = a.channelName.toLowerCase(),
                fb = b.channelName.toLowerCase();
              if (fa < fb) {
                return -1;
              }
            });
          } else {
            vm.channelsList.sort((a, b) => {
              // sort by Channel Name alphabetically desc
              let fa = a.channelName.toLowerCase(),
                fb = b.channelName.toLowerCase();
              if (fa > fb) {
                return -1;
              }
            });
          }
          vm.sortType = !vm.sortType;
          break;
        case "contactName":
          // clone the array
          newList = [...vm.channelsList];
          if (vm.sortType == true) {
            vm.channelsList.sort((a, b) => {
              // sort by Channel Name alphabetically
              let fa = a.contactName.toLowerCase(),
                fb = b.contactName.toLowerCase();
              if (fa < fb) {
                return -1;
              }
            });
          } else {
            vm.channelsList.sort((a, b) => {
              // sort by Channel Name alphabetically desc
              let fa = a.contactName.toLowerCase(),
                fb = b.contactName.toLowerCase();
              if (fa > fb) {
                return -1;
              }
            });
          }
          vm.sortType = !vm.sortType;
          break;
        case "schools":
          // clone the array
          newList = [...vm.channelsList];
          if (vm.sortType == true) {
            vm.channelsList.sort((a, b) => {
              // sort by Channel Name alphabetically
              let fa = a.schools,
                fb = b.schools;
              if (fa < fb) {
                return -1;
              }
            });
          } else {
            vm.channelsList.sort((a, b) => {
              // sort by Channel Name alphabetically desc
              let fa = a.schools,
                fb = b.schools;
              if (fa > fb) {
                return -1;
              }
            });
          }
          vm.sortType = !vm.sortType;
          break;

        case "avgSalesMonth":
          // clone the array
          newList = [...vm.channelsList];
          if (vm.sortType == true) {
            vm.channelsList.sort((a, b) => {
              // sort by Channel Name alphabetically
              let fa = a.avgSalesMonth,
                fb = b.avgSalesMonth;
              if (fa < fb) {
                return -1;
              }
            });
          } else {
            vm.channelsList.sort((a, b) => {
              // sort by Channel Name alphabetically desc
              let fa = a.avgSalesMonth,
                fb = b.avgSalesMonth;
              if (fa > fb) {
                return -1;
              }
            });
          }
          vm.sortType = !vm.sortType;
          break;
        default:
          break;
      }
    },
    checkAll() {
      let vm = this;
      vm.allchecked = !vm.allchecked;
      vm.$refs.refDeleteBox.classList.remove("delete-show");
      if (vm.allchecked) {
        document.querySelectorAll(".Channel").forEach((el) => {
          vm.itemToDelete.push(+el.value);
        });
        vm.$refs.refDeleteBox.classList.remove("delete-show");
      } else {
        vm.$refs.refDeleteBox.classList.add("delete-show");
        vm.itemToDelete = [];
      }
    },
    triggered() {
      let vm = this;
      vm.isLoading = true;
      vm.getAllChannels();
    },
    singlechecked(id, e) {
      let vm = this;
      if (e.target.checked) {
        vm.$refs.refDeleteBox.classList.remove("delete-show");
        vm.itemToDelete.push(id);
      } else {
        let target = e.target.value;
        if (vm.itemToDelete.indexOf(+target) > -1) {
          vm.itemToDelete.splice(vm.itemToDelete.indexOf(+target), 1);
          // console.log(vm.itemToDelete)
        }
        vm.itemToDelete.length === 0
          ? vm.$refs.refDeleteBox.classList.add("delete-show")
          : "";
      }
    },
    resetDeleteAction() {
      let vm = this;
      document
        .querySelectorAll('input[name="Channel"]:checked')
        .forEach((CheckBox) => {
          CheckBox.checked = false;
        });
      vm.itemToDelete = [];
      vm.$refs.refDeleteBox.classList.add("delete-show");
      vm.all = false;
      vm.allchecked = false;
    },
    showChannelEdit(id, channel) {
      let vm = this;
      vm.$store.commit("VIEW_CHANNEL_EDIT", { id, channel });
    },
    showChannelReport(id) {
      let vm = this;
      vm.$store.commit("VIEW_CHANNEL_REPORT", { id });
    },
    getAllChannels() {
      let vm = this;
      vm.$store
        .dispatch("POST_GET_CHANNELS", {
          Page: 1,
          Size: 100,
          OrganizationId: 2,
          searchQuery: "",
        })
        .then((res) => {
          vm.channelsList = res.data?.channels;
          vm.isLoading = false;
        })
        .catch((e) => {
          this.$toast.warning("Something Went wrong!");
        });
    },
    deleteChannel(e) {
      let vm = this;
      vm.isRequesting = true;
      vm.$store
        .dispatch("POST_CHANNELS_DELETE", { channelIds: vm.itemToDelete })
        .then((res) => {
          vm.isRequesting = false;
          vm.confirmDelete = false;
          vm.resetDeleteAction();
          vm.isLoading = true;
          vm.$toast.success("Channel deleted successful");
          vm.$store.commit("LIST_REFRESHER_FOR_CHANNEL");
        })
        .catch((e) => {
          vm.isRequesting = false;
          this.$toast.error("Something Went wrong!");
        });
    },
    updateChannelStatus(id, status, e) {
      let vm = this;
      let newStatus;
      if (status) {
        e.target.classList.remove("special");
        newStatus = 0;
        vm.$store
          .dispatch("POST_CHANNEL_STATUS_UPDATE", {
            channelId: id,
            status: newStatus,
          })
          .then((res) => {
            vm.isLoading = true;
            vm.getAllChannels();
          })
          .catch((e) => {
            this.$toast.warning("Something Went wrong!");
          });
      } else {
        e.target.classList.add("special");
        newStatus = 1;
        vm.$store
          .dispatch("POST_CHANNEL_STATUS_UPDATE", {
            channelId: id,
            status: newStatus,
          })
          .then((res) => {
            vm.isLoading = true;
            vm.getAllChannels();
          })
          .catch((e) => {
            this.$toast.warning("Something Went wrong!");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped >
@import "../stylesConst/fonts.scss";

table {
  width: 100%;
  border: none;
  color: #636363;

  tr {
    background: white;
    border: none;

    &:nth-child(odd) {
      background: #f3f3f7;
    }

    &:hover {
      background: $secondary_hover_color;
    }

    th {
      background: #d2d2d5;
      border: none;
      padding: 10px;
      font-size: 13px;
      border-right: 0;
      color: $secondary_font_color;

      &:not(:last-child) {
        border-right: 3px solid rgb(255, 255, 255);
      }

      input {
        cursor: pointer;
      }

      div {
        i {
          cursor: pointer;
        }
      }
    }

    td {
      text-align: center;
      padding: 10px;
      font-size: 13px;

      input {
        cursor: pointer;
      }

      div {
        display: flex;
        align-items: center;
        gap: 3px;

        i {
          cursor: pointer;
          height: 20px;
          width: 20px;
          background: rgb(90, 83, 83);
          display: grid;
          place-content: center;
          font-size: 11px;
          border-radius: 50%;
          color: #d2d2d5;
        }
      }
    }
  }
}

.headerHorizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  .fa-arrow-right-arrow-left {
    transform: rotate(90deg);
  }
}

.statusCheck {
  display: flex;
  justify-content: center;

  label {
    height: 20px;
    width: 40px;
    background: rgb(194, 194, 194);
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;

    div {
      height: 20px;
      width: 20px;
      background: rgb(235, 233, 233);
      border-radius: 50%;
      position: relative;
      right: 0;
      transition: all 0.2s ease;
      pointer-events: none !important;
    }
  }

  input {
    visibility: hidden;
  }
}

.special {
  background: #01cffe !important;

  div {
    right: -20px !important;
    background: rgb(235, 233, 233) !important;
    pointer-events: none !important;
  }
}

.channel-containter {
  position: relative;

  .delete-box {
    position: fixed;
    bottom: 100px;
    width: 300px;
    border-radius: 15px;
    height: 150px;
    background: white;
    z-index: 100;
    left: 50%;
    transform: translate(-25%, 0);
    box-shadow: 2px 3px 12px rgb(216, 216, 216),
      -2px -3px 12px rgb(216, 216, 216);
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    font-size: 16px;
    transition: all 0.5s ease-in-out;

    .items {
      display: flex;
      align-items: center;

      .cross {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-right: 4px;
        cursor: pointer;
      }

      .selected-number {
        min-width: 40px;
        min-height: 40px;
        padding: 10px;
        border-radius: 50%;
        display: grid;
        place-content: center;
        background: $primary_bg_color;
        margin-right: 4px;
      }
    }

    .actions {
      button {
        padding: 8px 12px;
        border-radius: 4px;
        border: none;
        background: $primary_bg_color;
        outline: none;
        font-weight: 700;
        cursor: pointer;
        color: #2c3e50;
      }
    }
  }
}

.delete-show {
  bottom: -200px !important;
}

.confirm_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #03030396;
  z-index: 100;
  display: grid;
  place-content: center;

  .box_conf {
    width: 358px;
    background-color: white;
    border-radius: 6px;
    color: #4a4a4a;
    display: block;
    padding: 1.25rem;

    p {
      text-align: center;
    }

    .action {
      display: flex;
      justify-content: center;
      column-gap: 12px;

      button {
        width: 98px;
        padding: 8px 4px;
        margin-top: 20px;
        cursor: pointer;
        border: none;
        font-weight: bold;
        border-radius: 6px;
        box-shadow: 2px 3px 12px rgb(216, 216, 216),
          -2px -3px 9px rgb(216, 216, 216);
      }

      .cancelbtn {
      }

      .confirmbtn {
        background: #92e8ff;
      }
    }
  }
}

.activateNschool {
  &:hover {
    color: #2bd7fd;
    font-weight: 700;
    cursor: pointer;
  }
}

.channel_not_found {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    margin: 8px;
    font-size: 16px;
    text-align: center;
  }
}

.alignListing {
  flex: 1 !important;
  text-align: start !important;
  padding-left: 7px !important;
}
</style>