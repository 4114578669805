<template>
  <div class="ImageUploader">
    <div class="drop-zone">
      <div class="drop-zone__input">
        <input
          type="file"
          name="image"
          id="image"
          class="drop-zone__input"
          @change="imageFile = $event.target.files[0]"
        />
        <label for="image" class="drop-zone__label">
          <span v-if="image">Change Image</span>
          <span v-else>Upload Image</span>
        </label>
      </div>
      <div class="drop-zone__thumb">
        <img :src="$store.state.assets_base_url + image" v-if="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageUploader",
  props: {
    image: {
      type: String,
      default: ""
    },
    imagePath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      imageFile: null
    };
  },
  methods: {
    uploadImage() {
      this.$startLoader();
      const formData = new FormData();
      formData.append("file", this.imageFile);
      // dispatch("uploadImage", formData);
      if (this.imageFile) {
        this.$store.dispatch("UPLOAD_FILE", formData).then(res => {
          if (res.status === 200) {
            this.$emit("imageUploaded", res.data.fileName);
            console.log(res.data);
          }
          this.$stopLoader();
        });
      } else {
        this.$emit("imageUploaded", "");
        this.$stopLoader();
      }
    }
  },
  watch: {
    imageFile() {
      this.uploadImage();
    }
  }
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.ImageUploader {
  .drop-zone {
    width: 200px;
    height: 100px;
    border: 3px dashed #ddd;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      border-color: $EEskyblue;
    }
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 2;
      border: none;
      cursor: pointer;
    }
    label {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
      color: #a3a3a3;
      z-index: 1;
      // text shadow
      text-shadow: 1px 1px 1px rgba(104, 104, 104, 0.884);
      cursor: pointer;
    }
    &__thumb {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>