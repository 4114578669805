import {
    AXIOS_POST,
    AXIOS_GET,
    AXIOS_PUT,
    AXIOS_DELETE,
  } from "../../Config/Axios_EELibrary/axios.js";
  import {
    GET_GRADES,
    GET_LEVELS,
    GET_STAGES,
  } from "./../actions/common.js";
  import {
    GET_GRADES_URL,
    GET_LEVELS_URL,
    GET_STAGES_URL,
  } from "./../Variables.js";
  
  let token = localStorage.getItem("EEadmin_token");
  
  const state = {};
  const mutations = {};
  const getters = {};
  const actions = {
    [GET_GRADES]: ({state, rootState}, payload) => {
      return AXIOS_GET(GET_GRADES_URL, rootState.token);
    },
    [GET_LEVELS]: ({state, rootState}, payload) => {
      return AXIOS_GET(GET_LEVELS_URL + payload, rootState.token);
    },
    [GET_STAGES]: ({state, rootState}, payload) => {
      return AXIOS_GET(GET_STAGES_URL + payload, rootState.token);
    },
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  