import {
  AXIOS_POST,
  AXIOS_GET,
  AXIOS_PUT,
  AXIOS_DELETE,
} from "../../Config/Axios_EELibrary/axios.js";
import {
  ADD_LIBRARY,
  GET_LIBRARIES,
  UPDATE_LIBRARY,
  DELETE_LIBRARY,
} from "./../actions/libraries.js";
import {
  ADD_LIBRARY_URL,
  GET_LIBRARIES_URL,
  UPDATE_LIBRARY_URL,
  DELETE_LIBRARY_URL,
} from "./../Variables.js";


const state = {};
const mutations = {};
const getters = {};
const actions = {
  [ADD_LIBRARY]: ({state, rootState}, payload) => {
    return AXIOS_POST(ADD_LIBRARY_URL, payload, rootState.token);
  },
  [GET_LIBRARIES]: ({state, rootState}, payload) => {
    return AXIOS_GET(GET_LIBRARIES_URL, rootState.token);
  },
  [UPDATE_LIBRARY]: ({state, rootState}, payload) => {
    return AXIOS_PUT(UPDATE_LIBRARY_URL, payload, rootState.token);
  },
  [DELETE_LIBRARY]: ({state, rootState}, payload) => {
    return AXIOS_DELETE(DELETE_LIBRARY_URL + payload, payload, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
