<template>
    <div class="umgt-container">
        <div class="umgt-filter">
            <div class="umgt-action-container">
                <div class="setting">
                    <!-- <i class="fa-solid fa-sliders"></i> -->
                </div>
                <div class="umgt-search">
                    <i class="fa fa-search"></i>
                    <input type="text" @change="searchadmin" placeholder="search">
                </div>
            </div>
            <div class="umgt-action">
                <!-- // add option -->
                <div class="umgt-add-box" v-show="showAdd">
                    <h1>Category</h1>
                    <div class="btn-container">
                        <button @click="toggleAdminAdd">Admin</button>
                    </div>
                    <div class="arrow"></div>
                </div>

                <div class="umgt-add" @click="toggleAdminAdd">
                    <i class="fa-solid fa-square-plus"></i>
                    <h6>Add</h6>
                </div>
                <div class="umgt-export" @click="exportAdmin">
                    <i class="fa-solid fa-file-export"></i>
                    <h6>Export</h6>
                </div>

            </div>
        </div>
        <div class="umgt-tables">
            <div class="tables">
                <admin />
            </div>
        </div>
    </div>
</template>

<script>



import Admin from './Admin/Admin.vue'
export default {
    components: { Admin },
    data() {
        return {
            showAdd: false,
        }
    },

    methods: {
        toggleAdminAdd() {
            let vm = this;
            vm.showAdd = false;
            vm.$store.commit('VIEW_ADMIN_ADD')
        },
        exportAdmin() {
            let vm = this;
            vm.$store.dispatch('POST_EXORT_ADMIN')
                .then((response) => {
                    return new Blob([response.data])
                }).then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'admins.xlsx';
                    link.click();
                })
                .catch((e) => {
                    console.log(e)
                })

        },
        searchadmin(e) {
            let vm = this;
            vm.$store.state.searchadminTerm = e.target.value;
        }
    },

}
</script>

<style lang="scss" scoped>
@import './stylesConst/fonts.scss';

.umgt-container {
    height: calc(100% - 100px);
    font-size: 22px;

    .umgt-filter {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 24px;
        color: $secondary_font_color;
        padding-left: 20px;
        font-size: 15px;

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        i {
            margin-right: 16px;
        }

        .umgt-action-container {
            i {
                margin-right: 15px;
            }

            .umgt-search {
                background: white;
                padding: 10px;
                border-radius: 8px;

                input {
                    border: none;
                    outline: none;
                    font-size: 14px;
                }
            }
        }

        .umgt-action {
            display: flex;
            position: relative;

            .umgt-add-box {
                width: 250px;
                padding: 20px;
                position: relative;
                background: rgb(255, 255, 255);
                z-index: 2;
                border-radius: 8px;
                display: block;
                text-align: center;
                color: rgb(136, 133, 133);
                box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
                transition: all 0.5s ease;

                h1 {
                    font-size: 14px;
                    margin-bottom: 10px;
                    color: $secondary_font_color;
                }

                .btn-container {
                    display: flex;
                    column-gap: 4px;

                    button {
                        padding: 5px;
                        flex: 1;
                        border-radius: 4px;
                        border: none;
                        color: $secondary_font_color;
                        box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
                        cursor: pointer;
                        font-weight: 600;

                        &:last-of-type {
                            background: $primary_bg_color;
                        }
                    }
                }

                .arrow {
                    position: absolute;
                    height: 20px;
                    width: 20px;
                    background: rgb(255, 255, 255);
                    right: -4px;
                    transform: rotate(45deg);
                    top: 40%;
                    z-index: -1;
                }
            }

            .umgt-add {
                cursor: pointer;
                margin-left: 10px;

                i {
                    margin: 0;

                }

                h6 {
                    margin-left: 8px;
                }
            }

            .umgt-export {
                margin-left: 20px;
                cursor: pointer;

                i {
                    margin: 0;

                }

                h6 {
                    margin-left: 8px;
                }
            }
        }



    }

    .umgt-tables {
        position: relative;
        width: 100%;
        height: calc(100% - 70px);
        overflow-x: auto;
        overflow-y: auto;
        padding-top: 10px;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
            width: 16px;
            background: #d2d0d0;
            margin: 0;
            padding: 0;
        }

        &::-webkit-scrollbar-thumb {
            cursor: pointer !important;
            border-radius: 8px;
            background: $primary_bg_color;
            height: 150px;
            margin: 0;
            padding: 0;
            border: none;
        }

        .tables {
            width: 95%;
            background: rgb(255, 255, 255);
            border: none;
            border-radius: 15px;
            margin: 0 auto;
            padding: 10px;
            box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
            min-height: 100%;
            overflow-x: auto;

            .umgt-table-btn {
                display: flex;
                column-gap: 4px;

                button {
                    border: none;
                    padding: 10px;
                    outline: none;
                    background: $primary_bg_color;
                    color: $secondary_font_color;
                    flex: 1;
                    font-weight: bold;
                    cursor: pointer;
                    border-radius: 4px;
                    transition: all 0.1s linear;

                    &.active {

                        background: $primary_hover_color;
                    }

                    &:hover {
                        background: $primary_hover_color;
                    }

                }
            }
        }
    }
}
</style>