<script>
export default {
  data() {
    return {};
  },
  mounted() {
    if (this.$loggedAdmin()) {
      if (this.$store) { this.$store.state.token = localStorage.getItem("EEadmin_token") }

    } else {
      // this.$store.state.token = null;
      // this.$router.push({ name: "Login" });
    }
  },
  methods: {
    $adminLogin(user, token) {
      localStorage.setItem("EEadmin_user", JSON.stringify(user));
      localStorage.setItem("EEadmin_token", token);
      this.$store.state.token = localStorage.getItem("EEadmin_token");
      // get user data from local storage
      this.$router.push({ name: "Dashboard" });

      // save user in local storage
    },
    $loggedAdmin() {
      if (localStorage.getItem("EEadmin_user")) {
        let admin = JSON.parse(localStorage.getItem("EEadmin_user"));
        return admin;
      } else {
        return false;
      }
    },
    $loggedAdminToken() {
      if (localStorage.getItem("EEadmin_token")) {
        let EEadmin_token = localStorage.getItem("EEadmin_token");
        return EEadmin_token;
      } else {
        return false;
      }
    },
    $adminlogout() {
      if (localStorage.getItem("EEadmin_user")) {
        let admin = JSON.parse(localStorage.getItem("EEadmin_user"));
        localStorage.removeItem("EEadmin_token");
        localStorage.removeItem("EEadmin_user");
        this.$router.push({ name: "Login" }).catch(() => { });
      } else {
        this.$router.push({ name: "Login" }).catch(() => { });
      }
    },
    $startLoader() {
      this.$store.state.isLoading = true;
    },
    $stopLoader() {
      this.$store.state.isLoading = false;
    },
    $showNotification(text) {
      this.$store.state.showNotification = true;
      this.$store.state.notificationText = text;
    }
  }
};
</script>
