<template>
  <div class="Overview">
    <div class="scoreContent">
      <ScoreLibrarySidebar />
      <div class="score-body">
        <ScoreLibraryTopbar :pageTitle="'Data Analytics'" />
        <div class="analytics-section">
          <div class="analytics-navs">
            <ul>
              <li>
                <router-link
                  :to="{ name: 'AnalyticsOverview', params: { item: 'overview' } }"
                  :class="activeItem == 'overview' ? 'active' : ''"
                >Overview</router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'AnalyticsOverview', params: { item: 'performance' } }"
                  :class="activeItem == 'performance' ? 'active' : ''"
                >Performance</router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'AnalyticsOverview', params: { item: 'navigation' } }"
                  :class="activeItem == 'navigation' ? 'active' : ''"
                >Navigation</router-link>
              </li>
            </ul>
          </div>
          <OverviewAnalytics v-if="activeItem == 'overview'" />
          <DetailedAnalytics v-if="activeItem == 'performance'" />
          <NavigationAnalytics v-if="activeItem == 'navigation'" />
        </div>
      </div>
    </div>
    <AdminRegFooter />
  </div>
</template>

<script>
import OverviewAnalytics from "./components/OverviewAnalytics";
import DetailedAnalytics from "./components/DetailedAnalytics";
import NavigationAnalytics from "./components/NavigationAnalytics";
export default {
  name: "Overview",
  components: {
    OverviewAnalytics,
    DetailedAnalytics,
    NavigationAnalytics
  },
  data() {
    return {
      activeItem: null
    };
  },
  methods: {},
  mounted() {
    this.activeItem = this.$route.params.item;
  },
  watch: {
    $route(to, from) {
      this.activeItem = to.params.item;
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.Overview {
  .analytics-section {
    .analytics-navs {
      width: 100%;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        li {
          a {
            display: block;
            padding: 10px 20px;
            color: #000000;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
            transition: all 0.73s ease;
            font-weight: 500;
            &.active {
              border-bottom: 1px solid $EEskyblue;
              color: $EEskyblue;
            }
          }
        }
      }
    }
  }
}
</style>