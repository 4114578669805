<template>
    <div class="umgt-add-subscriptions">
        <div class="generate_container">
            <h1><span @click="hideExport"><i class=" fa fa-angle-left"></i></span>
                Export List
                <span></span>
            </h1>
            <div class="form">

                <form @submit.prevent="exportSubList">
                    <div class="single">
                        <label for="Contact Name">Choose School/Teacher to export</label>
                        <div class="keys">
                            <Spinner size="18" v-if="isLoading"></Spinner>
                            <template v-if="!isLoading">
                                <div v-for="edu in educatorsData" :key="edu.educatorName
                                "><input type="checkbox" :value="edu.educatorId"
                                        v-model="exportForm.selectedEducators">
                                    <span>{{
                                        edu.educatorName }}</span>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="single">
                        <button type="button" class="generate" v-if="isRequesting">
                            <Spinner size="18" v-if="isRequesting"></Spinner>
                        </button>
                        <button v-if="!isRequesting" class="generate">
                            <span>Export</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
    data() {
        return {
            toggleAddschool: false,
            educatorsData: [],
            isRequesting: false,
            isLoading: true,
            exportForm: {
                selectedEducators: []
            }

        }
    },
    mounted() {
        let vm = this;
        vm.getAllEducators()
    },
    components: {
        Spinner,
    },
    methods: {
        hideExport() {
            let vm = this;
            vm.$store.commit('HIDE_EXPORT_SUBSCRIPTIONS')
        },
        getAllEducators(id = 0) {
            let vm = this;
            vm.educatorsData = []
            vm.$store.dispatch('POST_GET_EDUCATORS', {
                "Page": 1,
                "Size": 100,
                "OrganizationId": id,
                "searchQuery": ""
            }).then(res => {
                vm.educatorsData = res.data.educators;
                vm.isLoading = false;
            }).catch(e => {
                vm.$toast.warning('Something went wrong')
                vm.isLoading = false;
            })
        },
        exportSubList() {
            let vm = this;
            vm.isRequesting = true;
            vm.$store.dispatch('POST_KEYS_EXPORT1', vm.exportForm)
                .then((response) => {
                    return new Blob([response.data])
                }).then((blob) => {
                    vm.isRequesting = false;
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'keys.xlsx';
                    link.click();
                })
                .catch((e) => {
                    vm.isRequesting = false;
                    vm.$toast('Something went wrong')
                })
        }
    },
}
</script>

<style lang="scss" scoped>
@import './../stylesConst/fonts.scss';

.umgt-add-subscriptions {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    place-content: center;
    background: rgba(0, 0, 0, 0.344);
    z-index: 10001;

    .generate_container {
        width: 400px;
        background: white;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 15px;


        h1 {
            color: $secondary_font_color;
            font-weight: 600;
            text-align: center;
            font-size: 14px;
            margin-bottom: 40px;
            margin-top: 40px;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;

            i {
                cursor: pointer
            }
        }

        .form {

            .single {

                position: relative;

                .keys {
                    width: 100%;
                    height: 200px;
                    overflow: hidden;
                    overflow-y: auto;
                    background: rgba(82, 82, 82, 0.516);
                    border-radius: 4px;
                    margin-bottom: 14px;
                    padding: 10px;
                    color: white;

                    div {
                        margin-bottom: 5px;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        gap: 14px;

                        input {
                            margin-bottom: 0;
                        }
                    }
                }

                button.generate {
                    width: 100%;
                    border: none;
                    border-radius: 4px;
                    background: $primary_bg_color;
                    display: grid;
                    place-content: center;
                    font-weight: 600;
                    margin-bottom: 14px;
                }

                label {
                    display: block;
                    font-size: 14px;
                    color: $primary_font_color;
                    font-weight: bold;
                    margin-bottom: 10px;

                }

                button {
                    margin-bottom: 50px;
                    padding: 10px;
                    width: 200px;
                    text-align: start;
                    cursor: pointer;

                }

                table {
                    width: 300px;
                    max-height: 200px !important;
                    overflow-y: auto;
                    font-size: 14px;

                    tr {
                        td {
                            padding: 8px 4px;
                        }
                    }
                }

                .schoolAdd {
                    height: 200px;
                    width: 250px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    border-radius: 4px;
                    position: absolute;
                    top: 40px;
                    left: 200px;
                    z-index: 100;
                    background: white;
                    box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);

                    .search {
                        width: 100%;
                        height: 32px;
                        display: flex;
                        padding: 4px;
                        border-bottom: 1px solid rgb(232, 232, 232);
                        position: sticky;
                        top: 0;
                        background: white;

                        i {
                            padding-left: 4px;
                        }

                        input {
                            width: 100%;
                            padding-left: 2px;
                            height: 100%;
                            border: none;
                            font-size: 16px;

                        }
                    }

                    ul {
                        list-style: none;
                        padding: 4px;

                        li {
                            padding: 4px 6px;
                            font-size: 16px;

                            &:hover {
                                background: $primary_hover_color;
                            }
                        }
                    }
                }
            }

            .action {
                display: flex;
                column-gap: 10px;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 40px;

                button {
                    padding: 8px;
                    width: 120px;
                    border-radius: 4px;
                    background: $primary_bg_color;
                    font-weight: bold;
                    color: $secondary_font_color;
                    cursor: pointer;

                    &:last-of-type {
                        background: rgb(232, 232, 232);
                    }

                    border: none;
                }
            }

            .group {
                display: flex;
                flex-wrap: wrap;
                column-gap: 30px;

                .permission {
                    font-size: 14px;

                    input {
                        margin-right: 7px;
                    }
                }
            }

            input {
                padding: 10px;
                border: none;
                outline: none;
                margin-bottom: 10px;
                border: 1px solid rgb(205, 205, 205);
                cursor: pointer;
                margin-bottom: 12px;

                &:focus {
                    border: 1px solid $primary_bg_color;

                }


            }

            select {
                padding: 10px;
                border: none;
                outline: none;
                width: 200px;
                border-radius: 4px;
                margin-bottom: 12px;
                cursor: pointer;
                border: 1px solid rgb(205, 205, 205);

                &:focus {
                    border: 1px solid $primary_bg_color;

                }

                option {
                    padding: 10px 2px;
                }
            }
        }
    }


}

.statusCheck {
    display: flex;
    justify-content: center;

    label {
        height: 20px;
        width: 40px;
        background: rgb(194, 194, 194);
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        div {
            height: 20px;
            width: 20px;
            background: rgb(235, 233, 233);
            border-radius: 50%;
            position: relative;
            right: 0;
            transition: all 0.2s ease;

        }

    }

    input {
        visibility: hidden;
    }
}

.special {
    background: $primary_bg_color !important;

    div {
        right: -20px !important;
    }
}
</style>