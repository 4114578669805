import {
  AXIOS_POST,
  AXIOS_POST_DOWNLOAD,
  AXIOS_GET,
} from "./../../Config/Axios_UserMagment/axios.js";
import {
  POST_CHANNEL_CREATE,
  POST_CHANNEL_UPDATE,
  POST_GET_CHANNELS,
  POST_CHANNELS_EXPORT,
  POST_CHANNELS_DELETE,
  POST_CHANNEL_STATUS_UPDATE,
  POST_CHANNEL_REPORT,
  GET_CHANNELS_UNASSIGNED_EDUCATORS,
  POST_CHANNEL_EDIT,
} from "./../actions/Channel.js";
import {
  POST_CHANNEL_CREATE_URL,
  POST_CHANNEL_UPDATE_URL,
  POST_GET_CHANNELS_URL,
  POST_CHANNELS_EXPORT_URL,
  POST_CHANNELS_DELETE_URL,
  POST_CHANNEL_STATUS_UPDATE_URL,
  POST_CHANNEL_REPORT_URL,
  GET_CHANNELS_UNASSIGNED_EDUCATORS_URL,
  POST_CHANNEL_EDIT_URL,
} from "./../Variables.js";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [POST_CHANNEL_CREATE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_CHANNEL_CREATE_URL, payload, rootState.token);
  },
  [POST_CHANNEL_UPDATE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_CHANNEL_UPDATE_URL, payload, rootState.token);
  },
  [POST_CHANNEL_EDIT]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_CHANNEL_EDIT_URL, payload, rootState.token);
  },
  [POST_GET_CHANNELS]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_GET_CHANNELS_URL, payload, rootState.token);
  },
  [POST_CHANNELS_EXPORT]: ({ rootState }, payload) => {
    return AXIOS_POST_DOWNLOAD(
      POST_CHANNELS_EXPORT_URL,
      payload,
      rootState.token
    );
  },
  [POST_CHANNELS_DELETE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_CHANNELS_DELETE_URL, payload, rootState.token);
  },
  [POST_CHANNEL_STATUS_UPDATE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_CHANNEL_STATUS_UPDATE_URL, payload, rootState.token);
  },
  [POST_CHANNEL_REPORT]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_CHANNEL_REPORT_URL, payload, rootState.token);
  },
  [GET_CHANNELS_UNASSIGNED_EDUCATORS]: ({ rootState }, payload) => {
    return AXIOS_GET(GET_CHANNELS_UNASSIGNED_EDUCATORS_URL, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
