
<template>
    <div class="TechSupport_container" :class="maxmize ? 'onMaximize' : 'onMinimize'">
        <div class="header" @click="maxmize = !maxmize">
            {{ maxmize? '- Sent To Tech Department': '+' }}
        </div>
        <div class="dragposition">

            <div v-if="maxmize" class="techComp">
                <Waiting></Waiting>
                <Solution></Solution>
                <ActiveComp></ActiveComp>
            </div>

            <templalate v-if="!maxmize">
                <div class="box_data">
                    <h2>
                        Sent To Tech Department
                    </h2>
                </div>
            </templalate>

        </div>
    </div>
</template>

<script>
import Solution from './Solution.vue';
import Waiting from './Waiting.vue';
import ActiveComp from './Active.vue';

export default {
    name: "TechSupportmenu",
    data() {
        return {
            maxmize: false,
        };
    },
    inheritAttrs: false,
    components: { Solution, Waiting, ActiveComp }
}
</script>

<style lang="scss" scoped>
@import './../StylesConst/fonts.scss';

.TechSupport_container {
    height: 100%;
    transition: all 0.5s ease-in-out;

    .header {
        height: 26px;
        display: grid;
        place-content: center;
        border: none;
        border-bottom: 2px solid $primary_border_color;
        border-right: 2px solid $primary_border_color;
        cursor: pointer;
    }

    .dragposition {
        display: grid;
        min-height: calc(100% - 87px);
        border-right: 2px dotted $primary_border_color;
        height: 100%;

        .techComp {
            display: flex;
        }

        .box_data {
            text-align: center;
            display: grid;
            place-content: center;
            padding-top: 80px;

            h2 {
                writing-mode: vertical-rl;
                text-orientation: mixed;
                transform: rotate(180deg);
            }
        }

    }

    .add_task {
        text-align: right;
        padding: 10px;
        color: green;
        font-weight: 700;
        border-right: 2px dotted $primary_border_color;

        h3 {
            font-size: 14px;
            cursor: pointer;
        }
    }


}

.onMinimize {
    max-width: 100px;
    min-width: 100px;
}

.onMaximize {}
</style>