const state = {
  channelTabSwitcher: {
    channelList: true,
    addNewChannel: false,
    editChannel: { show: false, id: 0 },
    viewChannelReport: { show: false, id: 0 },
  },
  SchlTeacherTabSwitcher: {
    SchlTeacherList: true,
    addNewSchlTeacher: false,
    editSchlTeacher: { show: false, id: 0 },
    viewSchlTeacherReport: { show: false, id: 0 },
  },
  StudentTabSwitcher: {
    studentList: true,
    editStudent: { show: false, id: 0 },
  },
  SubscriptionsTabSwitcher: {
    SubscriptionsList: true,
    editSubscriptions: { show: false, id: 0 },
    generate: false,
    export: false,
  },
  refleshTabList: {
    reRender: false,
  },
};

const mutations = {
  LIST_SUBSCRIPTIONS() {
    state.SubscriptionsTabSwitcher = {
      SubscriptionsList: true,
      editSubscriptions: { show: false, id: 0 },
      generate: false,
    };
  },
  SHOW_GENERATE_SUBSCRIPTIONS() {
    state.SubscriptionsTabSwitcher.generate = true;
  },
  HIDE_GENERATE_SUBSCRIPTIONS() {
    state.SubscriptionsTabSwitcher.generate = false;
  },
  SHOW_EXPORT_SUBSCRIPTIONS() {
    state.SubscriptionsTabSwitcher.export = true;
  },
  HIDE_EXPORT_SUBSCRIPTIONS() {
    state.SubscriptionsTabSwitcher.export = false;
  },
  EDIT_SUBSCRIPTIONS(state, payload) {
    state.SubscriptionsTabSwitcher = {
      SubscriptionsList: false,
      editSubscriptions: { show: true, id: payload },
      generate: false,
    };
  },
  LIST_REFRESHER_FOR_EDUCATOR() {
    state.refleshTabList = {
      reRender: !state.refleshTabList.reRender,
    };
  },
  LIST_REFRESHER_FOR_CHANNEL() {
    state.refleshTabList = {
      reRender: !state.refleshTabList.reRender,
    };
  },
  VIEW_CHANNEL_LIST() {
    state.channelTabSwitcher = {
      channelList: true,
      addNewChannel: false,
      editChannel: { show: false, id: 0 },
      viewChannelReport: { show: false, id: 0 },
    };
  },
  VIEW_CHANNEL_ADD() {
    state.channelTabSwitcher = {
      channelList: false,
      addNewChannel: true,
      editChannel: { show: false, id: 0 },
      viewChannelReport: { show: false, id: 0 },
    };
  },
  VIEW_CHANNEL_EDIT(state, payload) {
    state.channelTabSwitcher = {
      channelList: false,
      addNewChannel: false,
      editChannel: { show: true, id: payload },
      viewChannelReport: { show: false, id: 0 },
    };
  },
  VIEW_CHANNEL_REPORT(state, payload) {
    state.channelTabSwitcher = {
      channelList: false,
      addNewChannel: false,
      editChannel: { show: false, id: 0 },
      viewChannelReport: { show: true, id: payload },
    };
  },

  VIEW_SCHLTEACHER_LIST() {
    state.SchlTeacherTabSwitcher = {
      SchlTeacherList: true,
      addNewSchlTeacher: false,
      editSchlTeacher: { show: false, id: 0 },
      viewSchlTeacherReport: { show: false, id: 0 },
    };
  },
  VIEW_SCHLTEACHER_ADD() {
    state.SchlTeacherTabSwitcher = {
      SchlTeacherList: false,
      addNewSchlTeacher: true,
      editSchlTeacher: { show: false, id: 0 },
      viewSchlTeacherReport: { show: false, id: 0 },
    };
  },
  VIEW_SCHLTEACHER_EDIT(state, payload) {
    state.SchlTeacherTabSwitcher = {
      SchlTeacherList: false,
      addNewSchlTeacher: false,
      editSchlTeacher: { show: true, id: payload },
      viewSchlTeacherReport: { show: false, id: 0 },
    };
  },
  VIEW_SCHLTEACHER_REPORT(state, payload) {
    state.SchlTeacherTabSwitcher = {
      SchlTeacherList: false,
      addNewSchlTeacher: false,
      editSchlTeacher: { show: false, id: 0 },
      viewSchlTeacherReport: { show: true, id: payload },
    };
  },
  VIEW_STUDENT_LIST() {
    state.StudentTabSwitcher = {
      studentList: true,
      editStudent: { show: false, id: 0 },
    };
  },
  VIEW_STUDENT_EDIT(state, payload) {
    state.StudentTabSwitcher = {
      studentList: false,
      editStudent: { show: true, id: payload },
    };
  },
};

// const actions = {
//   fxn({ commit }) {
//     commit("");
//   },
// };

export default {
  state,
  mutations,
};
