<template>
    <div class="inbox_container" :class="maxmize ? 'onMaximize' : 'onMinimize'">
        <div class="header" @click="maxmize = !maxmize">
            {{ maxmize? '- Inbox': '+' }}
        </div>
        <div v-if="maxmize" class="add_task">
            <h3>+ Add task</h3>
        </div>
        <div class="dragposition">
            <templalate v-if="maxmize">
                <draggable :animation="400" :list="Items" tag="div" ghost-class="drop-ell" group="kanbanboard"
                    class="item_list">
                    <div v-for="element in Items" :key="element.id" class="drop-el"
                        :style="{ background: element.color }">{{
                            element.Message
                        }}
                    </div>
                </draggable>
            </templalate>
            <div class="box_data" v-if="!maxmize">
                <h2>
                    Inbox
                </h2>
            </div>


        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    name: 'inbox',
    components: {
        draggable,
    },
    data() {
        return {
            maxmize: true,
            Items: [
                { id: 0, Message: 'I can not signUp', color: '#b35d4f' },
                { id: 1, Message: 'I like your game', color: '#da8f56' },
                { id: 2, Message: 'bug on avatar', color: '#be6731' },
                { id: 3, Message: 'How I can cancel subscription', color: '#73a8c5' },
            ],

        }
    },
}
</script>

<style lang="scss" scoped>
@import './../StylesConst/fonts.scss';

.inbox_container {
    height: 100%;
    width: 400px;
    transition: all 0.5s ease-in-out;

    .header {
        height: 87px;
        display: grid;
        place-content: center;
        border: none;
        border-bottom: 2px solid $primary_border_color;
        border-right: 2px solid $primary_border_color;
        cursor: pointer;
    }

    .dragposition {
        min-height: calc(100% - 87px);
        border-right: 2px dotted $primary_border_color;

        .box_data {
            text-align: center;
            display: grid;
            place-content: center;
            padding-top: 80px;

            h2 {
                writing-mode: vertical-rl;
                text-orientation: mixed;
                transform: rotate(180deg);
            }
        }

        .item_list {
            height: 100%;
            position: relative;
            display: flex;
            padding: 20px;
            gap: 20px;
            flex-wrap: wrap;
            padding-bottom: 300px;

            .drop-el {
                height: 100px;
                min-width: 169px;
                max-width: 169px;
                color: white;
                padding: 15px;
                cursor: pointer;
                background: #832b1f;
            }
        }

    }

    .add_task {
        text-align: right;
        padding: 10px;
        color: green;
        font-weight: 700;
        border-right: 2px dotted $primary_border_color;

        h3 {
            font-size: 14px;
            cursor: pointer;
        }
    }


}

.onMinimize {
    max-width: 100px;
    min-width: 100px;
}

.onMaximize {
    max-width: 400px;
    min-width: 400px;
}
</style>

