<template>
    <div class="school_teacher_container">


        <!-- School/Teacher List -->
        <div v-if="schlToggle.SchlTeacherList">
            <h1 class="organization_name" v-if="channelId">School/Teacher under Organization</h1>
            <table>
                <tr>
                    <th>
                        <div class="headerHorizontal">
                            <input type="checkbox" @change="all = !all, checkAll()" :checked="all" :value="allchecked"
                                ref="allschInput">
                            <span>School/Teacher Name</span>
                            <div @click="reverseData">
                                <i class="fa  fa-chevron-up"></i>
                            </div>
                        </div>

                    </th>
                    <th>
                        <div class="headerHorizontal">
                            <span>Contact Name</span>
                            <i @click="reverseData" class="fa  fa-chevron-down"></i>
                        </div>


                    </th>
                    <th>Contact No</th>
                    <th>Contact Email</th>
                    <th>
                        <div class="headerHorizontal">
                            <span>Users</span>
                            <i @click="reverseData" class="fa  fa-chevron-up"></i>
                        </div>

                    </th>
                    <th>
                        <div class="headerHorizontal">
                            <span>
                                Avg sales/mth
                            </span>
                            <i @click="reverseData" class="fa  fa-chevron-up"></i>
                        </div>
                    </th>
                    <th>Region</th>
                    <th>Status</th>
                </tr>
                <template v-if="isLoading">
                    <tr v-for="row in 50" :key="row">
                        <td v-for="column in 8" :key="column">
                            <skeleton-loader :width="100" :height="10" type="rect" animation="fade"
                                style="
                                                                                                                                                                                                                                                                                    margin: 0 auto" />
                        </td>
                    </tr>
                </template>
                <template v-if="!isLoading">
                    <tr v-for="(educatorData, i) in educatorsData" :key="i">
                        <td>
                            <div class="headerHorizontal">
                                <input type="checkbox" :checked="all" class="eduInput" :value='educatorData.educatorId'
                                    name='eduInput' @change="singlechecked(educatorData.educatorId, $event)">
                                <span class="alignListing">{{ educatorData.educatorName }}</span>

                                <div>
                                    <i class="fa  fa-pen"
                                        @click="goToschlTeacherEdit(educatorData.educatorId, educatorData)"></i>
                                    <i class="fa  fa-list" @click="goToschlTeacherReport(educatorData.educatorId)"></i>
                                </div>
                            </div>
                        </td>
                        <td class="alignListing">{{ educatorData.contactName }}</td>
                        <td class="alignListing">{{ educatorData.contactNumber }}</td>
                        <td class="alignListing">{{ educatorData.contactEmail }}</td>
                        <td class="activateN" @click="$emit('specificUsers', educatorData.educatorId)">{{
                            educatorData.users }}</td>
                        <td>{{ educatorData.avgSalesMonth }}</td>
                        <td>{{ educatorData.region }}</td>
                        <td>
                            <div class="statusCheck">

                                <label for="status"
                                    @click="activateStatus(educatorData.educatorId, educatorData.status, $event)"
                                    :class="educatorData.status ? 'special' : ''">
                                    <div></div>
                                </label>
                                <input type="checkbox" id="status">
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
            <template v-if="!isLoading && educatorsData.length === 0">
                <div class="educator_not_found">
                    <h3>Educator not found in our collection</h3>
                    <!-- <button @click="resetList">Reset list instead</button> -->
                </div>
            </template>
        </div>


        <!-- Add school/teacher section -->
        <AddSchTeacher v-if="schlToggle.addNewSchlTeacher" @triggered="triggered" />

        <!-- Edit school/Teacher section -->
        <EditSchTeacher v-if="schlToggle.editSchlTeacher.show" @triggered="triggered" />
        <!-- View channel report -->
        <ReportSchoolTeacher v-if="schlToggle.viewSchlTeacherReport.show" />

        <!-- Items delete box -->
        <div class="delete-box delete-show" ref="refDeleteBox">
            <div class="items">
                <div class="cross" @click="resetDeleteAction">
                    <i class="fa fa-times"></i>
                </div>
                <div class="selected-number">
                    <span>{{ [...new Set(itemToDelete)].length }}</span>
                </div>
                <h3>Items selected</h3>
            </div>
            <div class="actions">
                <button @click="confirmDelete = true"><i class="fa fa-trash"></i> Delete</button>
            </div>
        </div>

        <!-- delete all confirmation Box -->
        <div class="confirm_container" v-if="confirmDelete">
            <div class="box_conf">
                <p>Are you sure you want to delete all selected users?</p>
                <div class="action">
                    <div class="cancel">
                        <button class="cancelbtn" @click="confirmDelete = false">Cancel</button>
                    </div>
                    <div class="confirm">
                        <button class="confirmbtn" @click="deleteEdu">
                            <Spinner size="18" v-if="isRequesting"></Spinner>
                            <span v-if="!isRequesting">Yes,Delete </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import AddSchTeacher from './AddSchTeacher.vue';
import Spinner from 'vue-simple-spinner'
import EditSchTeacher from './EditSchTeacher.vue';
import ReportSchoolTeacher from './ReportSchoolTeacher.vue';
export default {
    data() {
        return {
            all: false,
            schlToggle: this.$store.state.ControlTab.SchlTeacherTabSwitcher,
            itemToDelete: [],
            allchecked: false,
            confirmDelete: false,
            educatorsData: [],
            isLoading: true,
            isRequesting: false

        };
    },
    props: ['channelId']
    ,
    components: { AddSchTeacher, EditSchTeacher, ReportSchoolTeacher, Spinner },
    watch: {
        '$store.state.ControlTab.SchlTeacherTabSwitcher': function () {
            this.schlToggle = this.$store.state.ControlTab.SchlTeacherTabSwitcher
        },
        '$store.state.ControlTab.refleshTabList': function () {
            this.getAllEducators();
        },
        '$store.state.searchTerm': function () {
            let vm = this;
            vm.$store.dispatch('POST_GET_EDUCATORS', {
                "Page": 1,
                "Size": 100,
                "OrganizationId": 0,
                "searchQuery": vm.$store.state.searchTerm
            }).then(res => {
                vm.educatorsData = res.data.educators;
                vm.isLoading = false;
            }).catch(e => {
                console.log(e)
            })
        },
        'channelId': function () {
            let vm = this;
            vm.isLoading = true;
            vm.getAllEducators(vm.channelId);
        }
    },
    created() {
        let vm = this;
        vm.getAllEducators(vm.channelId);
    },

    methods: {
        reverseData() {
            let vm = this;
            vm.educatorsData.reverse()
        },
        resetList() {
            let vm = this;
            vm.$store.commit('VIEW_SCHLTEACHER_LIST')
        },
        activateStatus(id, status, e) {
            let vm = this;
            let newStatus;
            if (status) {
                e.target.classList.remove('special')
                newStatus = 0;
                vm.$store.dispatch('POST_EDUCATOR_STATUS_UPDATE', {
                    "educatorId": id,
                    "status": newStatus
                })
                    .then(res => {
                        vm.isLoading = true;
                        vm.getAllEducators();
                    }).catch(e => {
                        e.target.classList.add('special')
                        this.$toast.warning("Something Went wrong!");
                    })

            } else {
                e.target.classList.add('special')
                newStatus = 1;
                vm.$store.dispatch('POST_EDUCATOR_STATUS_UPDATE', {
                    "educatorId": id,
                    "status": newStatus
                })
                    .then(res => {
                        vm.isLoading = true;
                        vm.getAllEducators();
                    }).catch(e => {
                        e.target.classList.remove('special')
                        this.$toast.warning("Something Went wrong!");
                    })
            }
        },
        deleteEdu() {
            let vm = this;
            vm.isRequesting = true;
            vm.$store.dispatch('POST_EDUCATORS_DELETE', { educatorsIds: vm.itemToDelete })
                .then(res => {
                    vm.isRequesting = false;
                    vm.confirmDelete = false;
                    vm.resetDeleteAction();
                    vm.isLoading = true;
                    vm.$toast.success("Educator deleted successful");
                    vm.$store.commit('LIST_REFRESHER_FOR_CHANNEL');
                }).catch(e => {
                    vm.isRequesting = false;
                    this.$toast.error("Something Went wrong!");
                })
        }
        ,
        checkAll() {
            let vm = this;
            vm.allchecked = !vm.allchecked;
            vm.$refs.refDeleteBox.classList.remove('delete-show')
            if (vm.allchecked) {
                document.querySelectorAll('.eduInput').forEach(el => {
                    vm.itemToDelete.push(+el.value)
                })
                vm.$refs.refDeleteBox.classList.remove('delete-show')

            } else {
                vm.$refs.refDeleteBox.classList.add('delete-show');
                vm.itemToDelete = [];
            }

        },
        triggered() {
            let vm = this;
            vm.isLoading = true;
            vm.getAllEducators();
        },
        singlechecked(id, e) {
            let vm = this;
            if (e.target.checked) {
                vm.$refs.refDeleteBox.classList.remove('delete-show')
                vm.itemToDelete.push(id)
            } else {
                let target = e.target.value;
                if (vm.itemToDelete.indexOf(+target) > -1) {
                    vm.itemToDelete.splice(vm.itemToDelete.indexOf(+target), 1)
                    console.log(vm.itemToDelete)
                }
                vm.itemToDelete.length === 0 ? vm.$refs.refDeleteBox.classList.add('delete-show') : ''
            }
        },
        resetDeleteAction() {
            let vm = this;
            document.querySelectorAll('input[name="eduInput"]:checked').forEach(CheckBox => {
                CheckBox.checked = false;
            })
            vm.itemToDelete = [];
            vm.$refs.refDeleteBox.classList.add('delete-show');
            vm.all = false;
            vm.allchecked = false;
        },
        goToschlTeacherEdit(id, educatorData) {
            let vm = this;
            vm.$store.commit('VIEW_SCHLTEACHER_EDIT', { id, educatorData })
        },
        goToschlTeacherReport(id) {
            let vm = this;
            vm.$store.commit('VIEW_SCHLTEACHER_REPORT', { id })
        },
        getAllEducators(id = 0) {
            let vm = this;
            vm.educatorsData = []
            vm.$store.dispatch('POST_GET_EDUCATORS', {
                "Page": 1,
                "Size": 100,
                "OrganizationId": id,
                "searchQuery": ""
            }).then(res => {
                vm.educatorsData = res.data.educators;
                vm.isLoading = false;
                if (!vm.educatorsData.length) {
                    vm.$toast.success("No Educator found!");
                }
            }).catch(e => {
                vm.isLoading = false;
                vm.$toast.error("Something went wrong");
            })
        }


    },

}
</script>

<style lang="scss" scoped >
@import '../stylesConst/fonts.scss';

.school_teacher_container {
    .organization_name {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        color: #666666;
    }

    div {
        h1 {
            font-weight: 600;
            color: $secondary_font_color;
            font-size: 16px;
        }

        table {
            width: 100%;
            border: none;
            color: #636363;
            border-spacing: 4px;

            tr {
                background: white;
                border: none;

                &:nth-child(odd) {
                    background: #f3f3f7;
                }

                &:hover {
                    background: $secondary_hover_color;
                }


                th {
                    background: #d2d2d5;
                    border: none;
                    padding: 10px;
                    font-size: 13px;
                    border-right: 0;
                    color: #666666;

                    &:not(:last-child) {
                        border-right: 3px solid rgb(255, 255, 255);

                    }


                    input {
                        cursor: pointer;
                    }

                    div {
                        i {
                            cursor: pointer;
                        }
                    }

                }

                td {
                    text-align: center;
                    padding: 10px;
                    font-size: 13px;

                    input {
                        cursor: pointer;
                    }

                    div {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        i {
                            cursor: pointer;
                            height: 20px;
                            width: 20px;
                            background: rgb(90, 83, 83);
                            display: grid;
                            place-content: center;
                            font-size: 11px;
                            border-radius: 50%;
                            color: #d2d2d5;
                        }
                    }

                }
            }
        }

    }


    .delete-box {
        position: fixed;
        bottom: 100px;
        width: 300px;
        border-radius: 15px;
        height: 150px;
        background: white;
        z-index: 100;
        left: 50%;
        transform: translate(-25%, 0);
        box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: space-between;
        font-size: 16px;
        transition: all 0.5s ease-in-out;

        .items {
            display: flex;
            align-items: center;

            .cross {
                display: flex;
                align-items: center;
                font-size: 18px;
                margin-right: 4px;
                cursor: pointer;

            }

            .selected-number {
                width: 40px;
                height: 40px;
                padding: 10px;
                border-radius: 50%;
                display: grid;
                place-content: center;
                background: $primary_bg_color;
                margin-right: 4px;

            }
        }

        .actions {
            button {
                padding: 8px 12px;
                border-radius: 4px;
                border: none;
                background: $primary_bg_color;
                outline: none;
                font-weight: 700;
                cursor: pointer;
                color: #2c3e50;
            }
        }
    }

}

.headerHorizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.statusCheck {
    display: flex;
    justify-content: center;

    label {
        height: 20px;
        width: 40px;
        background: rgb(194, 194, 194);
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        div {
            height: 20px;
            width: 20px;
            background: rgb(235, 233, 233);
            border-radius: 50%;
            position: relative;
            right: 0;
            transition: all 0.2s ease;
            pointer-events: none !important;


        }

    }

    input {
        visibility: hidden;
    }
}

.special {
    background: #01cffe !important;

    div {
        right: -20px !important;
        background: rgb(235, 233, 233) !important;
        pointer-events: none !important;
    }
}

.school_teacher_container {
    position: relative;

    .delete-box {
        position: fixed;
        bottom: 100px;
        width: 300px;
        border-radius: 15px;
        height: 150px;
        background: white;
        z-index: 100;
        left: 50%;
        transform: translate(-25%, 0);
        box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: space-between;
        font-size: 16px;
        transition: all 0.5s ease-in-out;

        .items {
            display: flex;
            align-items: center;

            .cross {
                display: flex;
                align-items: center;
                font-size: 18px;
                margin-right: 4px;
                cursor: pointer;

            }

            .selected-number {
                width: 40px;
                height: 40px;
                padding: 10px;
                border-radius: 50%;
                display: grid;
                place-content: center;
                background: $primary_bg_color;
                margin-right: 4px;

            }
        }

        .actions {
            button {
                padding: 8px 12px;
                border-radius: 4px;
                border: none;
                background: $primary_bg_color;
                outline: none;
                font-weight: 700;
                cursor: pointer;
                color: #2c3e50;
            }
        }
    }
}

.delete-box {
    position: fixed;
    bottom: 100px;
    width: 300px;
    border-radius: 15px;
    height: 150px;
    background: white;
    z-index: 100;
    left: 50%;
    transform: translate(-25%, 0);
    box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    font-size: 16px;
    transition: all 0.5s ease-in-out;

    .items {
        display: flex;
        align-items: center;

        .cross {
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-right: 4px;
            cursor: pointer;

        }

        .selected-number {
            min-width: 40px;
            min-height: 40px;
            padding: 10px;
            border-radius: 50%;
            display: grid;
            place-content: center;
            background: $primary_bg_color;
            margin-right: 4px;

        }
    }

    .actions {
        button {
            padding: 8px 12px;
            border-radius: 4px;
            border: none;
            background: $primary_bg_color;
            outline: none;
            font-weight: 700;
            cursor: pointer;
            color: #2c3e50;
        }
    }
}

.delete-show {
    bottom: -200px !important;
}

.delete-show {
    bottom: -200px !important;
}


.confirm_container {

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #03030396;
    z-index: 100;
    display: grid;
    place-content: center;

    .box_conf {
        width: 358px;
        background-color: white;
        border-radius: 6px;
        color: #4a4a4a;
        display: block;
        padding: 1.25rem;

        p {
            text-align: center;
        }

        .action {
            display: flex;
            justify-content: center;
            column-gap: 12px;

            button {
                width: 98px;
                padding: 8px 4px;
                margin-top: 20px;
                cursor: pointer;
                border: none;
                font-weight: bold;
                border-radius: 6px;
                box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 9px rgb(216, 216, 216);
            }

            .cancelbtn {}

            .confirmbtn {
                background: #92e8ff;
            }
        }
    }
}

.activateN {
    &:hover {
        color: #2bd7fd;
        font-weight: 700;
        cursor: pointer
    }
}

.educator_not_found {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        margin: 8px;
        font-size: 16px;
    }

    button {
        border: 2px solid $primary_bg_color;
        border-radius: 4px;
        width: 150px;
        padding: 8px;
        cursor: pointer;
    }
}

.alignListing {
    flex: 1 !important;
    text-align: start !important;
    padding-left: 7px !important;
}
</style>