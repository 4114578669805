<template>
  <div class="AdminProfile">
    <ConfirmPop
      v-if="showDeleteConfirm"
      @cancel="showDeleteConfirm = false"
      @confirm="removeLibrary"
      :text="`Are you sure you want to delete ${selectedLibrary.name}?`"
    />
    <div class="scoreContent">
      <ScoreLibrarySidebar />
      <div class="score-body">
        <ScoreLibraryTopbar :pageTitle="'Edit your profile'" />
        <div class="manage-widget">
          <div class="navigation">
            <ul>
              <li
                :class="editing == 'profile' ? 'active' : ''"
                @click="editing = 'profile'"
              >
                Edit Profile
              </li>
              <li
                :class="editing == 'password' ? 'active' : ''"
                @click="editing = 'password'"
              >
                Change Password
              </li>
            </ul>
          </div>
          <div class="content">
            <div class="left" v-if="editing == 'profile'">
              <form action class="add-library" @submit.prevent="editProfile">
                <div class="form-title">
                  <h3>Edit Profile</h3>
                </div>
                <div class="form-body">
                  <div class="form-group in-2">
                    <div class="input-group">
                      <label for="name">Fullname</label>
                      <input
                        type="text"
                        id="name"
                        v-model="user.fullName"
                        placeholder="Fullname"
                      />
                    </div>
                    <div class="input-group">
                      <label for="name">Email</label>
                      <input
                        type="email"
                        id="name"
                        v-model="user.email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div class="form-group in-2">
                    <div class="input-group">
                      <label for="name">Phone</label>
                      <input
                        type="text"
                        id="name"
                        v-model="user.mobile"
                        placeholder="Phone"
                      />
                    </div>

                    <div class="input-group">
                      <label for="name">Age</label>
                      <input
                        type="number"
                        id="name"
                        v-model="user.age"
                        placeholder="Age"
                      />
                    </div>
                  </div>
                  <!-- gender -->
                  <div class="form-group">
                    <div class="input-group">
                      <label for="name">Gender</label>
                      <select name id v-model="user.gender">
                        <option value>Select Gender</option>
                        <option value="0">Male</option>
                        <option value="1">Female</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label for="image">Image</label>
                      <ImageUploader
                        :image="user.fileName"
                        :imagePath="'/img/library/'"
                        @imageUploaded="user.fileName = $event"
                      />
                    </div>
                  </div>
                  <div class="submit">
                    <button type="submit" class="btn btn-primary">
                      Update Profile
                    </button>
                    <button
                      type="submit"
                      class="btn cancel"
                      @click="$router.go(-1)"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="left" v-if="editing == 'password'">
              <form action class="add-library" @submit.prevent="editPassword">
                <div class="form-title">
                  <h3>Change your password</h3>
                </div>
                <div class="form-body">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="name">Current password</label>
                      <input
                        type="password"
                        id="name"
                        v-model="passwords.currentPassword"
                        placeholder="Current password"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label for="name">New password</label>
                      <input
                        type="password"
                        id="name"
                        v-model="passwords.newPassword"
                        placeholder="New password"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label for="name">Confirm password</label>
                      <input
                        type="password"
                        id="name"
                        v-model="passwords.confirm"
                        placeholder="Confirm password"
                      />
                    </div>
                  </div>
                  <div class="submit">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AdminRegFooter />
  </div>
</template>

<script>
export default {
  name: "AdminProfile",
  components: {},
  data() {
    return {
      user: {},
      passwords: {
        current: "",
        newPassword: "",
        confirm: "",
      },
      showDeleteConfirm: false,
      editing: "profile",
      userId: "",
    };
  },
  methods: {
    getProfile() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROFILE", { userId: this.userId })
        .then((res) => {
          this.$stopLoader();
          this.user = res.data.user;
        })
        .catch((err) => {
          console.log("f");
        });
    },
    editProfile() {
      // check if all fields on user are filled
      if (
        this.user.fullName != "" &&
        this.user.age != "" &&
        this.user.email != "" &&
        this.user.mobile &&
        this.user.userId != "" &&
        this.user.fileName != ""
      ) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_PROFILE", this.user)
          .then((res) => {
            this.$stopLoader();
            this.$showNotification(res.data.message);
            this.getProfile();
          })
          .catch((err) => {
            console.log("f");
          });
      } else {
        this.$showNotification("Please fill all fields");
      }
    },
    editPassword() {
      if (
        this.passwords.currentPassword != "" &&
        this.passwords.newPassword != "" &&
        this.passwords.confirm != ""
      ) {
        if (this.passwords.newPassword == this.passwords.confirm) {
          this.$startLoader();
          this.$store
            .dispatch("UPDATE_PASSWORD", {
              userId: this.userId,
              currentPassword: this.passwords.currentPassword,
              newPassword: this.passwords.newPassword,
            })
            .then((res) => {
              this.$stopLoader();
              this.$showNotification(res.data.message);
              this.passwords = {
                currentPassword: "",
                newPassword: "",
                confirm: "",
              };
            })
            .catch((err) => {
              if (err.response.data.error) {
                this.$showNotification(err.response.data.error);
              }
              this.$stopLoader();
            });
        } else {
          this.$showNotification("Passwords do not match");
        }
      } else {
        this.$showNotification("Please fill all fields");
      }
    },
  },
  mounted() {
    this.userId = this.$loggedAdmin().userId;
    this.getProfile();
  },
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.AdminProfile {
  .manage-widget {
    .navigation {
      border-bottom: 1px solid #c4c4c4;
      margin: 2rem 0;
      width: 95%;
      ul {
        display: flex;

        li {
          font-size: 13px;
          font-weight: 400;
          color: #000;
          padding: 10px 20px;
          &:nth-child(1) {
            border-right: 1px solid #c4c4c4;
          }
          cursor: pointer;
          &.active {
            color: $EEskyblue;
          }
          &:hover {
            color: $EEskyblue;
          }
        }
      }
    }
    .content {
      display: grid;
      grid-template-columns: 95%;
      column-gap: 4%;
      .left {
        height: 70vh;
        overflow-y: auto;
        .add-library {
          width: 100%;
          .form-title {
            h3 {
              font-size: 20px;
              font-weight: 600;
              color: #000;
            }
          }
          .form-body {
            margin-top: 2rem;
            .form-group {
              &.in-2 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 2%;
              }
              .input-group {
                margin-bottom: 20px;
                label {
                  font-size: 14px;
                  font-weight: 600;
                  color: #000;
                  margin-bottom: 10px;
                  display: block;
                  text-align: left;
                }
                input,
                select {
                  width: 100%;
                  height: 40px;
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  padding: 0 10px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #000;
                  outline: none;
                }
                .radio-btns {
                  display: flex;
                  align-items: center;
                  .radio-btn {
                    margin-right: 20px;
                    input {
                      display: none;
                    }
                    label {
                      font-size: 14px;
                      font-weight: 400;
                      color: #000;
                      cursor: pointer;
                      position: relative;
                      padding-left: 30px;
                      &:before {
                        content: "";
                        width: 20px;
                        height: 20px;
                        border: 1px solid #ccc;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 0;
                      }
                      &:after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: $EEskyblue;
                        position: absolute;
                        left: 5px;
                        top: 5px;
                        display: none;
                      }
                    }
                    input:checked + label:after {
                      display: block;
                    }
                  }
                }
              }
            }
            .submit {
              text-align: left;
              display: flex;
              column-gap: 1rem;
              button {
                padding: 10px 50px;
                font-size: 14px;
                font-weight: 600;
                color: #fff;
                background: $EEskyblue;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                outline: none;
                &.cancel {
                  background: #515151;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>