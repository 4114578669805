
<template>
    <div class="Bugs_container" :class="maxmize ? 'onMaximize' : 'onMinimize'">
        <div class="header" @click="maxmize = !maxmize">
            {{ maxmize? '- Bugs': '+' }}
        </div>
        <div class="bugMenu">
            <templalate v-if="!maxmize">
                <div class="box_data">
                    <h2>
                        Bugs
                    </h2>
                </div>
            </templalate>

            <div class="other_menu" v-if="maxmize">
                <BufferMenu></BufferMenu>
                <InTechSupport></InTechSupport>
                <Done></Done>
            </div>


        </div>
    </div>
</template>

<script>
import BufferMenu from './BufferMenu.vue'
import Done from './Done.vue'
import InTechSupport from './InTechSupport.vue'
export default {
    name: "bugs",
    components: { Done, InTechSupport, BufferMenu },
    data() {
        return {
            maxmize: false,
        }
    },
}
</script>

<style lang="scss" scoped>
@import './../StylesConst/fonts.scss';

.Bugs_container {
    height: 100%;
    transition: all 0.5s ease-in-out;

    .header {
        height: 26px;
        display: grid;
        place-content: center;
        border: none;
        border-bottom: 2px solid $primary_border_color;
        border-right: 2px solid $primary_border_color;
        cursor: pointer;
    }

    .bugMenu {
        display: grid;
        min-height: 100%;

        .box_data {
            text-align: center;
            display: grid;
            place-content: center;
            padding-top: 80px;

            h2 {
                writing-mode: vertical-rl;
                text-orientation: mixed;
                transform: rotate(180deg);
            }
        }

        .other_menu {
            height: 100%;
            display: flex;
            border: none;

            &:last-child {
                border: none;
            }
        }
    }

}

.onMinimize {
    max-width: 100px;
    min-width: 100px;

    .bugMenu {
        border-right: 2px solid $primary_border_color;
    }
}

.onMaximize {}

.menu_last {
    border: none !important
}
</style>