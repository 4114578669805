<template>
    <div class="subscription_container">

        <!-- Admin List -->
        <div v-if="subScription.SubscriptionsList">
            <table>
                <tr>
                    <th>School Name</th>
                    <th>No of active users</th>
                    <th>
                        <div class="headerHorizontal">
                            <input type="checkbox" @change="all = !all, checkAll()" :checked="all" :value="allchecked">
                            <span>Subscription Keys</span>
                            <span></span>
                        </div>

                    </th>

                    <th>
                        <div class="headerHorizontal">
                            <span>Account Name</span>
                            <i class="fa  fa-chevron-down"></i>
                        </div>
                    </th>

                    <th>
                        <div class="headerHorizontal">
                            <span>Expiry Date</span>
                            <i class="fa  fa-chevron-up"></i>
                        </div>

                    </th>
                    <th>
                        <div class="headerHorizontal">
                            <span>
                                Region
                            </span>
                        </div>
                    </th>
                    <th>Status</th>
                </tr>
                <template v-if="isLoading">
                    <tr v-for="row in 50" :key="row + (Math.random() + 1).toString(36).substring(2)">
                        <td v-for="column in 7" :key="column">
                            <skeleton-loader :width="100" :height="10" type="rect" animation="fade"
                                style="margin: 0 auto" />
                        </td>
                    </tr>
                </template>

                <template v-if="!isLoading">
                    <template v-for="(data, i) in subData">
                        <template>
                            <tr :key="data.id" :class="i % 2 === 0 ? 'channelOdd' : ''">
                                <td class="headerHorizontal">
                                    <div><i @click="showCollapse" class="fa  fa-chevron-down"></i></div>
                                    <div style="flex: 1;margin-left: 14px">{{ data.name }}</div>
                                    <span></span>
                                </td>
                                <td>Active Users: {{ data.totalActiveUsers
                                }}</td>
                                <td>
                                    Total Keys: {{ data.totalSubcriptionsKeys
                                    }}
                                </td>
                                <td>
                                    -
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                        </template>
                        <template>
                            <tr class="collapse " :class="i % 2 === 0 ? 'channelOdd' : ''" :key="data.id">
                                <td class="headerHorizontal">
                                    <div><i @click="reverseCollapse" class="fa  fa-chevron-up"></i></div>
                                    <div style="flex: 1;margin-left: 14px">{{ data.name }}</div>
                                    <span></span>
                                </td>
                                <td>
                                    <div class="headerHorizontalIntableEXC">
                                        <h5>Enabled:{{ data.totalActiveUsers }}</h5>
                                        <h5>Available:{{ data.totalSubcriptionsKeys - data.totalActiveUsers }}</h5>
                                        <h5>Total Keys:{{ data.totalSubcriptionsKeys }}</h5>
                                    </div>
                                </td>
                                <td>
                                    <div class="headerHorizontalIntable" v-for="keyInfo in data.activationCodes"
                                        :key="keyInfo.key">
                                        <input type="checkbox" :value="keyInfo.activationId" class="userKey" :checked="all"
                                            name='userKey' @change="singlechecked(keyInfo.activationId, $event)">
                                        <span @click="gotoEdit(keyInfo.activationId, keyInfo.isUserFound)"
                                            :class="keyInfo.isUserFound ? 'keyHighlight' : ''">{{ keyInfo.key
                                            }}</span>
                                        <span></span>

                                    </div>

                                </td>
                                <td>
                                    <div class="headerHorizontalIntable" v-for="(keyAccount) in data.activationCodes" :key="keyAccount.userAccount
                                        .fullName + Math.random()">
                                        {{ keyAccount.userAccount
                                            .fullName ? keyAccount.userAccount
                                            .fullName : 'N/A' }}
                                    </div>
                                </td>
                                <td>
                                    <div class="headerHorizontalIntable" v-for="(keyExpire) in data.activationCodes"
                                        :key="keyExpire.expireBy + (Math.random() + 1).toString(36).substring(2)">
                                        {{ keyExpire.expireBy
                                            ? keyExpire.expireBy : 'N/A' }}
                                    </div>
                                </td>
                                <td>
                                    <div class="headerHorizontalIntable" v-for="(keyRegion) in data.activationCodes"
                                        :key="keyRegion.userAccount.phone + (Math.random() + 1).toString(36).substring(3)">
                                        {{ keyRegion.userAccount
                                            .region ? keyRegion.userAccount
                                            .region : 'N/A' }}
                                    </div>
                                </td>
                                <td>
                                    <div class="statusCheck" v-for="(keyisRevoked) in data.activationCodes"
                                        :key="keyisRevoked.activationId + (Math.random() + 1).toString(36).substring(4)">
                                        <label for="status"
                                            @click="activateStatus(keyisRevoked, keyisRevoked.isUserFound, $event)"
                                            :class="keyisRevoked.isRevoked ? 'special' : ''">
                                            <div></div>
                                        </label>
                                        <input type="checkbox" id="status">
                                    </div>

                                </td>
                            </tr>
                        </template>


                    </template>

                </template>

            </table>
        </div>

        <!-- Edit admin -->
        <EditSubscriptions v-if="subScription.editSubscriptions.show" @updateKey='updateKey' />

        <!-- Add Subscription -->
        <AddSubscriptions v-if="subScription.generate" @listRefreshed='listRefreshed' />
        <!--export Subscription -->
        <Export v-if="subScription.export" />

        <!-- Items delete box -->
        <div class="delete-box delete-show" ref="refDeleteBox">
            <div class="items">
                <div class="cross" @click="resetDeleteAction">
                    <i class="fa fa-times"></i>
                </div>
                <div class="selected-number">
                    <span>{{ [...new Set(itemToDelete)].length }}</span>
                </div>
                <h3>Items selected</h3>
            </div>
            <div class="actions">
                <button @click="confirmDelete = true"><i class="fa fa-trash"></i> Delete</button>
            </div>
        </div>

        <!-- delete all confirmation Box -->
        <div class="confirm_container" v-if="confirmDelete">
            <div class="box_conf">
                <p>Are you sure you want to delete all selected users?</p>
                <div class="action">
                    <div class="cancel">
                        <button class="cancelbtn" @click="confirmDelete = false">Cancel</button>
                    </div>
                    <div class="confirm" @click="deleteKey">
                        <button class="confirmbtn">
                            <Spinner size="18" v-if="isRequesting"></Spinner>
                            <span v-if="!isRequesting">Yes,Delete </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import AddSubscriptions from './AddSubscriptions.vue'
import EditSubscriptions from './EditSubscriptions.vue'
import Export from './Export.vue'
import Spinner from 'vue-simple-spinner'
export default {
    components: { AddSubscriptions, EditSubscriptions, Export, Spinner },
    mounted() {
        let vm = this;
        vm.getSubInfo();
    },
    data() {
        return {
            all: false,
            itemToDelete: [],
            allchecked: false,
            confirmDelete: false,
            subScription: this.$store.state.ControlTab.SubscriptionsTabSwitcher,
            tableData: '',
            showEdit: false,
            subData: [],
            isLoading: true,
            isRequesting: false
        }
    },
    watch: {
        '$store.state.ControlTab.SubscriptionsTabSwitcher': function () {
            this.subScription = this.$store.state.ControlTab.SubscriptionsTabSwitcher
        },
        '$store.state.searchTerm': function () {
            let vm = this;
            vm.getSubInfo(vm.$store.state.searchTerm)
        }
    },
    methods: {
        activateStatus(keyisRevoked, user, e) {
            let vm = this;
            if (user) {
                e.target.classList.toggle('special')
                let data = {
                    keyId: keyisRevoked.activationId,
                    isRevoked: !keyisRevoked.isRevoked
                }
                vm.$store.dispatch('POST_KEYS_STATUS', data).then(res => {
                    vm.getSubInfo();
                }).catch(e => {

                })
            } else {
                vm.$toast.warning("No user associated with this key")
            }

        },
        checkAll() {
            let vm = this;
            vm.allchecked = !vm.allchecked;
            vm.$refs.refDeleteBox.classList.remove('delete-show')
            if (vm.allchecked) {
                document.querySelectorAll('.userKey').forEach(el => {
                    vm.itemToDelete.push(+el.value)
                })
                vm.$refs.refDeleteBox.classList.remove('delete-show')

            } else {
                vm.$refs.refDeleteBox.classList.add('delete-show');
                vm.itemToDelete = [];
            }

        },
        singlechecked(id, e) {
            e.preventDefault();
            let vm = this;
            setTimeout(function () {
                if (e.target.checked) {
                    vm.$refs.refDeleteBox.classList.remove('delete-show')
                    vm.itemToDelete.push(id)
                } else {
                    let target = e.target.value;
                    if (vm.itemToDelete.indexOf(+target) > -1) {
                        vm.itemToDelete.splice(vm.itemToDelete.indexOf(+target), 1)
                    }
                    vm.itemToDelete.length === 0 ? vm.$refs.refDeleteBox.classList.add('delete-show') : ''
                }
            }, 0);
        },
        resetDeleteAction() {
            let vm = this;
            document.querySelectorAll('input[name="userKey"]:checked').forEach(CheckBox => {
                CheckBox.checked = false;
            })
            vm.itemToDelete = [];
            vm.$refs.refDeleteBox.classList.add('delete-show');
            vm.all = false;
            vm.allchecked = false;

        }
        ,
        showCollapse(e) {
            let clicked = e.target;
            clicked.parentElement.parentElement.parentElement.classList.add('collapse')
            clicked.parentElement.parentElement.parentElement.nextElementSibling.classList.remove('collapse')
        },
        reverseCollapse(e) {
            let clicked = e.target;
            clicked.parentElement.parentElement.parentElement.classList.add('collapse')
            clicked.parentElement.parentElement.parentElement.previousElementSibling.classList.remove('collapse')
        },
        gotoEdit(id, user) {
            let vm = this;
            if (user) {
                vm.$store.commit("EDIT_SUBSCRIPTIONS", { id })
            } else {
                vm.$toast.warning("No user associated with this key")
            }

        },
        getSubInfo(search = null) {
            let vm = this;
            vm.$store.dispatch('POST_GET_KEYS', {
                "searchKeyValue": search
            }).then(res => {
                vm.subData = res.data.educators
                vm.isLoading = false
            }).catch(e => {
                vm.isLoading = false
                vm.$toast.warning("Something went wrong!")
            }

            )

        },
        listRefreshed() {
            let vm = this;
            vm.getSubInfo()
        },
        updateKey() {
            let vm = this;
            vm.getSubInfo()
        },
        deleteKey() {
            let vm = this;
            vm.isRequesting = true;
            vm.$store.dispatch('POST_KEYS_DELETE', {
                "selectedKeys": vm.itemToDelete
            })
                .then(res => {
                    vm.isRequesting = false;
                    vm.confirmDelete = false;
                    vm.resetDeleteAction();
                    vm.isLoading = true;
                    vm.$toast.success("Key deleted successful");
                    vm.getSubInfo()
                }).catch(e => {
                    vm.isRequesting = false;
                    this.$toast.error("Something Went wrong!");
                })
        }
    },
}
</script>

<style lang="scss" scoped >
@import '../stylesConst/fonts.scss';

div {
    .organization_name {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        color: #666666;
    }

    h1 {
        font-weight: 600;
        color: $secondary_font_color;
        font-size: 16px;
    }

    table {
        width: 100%;
        border: none;
        color: #636363;
        ;

        .collapse {
            display: none;
            transition: all 0.5s ease;
        }


        tr {
            background: white;
            border: none;
            transition: all 0.5s ease;

            &:hover {
                /* background: $secondary_hover_color; */
            }



            th {
                background: #d2d2d5;
                border: none;
                padding: 10px;
                font-size: 13px;
                border-right: 0;
                color: #666666;

                &:not(:last-child) {
                    border-right: 3px solid rgb(255, 255, 255);

                }


                input {
                    cursor: pointer;
                }

                div {
                    i {
                        cursor: pointer;
                    }
                }

            }

            td {
                text-align: center;
                padding: 10px;
                font-size: 13px;

                input {
                    cursor: pointer;
                }

                div {
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    i {
                        cursor: pointer;
                        height: 20px;
                        width: 20px;
                        background: rgb(90, 83, 83);
                        display: grid;
                        place-content: center;
                        font-size: 11px;
                        border-radius: 50%;
                        color: #d2d2d5;
                    }
                }

            }
        }

        .channelOdd {
            background: #f3f3f7;
        }
    }
}


.headerHorizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .adminAvatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
            border: 2px solid $primary_bg_color;
        }
    }
}

.headerHorizontalIntable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 16px;
}

.headerHorizontalIntableEXC {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.statusCheck {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    label {
        height: 20px;
        width: 40px;
        background: rgb(194, 194, 194);
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        div {
            height: 20px;
            width: 20px;
            background: rgb(235, 233, 233);
            border-radius: 50%;
            position: relative;
            right: 0;
            transition: all 0.2s ease;
            pointer-events: none !important;


        }

    }

    input {
        visibility: hidden;
    }
}

.special {
    background: #01cffe !important;

    div {
        right: -20px !important;
        background: rgb(235, 233, 233) !important;
        pointer-events: none !important;
    }
}

.subscription_container {
    position: relative;

    .delete-box {
        position: fixed;
        bottom: 100px;
        width: 300px;
        border-radius: 15px;
        height: 150px;
        background: white;
        z-index: 100;
        left: 50%;
        transform: translate(-25%, 0);
        box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: space-between;
        font-size: 16px;
        transition: all 0.5s ease-in-out;

        .items {
            display: flex;
            align-items: center;

            .cross {
                display: flex;
                align-items: center;
                font-size: 18px;
                margin-right: 4px;
                cursor: pointer;

            }

            .selected-number {
                width: 40px;
                height: 40px;
                padding: 10px;
                border-radius: 50%;
                display: grid;
                place-content: center;
                background: $primary_bg_color;
                margin-right: 4px;

            }
        }

        .actions {
            button {
                padding: 8px 12px;
                border-radius: 4px;
                border: none;
                background: $primary_bg_color;
                outline: none;
                font-weight: 700;
                cursor: pointer;
                color: #2c3e50;
            }
        }
    }
}

.delete-box {
    position: fixed;
    bottom: 100px;
    width: 300px;
    border-radius: 15px;
    height: 150px;
    background: white;
    z-index: 100;
    left: 50%;
    transform: translate(-25%, 0);
    box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    font-size: 16px;
    transition: all 0.5s ease-in-out;

    .items {
        display: flex;
        align-items: center;

        .cross {
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-right: 4px;
            cursor: pointer;

        }

        .selected-number {
            min-width: 40px;
            min-height: 40px;
            padding: 10px;
            border-radius: 50%;
            display: grid;
            place-content: center;
            background: $primary_bg_color;
            margin-right: 4px;

        }
    }

    .actions {
        button {
            padding: 8px 12px;
            border-radius: 4px;
            border: none;
            background: $primary_bg_color;
            outline: none;
            font-weight: 700;
            cursor: pointer;
            color: #2c3e50;
        }
    }
}

.delete-show {
    bottom: -200px !important;
}

.confirm_container {

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #03030396;
    z-index: 100;
    display: grid;
    place-content: center;

    .box_conf {
        width: 358px;
        background-color: white;
        border-radius: 6px;
        color: #4a4a4a;
        display: block;
        padding: 1.25rem;

        p {
            text-align: center;
        }

        .action {
            display: flex;
            justify-content: center;
            column-gap: 12px;

            button {
                width: 98px;
                padding: 8px 4px;
                margin-top: 20px;
                cursor: pointer;
                border: none;
                font-weight: bold;
                border-radius: 6px;
                box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 9px rgb(216, 216, 216);
            }

            .cancelbtn {}

            .confirmbtn {
                background: #92e8ff;
            }
        }
    }


}

.keyHighlight {
    cursor: pointer;
    border-bottom: 1px solid #059ec1;

    &:hover {
        color: #059ec1
    }
}
</style>