<template>
  <div class="ManageLibraries">
    <ConfirmPop
      v-if="showDeleteConfirm"
      @cancel="showDeleteConfirm = false"
      @confirm="removeLibrary"
      :text="`Are you sure you want to delete ${selectedLibrary.name}?`"
    />
    <div class="scoreContent">
      <ScoreLibrarySidebar />
      <div class="score-body">
        <ScoreLibraryTopbar :pageTitle="'Manage Libraries'" />
        <div class="manage-widget">
          <div class="left" v-if="editing">
            <form action class="add-library" @submit.prevent="editLibrary">
              <div class="form-title">
                <h3>Edit Library</h3>
              </div>
              <div class="form-body">
                <div class="form-group">
                  <label for="name">Library Name</label>
                  <input
                    type="text"
                    id="name"
                    v-model="selectedLibrary.name"
                    placeholder="Library name"
                  />
                </div>
                <div class="form-group">
                  <label for="name">Short name</label>
                  <input
                    type="text"
                    id="name"
                    v-model="selectedLibrary.shortName"
                    placeholder="Short name"
                  />
                </div>
                <div class="form-group">
                  <label for="image">Library Image</label>
                  <ImageUploader
                    :image="selectedLibrary.image"
                    :imagePath="'/img/library/'"
                    @imageUploaded="selectedLibrary.image = $event"
                  />
                </div>
                <div class="submit">
                  <button type="submit" class="btn btn-primary">Update Library</button>
                  <button type="submit" class="btn cancel" @click="editing = false;">Done</button>
                </div>
              </div>
            </form>
          </div>
          <div class="left" v-else>
            <form action class="add-library" @submit.prevent="addLibrary">
              <div class="form-title">
                <h3>Add Library</h3>
              </div>
              <div class="form-body">
                <div class="form-group">
                  <label for="name">Library Name</label>
                  <input type="text" id="name" v-model="library.name" placeholder="Library name" />
                </div>
                <div class="form-group">
                  <label for="name">Short name</label>
                  <input type="text" id="name" v-model="library.shortName" placeholder="Short name" />
                </div>
                <div class="form-group">
                  <label for="image">Library Image</label>
                  <ImageUploader :image="library.image" @imageUploaded="library.image = $event" />
                </div>
                <div class="submit">
                  <button type="submit" class="btn btn-primary">Add Library</button>
                </div>
              </div>
            </form>
          </div>
          <div class="right">
            <label for class="libraries-title">Libraries</label>
            <div class="libraries-section">
              <div class="libraries-list">
                <div class="div library-item" v-for="library in libraries" :key="library.id">
                  <div class="library-image">
                    <img :src="$store.state.assets_base_url+library.image" />
                  </div>
                  <div class="library-name">
                    <label for>{{library.name}} - {{library.shortName}}</label>
                    <div class="edit">
                      <button class="btn btn-primary" @click.prevent="startEdit(library)">Edit</button>
                      <button class="btn btn-danger" @click.prevent="deleteLibrary(library)">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AdminRegFooter />
  </div>
</template>

<script>
export default {
  name: "ManageLibraries",
  components: {},
  data() {
    return {
      library: {
        name: "",
        shortName: "",
        image: ""
      },

      libraries: [],
      selectedLibrary: {},
      showDeleteConfirm: false,
      editing: false
    };
  },
  methods: {
    deleteLibrary(library) {
      this.selectedLibrary = library;
      this.showDeleteConfirm = true;
    },
    removeLibrary() {
      this.showDeleteConfirm = false;
      this.$startLoader();
      this.$store
        .dispatch("DELETE_LIBRARY", this.selectedLibrary.id)
        .then(res => {
          this.$stopLoader();
          this.$showNotification(res.data.message);
          this.getLibraries();
        })
        .catch(err => {
          this.stopLoader();
          this.$showNotification(err.error);
        });
    },
    startEdit(library) {
      this.selectedLibrary = library;
      this.editing = true;
    },
    editLibrary() {
      this.$startLoader();
      if (
        this.selectedLibrary.name == "" ||
        this.selectedLibrary.image == "" ||
        this.selectedLibrary.shortName == ""
      ) {
        this.$showNotification("Please fill all the fields");
        this.$stopLoader();
        return;
      } else {
        this.$store
          .dispatch("UPDATE_LIBRARY", this.selectedLibrary)
          .then(res => {
            this.$stopLoader();
            this.$showNotification(res.data.message);
            this.getLibraries();
            this.editing = false;
          })
          .catch(err => {
            this.stopLoader();
            this.$showNotification(err.error);
          });
      }
    },
    addLibrary() {
      this.$startLoader();
      if (
        this.library.name == "" ||
        this.library.image == "" ||
        this.library.shortName == ""
      ) {
        this.$showNotification("Please fill all the fields");
        this.$stopLoader();
        return;
      } else {
        this.$store
          .dispatch("ADD_LIBRARY", this.library)
          .then(res => {
            this.$stopLoader();
            this.$showNotification(res.data.message);
            this.getLibraries();
            this.library = {
              name: "",
              shortName: "",
              image: ""
            };
          })
          .catch(err => {
            this.stopLoader();
            this.$showNotification(err.error);
          });
      }
    },
    getLibraries() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LIBRARIES")
        .then(res => {
          this.$stopLoader();
          this.libraries = res.data.modules;
        })
        .catch(err => {
          this.$stopLoader();
          if (err.error) {
            this.$showNotification(err.error);
          } else {
            this.$showNotification("Something went wrong!!!");
          }
        });
    }
  },
  mounted() {
    this.getLibraries();
  }
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.ManageLibraries {
  display: flex;
  flex-direction: column;
  .manage-widget {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
    .left {
      .add-library {
        .form-title {
          h3 {
            font-size: 20px;
            font-weight: 600;
            color: #000;
          }
        }
        .form-body {
          margin-top: 2rem;
          .form-group {
            margin-bottom: 20px;
            label {
              font-size: 14px;
              font-weight: 600;
              color: #000;
              margin-bottom: 10px;
              display: block;
              text-align: left;
            }
            input {
              width: 100%;
              height: 40px;
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 0 10px;
              font-size: 14px;
              font-weight: 400;
              color: #000;
              outline: none;
            }
            .radio-btns {
              display: flex;
              align-items: center;
              .radio-btn {
                margin-right: 20px;
                input {
                  display: none;
                }
                label {
                  font-size: 14px;
                  font-weight: 400;
                  color: #000;
                  cursor: pointer;
                  position: relative;
                  padding-left: 30px;
                  &:before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    border: 1px solid #ccc;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 0;
                  }
                  &:after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: $EEskyblue;
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    display: none;
                  }
                }
                input:checked + label:after {
                  display: block;
                }
              }
            }
          }
          .submit {
            text-align: left;
            display: flex;
            column-gap: 1rem;
            button {
              padding: 10px 50px;
              font-size: 14px;
              font-weight: 600;
              color: #fff;
              background: $EEskyblue;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              outline: none;
              &.cancel {
                background: #515151;
              }
            }
          }
        }
      }
    }
    .right {
      .libraries-title {
        font-size: 20px;
        font-weight: 600;
        color: #000;
        margin-bottom: 20px;
      }
      .libraries-section {
        margin-top: 2rem;
        background: #eee;
        padding: 1rem;
        height: 70vh;
        overflow-y: auto;
        border-radius: 5px;
        .libraries-list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
          .library-item {
            .library-image {
              width: 100%;
              height: 100px;
              border-radius: 5px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              &.darker {
                img {
                  filter: brightness(0.5);
                }
              }
            }
            .library-name {
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              label {
                font-size: 15px;
                font-weight: 600;
                color: #000;
                text-align: left;
                display: block;
              }
              .edit {
                button {
                  padding: 5px 10px;
                  font-size: 14px;
                  font-weight: 600;
                  color: #fff;
                  border: none;
                  border-radius: 5px;
                  cursor: pointer;
                  outline: none;
                  &:first-child {
                    background: $EEskyblue;
                    margin-right: 10px;
                  }
                  &:last-child {
                    background: #515151;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>