import axios from "axios";
const instance = axios.create({
  // baseURL: process.env.NODE_ENV !== "production" ? "http://localhost:5174" : "http://13.215.247.43",
  baseURL: "http://13.215.247.43",
});

export const AXIOS_POST = async (Url, data = null, token = null) => {
  return await instance({
    url: Url,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const AXIOS_GET = async (Url, token = null) => {
  return await instance({
    url: Url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const AXIOS_PUT = async (Url, data, token = null) => {
  return await instance({
    url: Url,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const AXIOS_DELETE = async (Url, data, token = null) => {
  const result = await instance({
    url: Url,
    method: "DELETE",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return result;
};

export const AXIOS_POST_FILE = async (Url, data, token = null) => {
  return await instance({
    url: Url,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export default instance;
