export const POST_CHANNEL_CREATE = "POST_CHANNEL_CREATE";
export const POST_CHANNEL_UPDATE = "POST_CHANNEL_UPDATE";
export const POST_CHANNEL_EDIT = "POST_CHANNEL_EDIT";
export const POST_GET_CHANNELS = "POST_GET_CHANNELS";
export const POST_CHANNELS_EXPORT = "POST_CHANNELS_EXPORT";
export const POST_CHANNELS_DELETE = "POST_CHANNELS_DELETE";
export const POST_CHANNEL_STATUS_UPDATE = "POST_CHANNEL_STATUS_UPDATE";
export const POST_CHANNEL_REPORT = "POST_CHANNEL_REPORT";
export const GET_CHANNELS_UNASSIGNED_EDUCATORS =
  "GET_CHANNELS_UNASSIGNED_EDUCATORS";
