<template>
  <div id="chart" v-if="options.series.length > 0">
    <apexchart
      :type="options.chart.type"
      :width="options.chart.width"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    series: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: "pie",
          width: 350
        },
        series: [],
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ],
        theme: {
          monochrome: {
            enabled: true,
            color: "#3dd1d1",
          },
        }
      }
    };
  },
  mounted() {
    this.options.series = this.series;
    this.options.labels = this.labels;
  }
};
</script>

<style>
</style>