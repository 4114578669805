import { AXIOS_GET } from "./../../Config/Axios_UserMagment/axios.js";
import { GET_REGIONS } from "./../actions/Common.js";
import { GET_REGIONS_URL } from "./../Variables.js";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [GET_REGIONS]: ({ rootState }, payload) => {
    return AXIOS_GET(GET_REGIONS_URL, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
