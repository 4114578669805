const state = {
  AdminSwitcher: {
    adminList: true,
    addNewAdmin: false,
    editAdmin: { show: false, id: 0 },
  },
};

const mutations = {
  VIEW_ADMIN_LIST() {
    state.AdminSwitcher = {
      adminList: true,
      addNewAdmin: false,
      editAdmin: { show: false, id: 0 },
    };
  },
  VIEW_ADMIN_ADD() {
    state.AdminSwitcher = {
      adminList: false,
      addNewAdmin: true,
      editAdmin: { show: false, id: 0 },
    };
  },
  VIEW_ADMIN_EDIT(state, payload) {
    state.AdminSwitcher = {
      adminList: false,
      addNewAdmin: false,
      editAdmin: { show: true, id: payload },
    };
  },
};
export default {
  state,
  mutations,
};
