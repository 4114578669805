<template>
    <div class="top_nav">
        <div class="nav_left">
            <div class="menu" @click="$router.push({ path: '/dashboard' })">
                <i class="fa fa-angle-left"></i>
                <span>KanbanBoard</span>
            </div>
            <div class="menu active">
                <i class="fa-regular fa-clipboard"></i>
                <span>Board</span>
            </div>
            <div class="menu">
                <i class="fa-sharp fa-solid fa-gear"></i>
                <span>Settings</span>
            </div>

        </div>
        <div class="nav_right">
            <div class="avatar">
                <i class="fa-solid fa-user"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'topNav',
}
</script>

<style lang="scss" scoped>
@import "./StylesConst/fonts.scss";

.top_nav {
    height: 45px;
    border: none;
    border-bottom: 1px solid #ded7d7;
    box-shadow: $primary_box_shadow;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    background: white;

    .nav_left {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 10px;
        justify-content: center;

        .menu {
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 10px;
            min-width: 150px;
            justify-content: center;
            cursor: pointer;

            i {
                margin-right: 8px;
            }

            &.active {
                background: #e6e6e6;
            }

            &:last-of-type:hover {
                background: #e6e6e6;
            }

        }
    }

    .nav_right {

        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 10px;
        justify-content: center;

        .avatar {
            height: 35px;
            width: 35px;
            border-radius: 50px;
            display: grid;
            place-content: center;
            background: $primary_bg_color;
            color: rgb(0, 226, 19);
            cursor: pointer
        }
    }

}
</style>