<template>
  <div class="student_container">
    <!-- Student List -->
    <div v-if="studentToggle.studentList">
      <h1 class="organization_name" v-if="SchlTeacherId">
        Users Under School/Teacher
      </h1>
      <table>
        <tr>
          <th>
            <div class="headerHorizontal">
              <input
                type="checkbox"
                @change="(all = !all), checkAll()"
                :checked="all"
                :value="allchecked"
              />
              <span>Name</span>
              <div @click="reverseData('fullName')">
                <i class="fa fa-chevron-up"></i>
              </div>
            </div>
          </th>
          <th>
            <div class="headerHorizontal" @click="reverseData('educatorName')">
              <span>Attached School/Teacher</span>
              <i class="fa fa-chevron-down"></i>
            </div>
          </th>
          <th>
            <div class="headerHorizontal" @click="reverseData('modules')">
              <span>Modules</span>
              <i class="fa fa-chevron-down"></i>
            </div>
          </th>
          <th>
            <div class="headerHorizontal" @click="reverseData('modules')">
              <span>Total Fees</span>
              <i class="fa fa-chevron-down"></i>
            </div>
          </th>
          <th>
            <div class="headerHorizontal" @click="reverseData">
              <span> Expiry Date </span>
              <i class="fa fa-chevron-up"></i>
            </div>
          </th>
          <th>Region</th>
          <th>Status</th>
        </tr>
        <template v-if="isLoading">
          <tr v-for="row in 50" :key="row" v-auto-animate>
            <td v-for="column in 8" :key="column">
              <skeleton-loader
                :width="100"
                :height="10"
                type="rect"
                animation="fade"
                style="margin: 0 auto"
              />
            </td>
          </tr>
        </template>
        <template v-if="!isLoading">
          <tr v-for="(userData, i) in usersData" v-auto-animate :key="i">
            <td>
              <div class="headerHorizontal">
                <input
                  type="checkbox"
                  :checked="all"
                  class="eduInput"
                  name="eduInput"
                  :value="userData.studentId"
                  @change="singlechecked(userData.studentId, $event)"
                />
                <span class="alignListing"
                  >{{ userData.fullName ? userData.fullName : "N/A" }}
                </span>
                <div>
                  <i
                    class="fa-solid fa-info"
                    @click="editStudent(userData.studentId)"
                  ></i>
                </div>
              </div>
            </td>
            <td class="alignListing">
              {{ userData.educatorName ? userData.educatorName : "N/A" }}
            </td>
            <td>SR</td>
            <td class="alignListing">-</td>
            <td class="alignListing" :class="userData.expired ? 'expired' : ''">
              {{ userData.expiryDate ? userData.expiryDate.split("T")[0] : "N/A" }}
            </td>
            <td>{{ userData.region ? userData.region : "N/A" }}</td>
            <td>
              <div class="statusCheck">
                <label
                  for="status"
                  @click="
                    activateStatus(
                      userData.studentId,
                      userData.isActive,
                      $event
                    )
                  "
                  :class="userData.isActive ? 'special' : ''"
                >
                  <div></div>
                </label>
                <input type="checkbox" id="status" />
              </div>
            </td>
          </tr>
        </template>
      </table>
      <template v-if="!isLoading && usersData.length === 0">
        <div class="user_not_found">
          <h3>User not found in our collection</h3>
          <!-- <button @click="resetList">Reset list instead</button> -->
        </div>
      </template>
    </div>

    <!-- Edit student -->
    <EditUser v-if="studentToggle.editStudent.show" @triggered="triggered" />

    <!-- Items delete box -->
    <div class="delete-box delete-show" ref="refDeleteBoxStudent">
      <div class="items">
        <div class="cross" @click="resetDeleteAction">
          <i class="fa fa-times"></i>
        </div>
        <div class="selected-number">
          <span>{{ [...new Set(itemToDelete)].length }}</span>
        </div>
        <h3>Items selected</h3>
      </div>
      <div class="actions">
        <button @click="confirmDelete = true">
          <i class="fa fa-trash"></i> Delete
        </button>
      </div>
    </div>

    <!-- delete all confirmation Box -->
    <div class="confirm_container" v-if="confirmDelete">
      <div class="box_conf">
        <p>Are you sure you want to delete all selected users?</p>
        <div class="action">
          <div class="cancel">
            <button class="cancelbtn" @click="confirmDelete = false">
              Cancel
            </button>
          </div>
          <div class="confirm">
            <button class="confirmbtn" @click="deleteUser">
              <Spinner size="18" v-if="isRequesting"></Spinner>
              <span v-if="!isRequesting">Yes,Delete </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditUser from "./EditUser.vue";
import Spinner from "vue-simple-spinner";
export default {
  components: { EditUser, Spinner },
  mounted() {
    let vm = this;
    vm.getAllUsers(vm.SchlTeacherId);
  },
  data() {
    return {
      all: false,
      itemToDelete: [],
      srchTerm: this.$store.state.searchTerm,
      allchecked: false,
      confirmDelete: false,
      studentToggle: this.$store.state.ControlTab.StudentTabSwitcher,
      usersData: [],
      isLoading: true,
      isRequesting: false,
    };
  },
  props: ["SchlTeacherId"],
  watch: {
    "$store.state.ControlTab.StudentTabSwitcher": function () {
      this.studentToggle = this.$store.state.ControlTab.StudentTabSwitcher;
    },
    SchlTeacherId: function () {
      let vm = this;
      vm.isLoading = true;
      vm.getAllUsers(vm.SchlTeacherId);
    },
    "$store.state.ControlTab.refleshTabList": function () {
      this.getAllUsers();
    },
    "$store.state.searchTerm": function () {
      let vm = this;
      vm.$store
        .dispatch("POST_USERS_GET", {
          Page: 1,
          Size: 100,
          teacherId: 0,
          OrganizationId: 0,
          searchQuery: vm.$store.state.searchTerm,
        })
        .then((res) => {
          vm.usersData = res.data?.students;
          vm.isLoading = false;
        })
        .catch((e) => {
          vm.isLoading = false;
          vm.usersData = [];
        });
    },
  },
  methods: {
    activateStatus(id, status, e) {
      let vm = this;
      let newStatus;
      if (status) {
        e.target.classList.remove("special");
        newStatus = 0;
        vm.$store
          .dispatch("POST_USER_UPDATE_STATUS", {
            studentId: id,
            status: newStatus,
          })
          .then((res) => {
            vm.getAllUsers();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        e.target.classList.add("special");
        newStatus = 1;
        vm.$store
          .dispatch("POST_USER_UPDATE_STATUS", {
            studentId: id,
            status: newStatus,
          })
          .then((res) => {
            vm.getAllUsers();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    reverseData() {
      let vm = this;
      vm.usersData.reverse();
    },
    deleteUser() {
      let vm = this;
      vm.isRequesting = true;
      vm.$store
        .dispatch("POST_USERS_DELETE", { usersIds: vm.itemToDelete })
        .then((res) => {
          vm.isRequesting = false;
          vm.confirmDelete = false;
          vm.resetDeleteAction();
          vm.isLoading = true;
          vm.$toast.success("User deleted successful");
          vm.$store.commit("LIST_REFRESHER_FOR_CHANNEL");
        })
        .catch((e) => {
          vm.isRequesting = false;
          this.$toast.error("Something Went wrong!");
        });
    },
    checkAll() {
      let vm = this;
      vm.allchecked = !vm.allchecked;
      vm.$refs.refDeleteBoxStudent.classList.remove("delete-show");
      if (vm.allchecked) {
        document.querySelectorAll(".eduInput").forEach((el) => {
          vm.itemToDelete.push(+el.value);
        });
        vm.$refs.refDeleteBoxStudent.classList.remove("delete-show");
      } else {
        vm.$refs.refDeleteBoxStudent.classList.add("delete-show");
        vm.itemToDelete = [];
      }
    },
    singlechecked(id, e) {
      let vm = this;
      if (e.target.checked) {
        vm.$refs.refDeleteBoxStudent.classList.remove("delete-show");
        vm.itemToDelete.push(id);
      } else {
        let target = e.target.value;
        if (vm.itemToDelete.indexOf(+target) > -1) {
          vm.itemToDelete.splice(vm.itemToDelete.indexOf(+target), 1);
          console.log(vm.itemToDelete);
        }
        vm.itemToDelete.length === 0
          ? vm.$refs.refDeleteBoxStudent.classList.add("delete-show")
          : "";
      }
    },
    resetDeleteAction() {
      let vm = this;
      document
        .querySelectorAll('input[name="eduInput"]:checked')
        .forEach((CheckBox) => {
          CheckBox.checked = false;
        });
      vm.itemToDelete = [];
      vm.$refs.refDeleteBoxStudent.classList.add("delete-show");
      vm.all = false;
      vm.allchecked = false;
    },
    editStudent(id) {
      let vm = this;
      vm.$store.commit("VIEW_STUDENT_EDIT", { id });
    },
    resetList() {
      let vm = this;
      vm.getAllUsers();
    },
    getAllUsers(id = 0) {
      let vm = this;
      vm.usersData = [];
      vm.$store
        .dispatch("POST_USERS_GET", {
          Page: 1,
          Size: 100,
          teacherId: id,
          OrganizationId: 0,
          searchQuery: "",
        })
        .then((res) => {
          vm.usersData = res.data.students;
          //   look for the student, split the expiry date and check if it is expired
          vm.usersData.forEach((student) => {
            let expiryDate = student.expiryDate.split("T")[0];
            // let today = 30 days from now only year month and day
            let today = new Date();
            today.setDate(today.getDate() + 30);
            today = today.toISOString().split("T")[0];
            if (expiryDate < today) {
              student.expired = true;
            } else {
              student.expired = false;
            }
          });
          vm.isLoading = false;
        })
        .catch((e) => {
          vm.isLoading = false;
          if (e.response.data.error == "No users found") {
            vm.$toast.success("No users found!");
          } else {
            vm.$toast.error("Something went wrong");
          }
        });
    },
    triggered() {
      let vm = this;
      vm.isLoading = true;
      vm.getAllUsers();
    },
  },
};
</script>

<style lang="scss" scoped >
@import "../stylesConst/fonts.scss";

div {
  .organization_name {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: #666666;
  }

  h1 {
    font-weight: 600;
    color: $secondary_font_color;
    font-size: 16px;
  }

  table {
    width: 100%;
    border: none;
    color: #636363;
    tr {
      background: white;
      border: none;

      &:nth-child(odd) {
        background: #f3f3f7;
      }

      &:hover {
        background: $secondary_hover_color;
      }

      th {
        background: #d2d2d5;
        border: none;
        padding: 10px;
        font-size: 13px;
        border-right: 0;
        color: #666666;

        &:not(:last-child) {
          border-right: 3px solid rgb(255, 255, 255);
        }

        input {
          cursor: pointer;
        }

        div {
          i {
            cursor: pointer;
          }
        }
      }

      td {
        text-align: center;
        padding: 10px;
        font-size: 13px;

        input {
          cursor: pointer;
        }

        div {
          display: flex;
          align-items: center;
          gap: 3px;

          i {
            cursor: pointer;
            height: 20px;
            width: 20px;
            background: rgb(90, 83, 83);
            display: grid;
            place-content: center;
            font-size: 11px;
            border-radius: 50%;
            color: #d2d2d5;
          }
        }
      }
    }
  }
}

.headerHorizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.statusCheck {
  display: flex;
  justify-content: center;

  label {
    height: 20px;
    width: 40px;
    background: rgb(194, 194, 194);
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;

    div {
      height: 20px;
      width: 20px;
      background: rgb(235, 233, 233);
      border-radius: 50%;
      position: relative;
      right: 0;
      transition: all 0.2s ease;
      pointer-events: none !important;
    }
  }

  input {
    visibility: hidden;
  }
}

.special {
  background: #01cffe !important;

  div {
    right: -20px !important;
    background: rgb(235, 233, 233) !important;
    pointer-events: none !important;
  }
}

.student_container {
  position: relative;

  .delete-box {
    position: fixed;
    bottom: 100px;
    width: 300px;
    border-radius: 15px;
    height: 150px;
    background: white;
    z-index: 100;
    left: 50%;
    transform: translate(-25%, 0);
    box-shadow: 2px 3px 12px rgb(216, 216, 216),
      -2px -3px 12px rgb(216, 216, 216);
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    font-size: 16px;
    transition: all 0.5s ease-in-out;

    .items {
      display: flex;
      align-items: center;

      .cross {
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-right: 4px;
        cursor: pointer;
      }

      .selected-number {
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 50%;
        display: grid;
        place-content: center;
        background: $primary_bg_color;
        margin-right: 4px;
      }
    }

    .actions {
      button {
        padding: 8px 12px;
        border-radius: 4px;
        border: none;
        background: $primary_bg_color;
        outline: none;
        font-weight: 700;
        cursor: pointer;
        color: #2c3e50;
      }
    }
  }
}

.delete-box {
  position: fixed;
  bottom: 100px;
  width: 300px;
  border-radius: 15px;
  height: 150px;
  background: white;
  z-index: 100;
  left: 50%;
  transform: translate(-25%, 0);
  box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  font-size: 16px;
  transition: all 0.5s ease-in-out;

  .items {
    display: flex;
    align-items: center;

    .cross {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-right: 4px;
      cursor: pointer;
    }

    .selected-number {
      min-width: 40px;
      min-height: 40px;
      padding: 10px;
      border-radius: 50%;
      display: grid;
      place-content: center;
      background: $primary_bg_color;
      margin-right: 4px;
    }
  }

  .actions {
    button {
      padding: 8px 12px;
      border-radius: 4px;
      border: none;
      background: $primary_bg_color;
      outline: none;
      font-weight: 700;
      cursor: pointer;
      color: #2c3e50;
    }
  }
}

.delete-show {
  bottom: -200px !important;
}

.confirm_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #03030396;
  z-index: 100;
  display: grid;
  place-content: center;

  .box_conf {
    width: 358px;
    background-color: white;
    border-radius: 6px;
    color: #4a4a4a;
    display: block;
    padding: 1.25rem;

    p {
      text-align: center;
    }

    .action {
      display: flex;
      justify-content: center;
      column-gap: 12px;

      button {
        width: 98px;
        padding: 8px 4px;
        margin-top: 20px;
        cursor: pointer;
        border: none;
        font-weight: bold;
        border-radius: 6px;
        box-shadow: 2px 3px 12px rgb(216, 216, 216),
          -2px -3px 9px rgb(216, 216, 216);
      }

      .cancelbtn {
      }

      .confirmbtn {
        background: #92e8ff;
      }
    }
  }
}

.user_not_found {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    margin: 8px;
    font-size: 16px;
  }

  button {
    border: 2px solid $primary_bg_color;
    border-radius: 4px;
    width: 150px;
    padding: 8px;
    cursor: pointer;
  }
}

.alignListing {
  flex: 1 !important;
  text-align: start !important;
  padding-left: 7px !important;
  &.expired {
    color: #ff0000 !important;
  }
}
</style>