<template>
  <div class="ScoreLibraryTopbar">
    <ConfirmPop
      v-if="showConfirm"
      @cancel="showConfirm = false"
      @confirm="$adminlogout()"
      :text="'Are you sure you want to logout?'"
    />
    <div class="top-bar">
      <!-- back icon -->
      <router-link v-if="back" :to="{ name: 'ScoreLibraryDashboard' }" class="back-icon" style="margin-right: 1rem">
        <i class="fas fa-caret-left"></i>
        {{pageTitle}}
      </router-link>
      <label class="page-title" v-else>{{pageTitle}}</label>
      <div class="profile-dropdown">
        <div class="user-info">
          <div class="user-img">
            <i class="fas fa-user"></i>
          </div>
        </div>
        <div class="dropdown-items">
          <router-link :to="{name: 'AdminProfile'}" class="dropdown-item">
            <i class="fas fa-user"></i>
            <span>Profile</span>
          </router-link>
          <div class="dropdown-item" @click="showConfirm = true;">
            <i class="fas fa-sign-out-alt"></i>
            <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreLibraryTopbar",
  data() {
    return {
      showConfirm: false
    };
  },
  props: {
    pageTitle: {
      type: String,
      default: "EE Score Library"
    },
    back: {
      type: Boolean,
      default: false
    }
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.ScoreLibraryTopbar {
  margin-top: 3rem;
  width: 95%;
  @media (max-width: 800px) {
    margin-top: 0;
    padding-top: 1.2rem;
    padding-left: 1.2rem;
    box-shadow: 0px 2px 4px 10px #0000001a;
    width: calc(100% - 1.2rem);
  }
  .top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .page-title {
      font-size: 1.65rem;
      font-weight: 400;
      margin-bottom: 1rem;
      color: #000;
      @media (max-width: 800px) {
        font-size: 1.1rem;
      }
    }
    .back-icon {
      font-size: 1.35rem;
      font-weight: 400;
      margin-bottom: 1rem;
      color: $EEskyblue;
      text-decoration: none;
    }
    .user-info {
      display: flex;
      justify-content: center;
      align-items: center;
      .user-img {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background: $EEskyblue;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 800px) {
          height: 25px;
          width: 25px;
          margin-top: -15px;
          margin-right: 15px;
        }
        i {
          color: #fff;
          font-size: 1rem;
          @media (max-width: 800px) {
            font-size: 0.8rem;
          }
        }
      }
    }
    .profile-dropdown {
      position: relative;
      .dropdown-items {
        position: absolute;
        top: 40px;
        right: 0;
        background: #fff;
        box-shadow: 0px 0px 15px 4px #f2f2f2;
        border-radius: 10px;
        z-index: 999;
        display: none;
        .dropdown-item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem 1rem;
          text-decoration: none;
          color: #000;
          font-size: 1rem;
          font-weight: 400;
          i {
            margin-right: 0.5rem;
          }
          &:hover {
            background: $EEskyblue;
            color: #fff;
          }
        }
      }
      &:hover {
        .dropdown-items {
          display: block;
        }
      }
    }
  }
}
</style>