<template>
  <div class="Login">
    <div class="login-wrapper">
      <div class="left-section">
        <div class="left-inner">
          <img
            alt="Vue logo"
            :src="require('@/assets/logo/EEmusic-Logo.png')"
          />
          <label>Admin Panel</label>
        </div>
      </div>
      <div class="right-section">
        <label for class="title">Welcome back!</label>
        <form action class="login">
          <!-- //input for username and password with icons in inputs -->
          <div class="input-wrapper">
            <div class="input-icon">
              <i class="fas fa-user"></i>
            </div>
            <input type="text" placeholder="Email" v-model="user.email" />
          </div>
          <div class="input-wrapper">
            <div class="input-icon">
              <i class="fas fa-lock"></i>
            </div>
            <input
              type="password"
              placeholder="Password"
              v-model="user.password"
            />
          </div>
          <div class="forgot-password">
            <router-link :to="{name: 'ForgotPassword'}">Forgot Password?</router-link>
          </div>
          <button class="login-btn" @click.prevent="login()">Login</button>
          <div class="remember-me">
            <input type="checkbox" id="remember" />
            <label for="remember">Stay signed in</label>
          </div>
        </form>
      </div>
    </div>
    <AdminRegFooter />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Login",
  components: {},
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      loggedUser: {},
      showNotification: false,
      notificationText: "",
      loginTab: 1,
    };
  },
  methods: {
    login() {
      this.$startLoader();
      if (this.user.email === "" || this.user.password === "") {
        this.$stopLoader();
        this.$showNotification("Please fill all fields");
        return;
      } else {
        this.$store
          .dispatch("ADMIN_LOGIN", this.user)
          .then((res) => {
            if (res.status === 200) {
              // save user data in local storage
              localStorage.setItem("EEadmin_token", res.data.token);
              localStorage.setItem(
                "EEadmin_user",
                (this.loggedUser = {
                  token: res.data.token,
                  userId: res.data.userId,
                  userRoleId: res.data.userRoleId,
                  userRole: res.data.userRole,
                  userRoleName: res.data.userRoleName,
                })
              );
              this.$stopLoader();
              // save user token in local storage
              this.$adminLogin(this.loggedUser, res.data.token);
            } else {
              this.$stopLoader();
              this.$showNotification(res.data.error);
            }
          })
          .catch((err) => {
            this.$stopLoader();
            this.$showNotification(err.response.data.error);
          });
      }
    },
  },
  mounted() {
    if (this.$loggedAdmin()) {
      this.$router.push({ name: "Dashboard" });
    }
  },
};
</script>
<style lang="scss" scoped>
.Login {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .login-wrapper {
    margin-top: 10%;
    width: 800px;
    display: grid;
    grid-template-columns: 50% 50%;
    background: #d9d9d9;
    box-shadow: -1px 10px 20px 3px rgb(240, 240, 240);
    border-radius: 10px;
    overflow: hidden;
    @media screen and (max-width: 800px) {
      width: 100%;
      grid-template-columns: 100%;
      width: 90%;
    }
    .left-section {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      .left-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;
        @media screen and (max-width: 800px) {
          row-gap: 10px;
        }
        img {
          width: 120px;
          height: 100px;
          object-fit: contain;
          @media screen and (max-width: 800px) {
            width: 100px;
            height: 80px;
          }
        }

        label {
          color: #000;
          font-size: 2rem;
          font-weight: 200;
          margin-top: 10px;
          @media screen and (max-width: 800px) {
            font-size: 1.5rem;
            margin-top: 0;
          }
        }
      }
    }

    .right-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 4rem;

      .title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 3.5rem;
        margin-top: 2rem;
        @media screen and (max-width: 800px) {
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
      }

      .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;

        .input-wrapper {
          display: flex;
          align-items: center;
          width: 300px;
          height: 40px;
          border-radius: 40px;
          background-color: #fff;
          box-shadow: 0px 0px 10px 0px rgba(205, 205, 205, 0.75);
          overflow: hidden;

          .input-icon {
            width: 45px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 5px 0 0 5px;
            padding-left: 10px;

            i {
              color: rgb(170, 170, 170);
            }
          }

          input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            border-radius: 40px;
            padding-left: 10px;
            font-size: 0.9rem;
            font-weight: 200;
          }
        }

        .forgot-password {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-top: 0.4rem;

          @media screen and (max-width: 800px) {
            margin-top: 0.25rem;
          }
          a {
            text-decoration: none;
            color: #444;
            font-size: 0.75rem;
            font-weight: 200;
          }
        }

        .login-btn {
          width: 100%;
          padding: 15px;
          border: none;
          outline: none;
          border-radius: 40px;
          background-color: #777;
          color: #fff;
          font-size: 0.8rem;
          font-weight: 600;
          cursor: pointer;
        }

        .remember-me {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-start;

          input {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            border: 1px solid #000;
          }

          label {
            font-size: 0.8rem;
            font-weight: 200;
            color: #444;
          }
        }
      }
    }
  }
}
</style>
