<template>
  <div class="ConfirmPop">
    <div class="confirmPop__content">
      <label for>{{text}}</label>
      <div class="confirmPop__content__buttons">
        <button @click="cancel" class="cancel-btn">{{cancelMsg}}</button>
        <button @click="confirm" class="confirm-btn">{{confirmMsg}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmPop",
  props: {
    text: {
      type: String,
      default: "Are you sure you want to delete this item?"
    },
    cancelMsg: {
      type: String,
      default: "Cancel"
    },
    confirmMsg: {
      type: String,
      default: "Yes"
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    }
  }
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.ConfirmPop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .confirmPop__content {
    // gradient background
    background: linear-gradient(180deg, white, #c8c8c8);
    padding: 20px;
    border-radius: 5px;
    width: 390px;
    text-align: center;
    @media (max-width: 500px) {
      width: 80%;
    }
    label {
      display: block;
      margin-bottom: 20px;
      font-size: 1.2rem;
      line-height: 1.2;
      font-weight: 700;
      color: #666;
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
    .confirmPop__content__buttons {
      display: flex;
      justify-content: space-between;
      @media (max-width: 500px) {
        flex-direction: column;
        button {
          margin-bottom: 10px;
        }
      }
      button {
        padding: 8px 20px;
        border: none;
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 700;
        min-width: 160px;
        box-shadow: 2px 3px 2px 1px #afaeae;
        @media (max-width: 700px) {
            font-size: 1rem;
        }
        &.confirm-btn {
          background-color: $EEskyblue;
          color: #fff;
          &:hover {
            background-color: #28baba;
          }
        }
        &.cancel-btn {
          color: #666;
        }
        cursor: pointer;
        &:hover {
          background-color: #d5d5d5;
        }
      }
    }
  }
}
</style>