<template>
  <div class="umgt-edit-channel">
    <h1>
      <span @click="backToChannelListtab"><i class="fa fa-angle-left"></i></span
      >Channel Information

      <span></span>
    </h1>
    <div class="form">
      <form @submit.prevent="SendEditInfo">
        <div class="single">
          <div class="channelReport" @click="showChannelReport(channel.id.id)">
            <i class="fa-solid fa-clipboard-list"></i><span>View Report</span>
          </div>
          <label for="channelName">Channel Name</label>
          <input
            type="text"
            required
            placeholder="Enter name"
            v-model="editForm.channelName"
          />
        </div>
        <div class="group">
          <div class="single">
            <label for="Contact Name">Contact Name</label>
            <input
              type="text"
              required
              placeholder="Enter contact name"
              v-model="editForm.contactName"
            />
          </div>
          <div class="single">
            <label for="ContactNo">Contact No.</label>
            <input
              required
              type="Enter contact number"
              v-model="editForm.contactNumber"
            />
          </div>
          <div class="single">
            <label for="ContactEmail">Contact Email</label>
            <input
              type="email"
              required
              placeholder="Enter email"
              v-model="editForm.contactEmail"
            />
          </div>
        </div>
        <div class="group">
          <div class="single">
            <label for="Contact Name">Region</label>
            <select required v-model="editForm.region">
              <option
                v-for="Region in RegionList"
                :key="Region.code"
                :value="Region.region"
              >
                {{ Region.region }}
              </option>
            </select>
          </div>
          <div class="single">
            <label for="">Status</label>
            <div class="statusCheck">
              <label
                for="status"
                @click="channelStus"
                :class="chActive ? 'special' : ''"
              >
                <div></div>
              </label>
              <input type="checkbox" id="status" />
            </div>
          </div>
        </div>
        <div class="single">
          <label for="ContactEmail">Schools</label>
          <button type="button" @click="showSchools = !showSchools">
            + Add school
          </button>
          <template v-if="SchoolList.length > 0">
            <div class="schoolAdd" v-if="showSchools">
              <div class="search">
                <i class="fa fa-search"></i>
                <input
                  type="text"
                  v-model="searchTerm"
                  placeholder="Search School"
                />
              </div>
              <ul>
                <li
                  v-for="sch in filteredSchool()"
                  :key="sch.educatorId"
                  @click="selectSchl(sch, $event)"
                >
                  {{ sch.educatorName }}
                </li>
              </ul>
            </div>
          </template>
          <template v-else>
            <div class="schoolAdd" v-if="showSchools">
              <ul>
                <li>
                  All educators have been assigned to respective channels;
                  proceed to link them later!
                </li>
              </ul>
            </div>
          </template>
        </div>
        <div class="single">
          <label for="ContactEmail">School Name</label>
          <table>
            <tr
              v-for="newSch in newSchoolList"
              :key="newSch.id"
              class="delete_selected_list"
            >
              <td @click="unselectSchl(newSch, $event)">
                {{ newSch.educatorName }}
                <i class="fa fa-trash"></i>
              </td>
            </tr>
          </table>
        </div>
        <div class="action">
          <button>
            <Spinner size="18" v-if="isRequesting"></Spinner>
            <span v-if="!isRequesting">Save changes</span>
          </button>
          <button type="button" @click="backToChannelListtab">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Spinner from "vue-simple-spinner";
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      channel: this.$store.state.ControlTab.channelTabSwitcher.editChannel,
      showSchools: false,
      chActive:
        this.$store.state.ControlTab.channelTabSwitcher.editChannel.id.channel
          .status,
      RegionList: [],
      SchoolList: [],
      isRequesting: false,
      newSchoolList: [],
      searchTerm: "",
      editForm: {
        channelId:
          this.$store.state.ControlTab.channelTabSwitcher.editChannel.id.channel
            .channelId,
        channelName:
          this.$store.state.ControlTab.channelTabSwitcher.editChannel.id.channel
            .channelName,
        contactName:
          this.$store.state.ControlTab.channelTabSwitcher.editChannel.id.channel
            .contactName,
        contactNumber:
          this.$store.state.ControlTab.channelTabSwitcher.editChannel.id.channel
            .contactNumber,
        contactEmail:
          this.$store.state.ControlTab.channelTabSwitcher.editChannel.id.channel
            .contactEmail,
        region:
          this.$store.state.ControlTab.channelTabSwitcher.editChannel.id.channel
            .region,
        status:
          this.$store.state.ControlTab.channelTabSwitcher.editChannel.id.channel
            .status,
        schools: "",
      },
    };
  },
  created() {
    let vm = this;
    vm.getRegions();
    vm.geteditChannel();
    vm.getUnassignedEducators();
  },
  methods: {
    backToChannelListtab() {
      let vm = this;
      vm.$store.commit("VIEW_CHANNEL_LIST");
    },
    showChannelReport(id) {
      let vm = this;
      vm.$store.commit("VIEW_CHANNEL_REPORT", { id });
    },
    getRegions() {
      let vm = this;
      vm.$store
        .dispatch("GET_REGIONS")
        .then((res) => {
          vm.RegionList = res.data.regions;
        })
        .catch((e) => {
          // console.log(e)
        });
    },
    getUnassignedEducators() {
      let vm = this;
      vm.$store
        .dispatch("GET_CHANNELS_UNASSIGNED_EDUCATORS")
        .then((res) => {
          vm.SchoolList = res.data.educators;
        })
        .catch((e) => {
          this.$toast.warning("Something Went wrong!");
        });
    },
    geteditChannel() {
      let vm = this;
      vm.$store
        .dispatch("POST_CHANNEL_EDIT", { channelId: vm.channel.id.id })
        .then((res) => {
          // console.log(res.data)
          vm.newSchoolList = res.data.channel.educators;
          vm.editForm = {
            channelId: res.data.channel.id,
            channelName: res.data.channel.name,
            contactName: res.data.channel.contactName,
            contactNumber: res.data.channel.contactNumber,
            contactEmail: res.data.channel.contactEmail,
            region: res.data.channel.region,
            status: res.data.channel.status,
          };
        })
        .catch((e) => {
          this.$toast.error("Something went wrong");
        });
    },
    channelStus(e) {
      let vm = this;
      if (vm.chActive) {
        vm.chActive = 0;
        e.target.classList.remove("special");
      } else {
        vm.chActive = 1;
        e.target.classList.add("special");
      }
    },
    filteredSchool(e) {
      let vm = this;
      // console.log(vm.searchTerm)
      return vm.SchoolList.filter((el) =>
        el.educatorName.toLowerCase().includes(vm.searchTerm.toLowerCase())
      );
    },
    selectSchl(schl, e) {
      let vm = this;
      vm.SchoolList.splice(vm.SchoolList.indexOf(schl), 1);
      vm.newSchoolList.unshift(schl);
    },
    unselectSchl(schl, e) {
      let vm = this;
      vm.newSchoolList.splice(vm.newSchoolList.indexOf(schl), 1);
      vm.SchoolList.unshift(schl);
    },
    SendEditInfo() {
      let vm = this;
      vm.isRequesting = true;
      let schoolIds = [];
      vm.newSchoolList.forEach((el) => {
        schoolIds.push(el.educatorId);
      });
      vm.editForm.schools = schoolIds;
      vm.editForm.status = vm.chActive;
      vm.$store
        .dispatch("POST_CHANNEL_UPDATE", vm.editForm)
        .then((res) => {
          vm.$toast.success("Channel updated successful");
          vm.isRequesting = false;
          vm.$emit("triggered");
          vm.backToChannelListtab();
        })
        .catch((e) => {
          vm.isRequesting = false;
          vm.$toast.error("Something went wrong");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../stylesConst/fonts.scss";

.umgt-edit-channel {
  padding-left: 30px;
  padding-right: 30px;

  h1 {
    color: #000;
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
    margin-top: 40px;
    text-transform: capitalize;
    font-weight: 600;
    display: flex;
    justify-content: space-between;

    i {
      cursor: pointer;
    }
  }

  .form {
    .single {
      position: relative;

      .channelReport {
        margin-top: -30px;
        font-size: 15px;
        margin-bottom: 32px;

        i {
          margin-right: 8px;
        }

        span {
          text-decoration: underline;
        }

        cursor: pointer;
      }

      label {
        display: block;
        font-size: 18px;
        color: $primary_font_color;
        font-weight: bold;
        margin-bottom: 15px;
      }

      button {
        margin-bottom: 50px;
        padding: 10px;
        width: 200px;
        text-align: start;
        cursor: pointer;
      }

      table {
        width: 300px;
        max-height: 200px !important;
        overflow-y: auto;
        font-size: 14px;

        tr:nth-child(odd) {
          background: rgb(232, 232, 232);
        }

        tr {
          td {
            padding: 8px 4px;
          }
        }

        .delete_selected_list {
          padding: 0 6px;

          td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            i {
              color: red;
            }
          }
        }
      }

      .schoolAdd {
        height: 200px;
        width: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 4px;
        position: absolute;
        top: 40px;
        left: 200px;
        z-index: 100;
        background: white;
        box-shadow: 2px 3px 12px rgb(216, 216, 216),
          -2px -3px 12px rgb(216, 216, 216);

        .search {
          width: 100%;
          height: 32px;
          display: flex;
          padding: 4px;
          border-bottom: 1px solid rgb(232, 232, 232);
          position: sticky;
          top: 0;
          background: white;

          i {
            padding-left: 4px;
          }

          input {
            width: 100%;
            padding-left: 2px;
            height: 100%;
            border: none;
            font-size: 16px;
          }
        }

        ul {
          list-style: none;
          padding: 4px;

          li {
            padding: 4px 6px;
            font-size: 16px;

            &.selected {
              background: $secondary_hover_color;
            }
          }
        }
      }
    }

    .action {
      display: flex;
      column-gap: 10px;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;

      button {
        padding: 8px;
        width: 120px;
        border-radius: 4px;
        background: $primary_bg_color;
        font-weight: bold;
        color: $secondary_font_color;
        cursor: pointer;

        &:last-of-type {
          background: rgb(232, 232, 232);
        }

        border: none;
      }
    }

    .group {
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
    }

    input {
      padding: 10px;
      border: none;
      outline: none;
      margin-bottom: 50px;
      border: 1px solid rgb(205, 205, 205);
      cursor: pointer;

      &:focus {
        border: 1px solid $primary_bg_color;
      }
    }

    select {
      padding: 10px;
      border: none;
      outline: none;
      width: 200px;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid rgb(205, 205, 205);

      &:focus {
        border: 1px solid $primary_bg_color;
      }

      option {
        padding: 10px 2px;
      }
    }
  }
}

.statusCheck {
  display: flex;
  justify-content: center;
  cursor: pointer;

  label {
    height: 20px;
    width: 40px;
    background: rgb(194, 194, 194);
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;

    div {
      height: 20px;
      width: 20px;
      background: rgb(235, 233, 233);
      border-radius: 50%;
      position: relative;
      pointer-events: none;
      right: 0;
      transition: all 0.2s ease;
    }
  }

  input {
    visibility: hidden;
  }
}

.special {
  background: $primary_bg_color !important;

  div {
    right: -20px !important;
  }
}
</style>