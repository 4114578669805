<template>
  <div class="umgt-topNav">
    <router-link
      :to="{ name: 'user-management' }"
      class="umgt-back"
      @click="goBack"
    >
      <i class="fa fa-angle-left"></i>
      <h4>Manage subscriptions</h4>
    </router-link>
    <div class="umgt-profile">
      <div class="umgt-icon-container" @click="showSettings = !showSettings">
        <i class="fa fa-user"></i>
      </div>
      <div class="account" v-if="showSettings">
        <ul>
          <li>
            <router-link :to="{ name: 'AdminProfile' }"
              ><i class="fa fa-user"></i>Profile
            </router-link>
          </li>
          <li @click="logout">
            <i class="fa-solid fa-right-from-bracket"></i>Logout
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSettings: false,
    };
  },
  methods: {
    goBack() {
      let vm = this;
      vm.$router.push("/dashboard");
    },
    logout() {
      let vm = this;
      vm.$adminlogout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./stylesConst/fonts.scss";

.umgt-topNav {
  height: 80px;
  font-weight: bold;
  color: $primary_font_color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;

  .umgt-back {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $primary_font_color;
    i {
      margin-right: 10px;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .umgt-profile {
    position: relative;

    .umgt-icon-container {
      position: relative;
      height: 50px;
      width: 50px;
      display: grid;
      place-content: center;
      font-size: 16px;
      background: $primary_bg_color;
      color: white;
      border-radius: 50%;
      right: 20px;
      cursor: pointer;
    }

    .account {
      width: 185px;
      background: white;
      min-height: 62px;
      position: absolute;
      right: 8px;
      margin-top: 6px;
      box-shadow: 2px 3px 12px rgb(216, 216, 216),
        -2px -3px 12px rgb(216, 216, 216);
      background-color: white;
      z-index: 1;
      border-radius: 15px;

      ul {
        li {
          color: #666666a6;
          padding: 7px;
          border-bottom: 1px solid #7d7d7d21;
          padding-left: 15px;
          cursor: pointer;
          font-size: 13px;

          i {
            margin-right: 6px;
          }

          a {
            color: #666666a6;
          }
        }
      }
    }
  }
}
</style>