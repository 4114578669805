import {
  AXIOS_POST,
  AXIOS_GET,
  AXIOS_PUT,
  AXIOS_DELETE,
  AXIOS_POST_FILE,
} from "../../Config/Axios_EELibrary/axios.js";
import {
  ADD_SCORE,
  GET_SCORES,
  UPDATE_SCORE,
  DELETE_SCORE,
  DELETE_SELECTED_SCORES,
  EXPORT_SCORES,
  TOGGLE_SCORE,
  UPLOAD_SCORE_FILE,
} from "./../actions/scores.js";
import {
  ADD_SCORE_URL,
  GET_SCORES_URL,
  UPDATE_SCORE_URL,
  DELETE_SCORE_URL,
  DELETE_SELECTED_SCORES_URL,
  EXPORT_SCORES_URL,
  TOGGLE_SCORE_URL,
  UPLOAD_SCORE_FILE_URL,
} from "./../Variables.js";

let token = localStorage.getItem("EEadmin_token");

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [ADD_SCORE]: ({state, rootState}, payload) => {
    return AXIOS_POST(ADD_SCORE_URL, payload, rootState.token);
  },
  [GET_SCORES]: ({state, rootState}, payload) => {
    return AXIOS_POST(GET_SCORES_URL, payload, rootState.token);
  },
  [UPDATE_SCORE]: ({state, rootState}, payload) => {
    return AXIOS_PUT(UPDATE_SCORE_URL + payload.id, payload, rootState.token);
  },
  [DELETE_SCORE]: ({state, rootState}, payload) => {
    return AXIOS_DELETE(DELETE_SCORE_URL + payload, payload, rootState.token);
  },
  [DELETE_SELECTED_SCORES]: ({state, rootState}, payload) => {
    return AXIOS_DELETE(DELETE_SELECTED_SCORES_URL, payload, rootState.token);
  },
  [EXPORT_SCORES]: ({state, rootState}, payload) => {
    return AXIOS_POST(EXPORT_SCORES_URL, payload, rootState.token);
  },
  [TOGGLE_SCORE]: ({state, rootState}, payload) => {
    return AXIOS_PUT(TOGGLE_SCORE_URL + payload.id, payload, rootState.token);
  },
  [UPLOAD_SCORE_FILE]: ({state, rootState}, payload) => {
    return AXIOS_POST_FILE(UPLOAD_SCORE_FILE_URL, payload, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
