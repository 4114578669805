<template>
  <div class="AdminRegFooter">
    <div class="footer-section">
      <hr />
      <div class="footer-body">
        <a href="https://beian.miit.gov.cn/ " target="_blank"
          >粤ICP备2023010226号</a
        >
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.AdminRegFooter {
  .footer-section {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    column-gap: 2rem;
    hr {
      border-top: 1px #c6c6c6 solid;
      width: 100px;

      @media screen and (max-width: 800px) {
        width: 50px;
      }
    }
    .footer-body {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        color: #000;
        font-size: 12px;
      }
    }
  }
}
</style>