// Authentication

// Auth
export const ADMIN_LOGIN_URL = "/admin/auth/login";
export const SEND_PASSWORD_EMAIL_URL = "/admin/auth/verify/account";


// profile
export const GET_PROFILE_URL = "/admin/profile/fetch";
export const UPDATE_PROFILE_URL = "/admin/profile/update";
export const UPDATE_PASSWORD_URL = "/admin/profile/change/password";