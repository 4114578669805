import {
  AXIOS_POST,
  AXIOS_GET,
  AXIOS_PUT,
  AXIOS_DELETE,
} from "../../Config/Axios_Auth/axios.js";
import { GET_PROFILE, UPDATE_PROFILE, UPDATE_PASSWORD } from "../actions/profile";
import { GET_PROFILE_URL, UPDATE_PROFILE_URL, UPDATE_PASSWORD_URL } from "../Variables.js";

let token = localStorage.getItem("EEadmin_token");

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [GET_PROFILE]: ({state, rootState}, payload) => {
    return AXIOS_POST(GET_PROFILE_URL, payload, rootState.token);
  },
  [UPDATE_PROFILE]: ({state, rootState}, payload) => {
    return AXIOS_POST(UPDATE_PROFILE_URL, payload, rootState.token);
  },
  [UPDATE_PASSWORD]: ({state, rootState}, payload) => {
    return AXIOS_POST(UPDATE_PASSWORD_URL, payload, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
