<template>
  <div class="NotificationPop">
    <div class="NotificationPop__content">
      <label for>{{$store.state.notificationText}}</label>
      <div class="NotificationPop__content__buttons">

        <button @click="confirmCancel()" class="cancel-btn" v-if="confirmable">Ok</button>
        <button @click="cancel" class="cancel-btn">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationPop",
  props: {
    confirmable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$store.state.showNotification = false;
    },
    confirmCancel() {
      this.$emit("confirmCancel");
    }
  },
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.NotificationPop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .NotificationPop__content {
    // gradient background
    background: linear-gradient(180deg, white, #c8c8c8);
    padding: 20px;
    border-radius: 5px;
    width: 390px;
    text-align: center;
    @media (max-width: 500px) {
      width: 80%;
    }
    label {
      display: block;
      margin-bottom: 20px;
      font-size: 1.2rem;
      line-height: 1.2;
      font-weight: 700;
      color: #666;
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
    .NotificationPop__content__buttons {
      display: flex;
      justify-content: center;
      @media (max-width: 500px) {
        flex-direction: column;
        button {
          margin-bottom: 10px;
        }
      }
      button {
        padding: 8px 20px;
        border: none;
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 700;
        min-width: 160px;
        box-shadow: 2px 3px 2px 1px #afaeae;
        @media (max-width: 700px) {
          font-size: 1rem;
        }
        &.cancel-btn {
          background: $EEskyblue;
          color: #fff;
        }
        cursor: pointer;
        &:hover {
          background-color: #28b0b0;
        }
      }
    }
  }
}
</style>