import {
  AXIOS_POST,
  AXIOS_POST_DOWNLOAD,
  AXIOS_GET,
} from "../../Config/Axios_UserMagment/axios.js";
import {
  POST_GET_ADMIN_URL,
  POST_ADD_ADMIN_URL,
  POST_EDIT_ADMIN_URL,
  POST_UPDATE_ADMIN_URL,
  POST_STATUS_ADMIN_URL,
  POST_EXORT_ADMIN_URL,
  POST_DELETE_ADMIN_URL,
  GET_PERMISSION_URL,
} from "../Variables";
import {
  POST_GET_ADMIN,
  POST_ADD_ADMIN,
  POST_EDIT_ADMIN,
  POST_UPDATE_ADMIN,
  POST_STATUS_ADMIN,
  POST_EXORT_ADMIN,
  POST_DELETE_ADMIN,
  GET_PERMISSION,
} from "../actions/Adminstrator.js";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [POST_GET_ADMIN]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_GET_ADMIN_URL, payload, rootState.token);
  },
  [POST_ADD_ADMIN]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_ADD_ADMIN_URL, payload, rootState.token);
  },
  [POST_EDIT_ADMIN]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_EDIT_ADMIN_URL, payload, rootState.token);
  },
  [POST_UPDATE_ADMIN]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_UPDATE_ADMIN_URL, payload, rootState.token);
  },
  [POST_STATUS_ADMIN]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_STATUS_ADMIN_URL, payload, rootState.token);
  },
  [POST_DELETE_ADMIN]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_DELETE_ADMIN_URL, payload, rootState.token);
  },
  [POST_EXORT_ADMIN]: ({ rootState }, payload) => {
    return AXIOS_POST_DOWNLOAD(POST_EXORT_ADMIN_URL, payload, rootState.token);
  },
  [GET_PERMISSION]: ({ rootState }, payload) => {
    return AXIOS_GET(GET_PERMISSION_URL, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
