<template>
  <div class="ScoreLibrarySidebar">
    <div class="sidebar-widget" :class="navCollapse ? 'collapsed' : ''">
      <div class="sidebar-dialog">
        <router-link :to="{ name: 'Dashboard' }" class="img-section">
          <img
            :src="$store.state.assets_base_url + user.fileName"
            v-if="user.fileName"
          />
          <img src="/img/stamp.png" alt="Avatar" v-else />
        </router-link>
        <div class="links-section">
          <ul class="links">
            <li
              :class="activeLink == 'score-library' ? 'active' : ''"
              v-if="checkPermission('SL')"
            >
              <router-link :to="{ name: 'ScoreLibraryDashboard' }">
                <img
                  :src="
                    activeLink == 'score-library'
                      ? '/img/library/White/score.png'
                      : '/img/library/Grey/score.png'
                  "
                  alt
                />
                <label>Score library</label>
              </router-link>
            </li>
            <li
              :class="activeLink == 'user-management' ? 'active' : ''"
              v-if="checkPermission('UM')"
            >
              <router-link :to="{ name: 'user-management' }">
                <img
                  :src="
                    activeLink == 'user-management'
                      ? '/img/library/White/user.png'
                      : '/img/library/Grey/user.png'
                  "
                  alt
                />
                <label>User Management</label>
              </router-link>
            </li>
            <li
              :class="activeLink == 'analytics' ? 'active' : ''"
              v-if="checkPermission('DA')"
            >
              <router-link
                :to="{
                  name: 'AnalyticsOverview',
                  params: { item: 'overview' },
                }"
              >
                <img
                  :src="
                    activeLink == 'analytics'
                      ? '/img/library/White/analytics.png'
                      : '/img/library/Grey/analytics.png'
                  "
                  alt
                />
                <label>Data Analytics</label>
              </router-link>
            </li>
            <li
              :class="activeLink == 'support' ? 'active' : ''"
              v-if="checkPermission('ES')"
            >
              <router-link to="#">
                <img
                  :src="
                    activeLink == 'support'
                      ? '/img/library/White/support.png'
                      : '/img/library/Grey/support.png'
                  "
                  alt
                />
                <label>Support</label>
              </router-link>
            </li>
            <li :class="activeLink == 'settings' ? 'active' : ''">
              <router-link :to="{ name: 'AdminProfile' }">
                <img
                  :src="
                    activeLink == 'settings'
                      ? '/img/library/White/settings.png'
                      : '/img/library/Grey/settings.png'
                  "
                  alt
                />
                <label>Set Up</label>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="collapse-button" @click="collapseNavbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreLibrarySidebar",
  data() {
    return {
      user: {},
      activeLink: null,
      navCollapse: false,
      permissions: [],
    };
  },
  methods: {
    checkUrl() {
      // if the current url contains the string 'score-library' then set the active link to 'score-library'
      if (window.location.href.includes("score-library")) {
        this.activeLink = "score-library";
      }
      // if the current url contains the string 'user-management' then set the active link to 'user-management'
      if (window.location.href.includes("user-management") || window.location.href.includes("subscription")) {
        this.activeLink = "user-management";
      }
      // if the current url contains the string 'analytics' then set the active link to 'analytics'
      if (window.location.href.includes("analytics")) {
        this.activeLink = "analytics";
      }
      // if the current url contains the string 'support' then set the active link to 'support'
      if (window.location.href.includes("support")) {
        this.activeLink = "support";
      }
      // if the current url contains the string 'settings' then set the active link to 'settings'
      if (window.location.href.includes("settings") || window.location.href.includes("administrator")) {
        this.activeLink = "settings";
      }
    },
    collapseNavbar() {
      this.$store.state.navCollapsed = !this.$store.state.navCollapsed;
      this.navCollapse = !this.navCollapse;
    },
    getProfile() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROFILE", { userId: this.userId })
        .then((res) => {
          this.$stopLoader();
          this.user = res.data.user;
          this.permissions = res.data.user.permissions;
        })
        .catch((err) => {
          console.log("f");
        });
    },
    checkPermission(permission) {
      // check if user has permission where permission.code === permission
      return this.permissions.some((p) => p.code === permission);
    },
  },
  mounted() {
    this.checkUrl();
    // check navCollapsed state
    if (this.$store.state.navCollapsed) {
      this.navCollapse = true;
    } else {
      this.navCollapse = false;
    }

    if (!this.$loggedAdmin()) {
      this.$adminlogout();
    } else {
      this.userId = this.$loggedAdmin().userId;
      this.getProfile();
    }
  },
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
$EEorange: orange;

.ScoreLibrarySidebar {
  .sidebar-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 3rem;
    position: relative;
    transition: all 0.5s ease-in-out;
    @media (max-width: 1400px) {
      margin: 0 2rem;
    }

    @media (max-width: 800px) {
      margin: 0 0rem;
      z-index: 9;
    }
    &.collapsed {
      width: 0px;
      margin: 0 2rem;
      .sidebar-dialog {
        width: 0px;
        overflow: hidden;
      }
      .collapse-button {
        left: -20px;
        right: auto;
        i {
          transform: rotate(180deg);
        }
      }
    }
    .sidebar-dialog {
      background: $EEskyblue;
      height: 90%;
      padding: 8px 0;
      border-radius: 20px;
      width: 80px;
      box-shadow: 0px 0px 50px 7px rgb(225, 225, 225);
      overflow-y: auto;
      overflow-x: hidden;
      @media (max-width: 800px) {
        width: 50px;
        height: 100%;
        border-radius: 0;
        overflow-y: auto;
      }

      .img-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        padding-bottom: 6rem;
        border-bottom: 1px solid #777;

        @media (max-width: 800px) {
          margin-top: 0;
          padding-bottom: 2rem;
          border-bottom: none;
        }
        @media (max-height: 800px) {
          margin-top: 0;
          padding-bottom: 2rem;
          border-bottom: none;
        }

        img {
          width: 60px !important;
          height: 60px !important;
          max-width: 60px !important;
          max-height: 60px !important;
          border-radius: 50%;
          filter: grayscale(100%);
          object-fit: cover;

          @media (max-width: 800px) {
            width: 40px !important;
            height: 40px !important;
            max-width: 40px !important;
            max-height: 40px !important;
          }
        }
      }

      .links-section {
        display: flex;
        justify-content: center;
        align-items: center;

        ul {
          width: 100%;
          list-style: none;

          li {
            width: 100%;
            cursor: pointer;
            a {
              color: #000;
              font-size: 20px;
              padding: 20px 0;
              display: block;
              border-bottom: 1px solid #777;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              row-gap: 5px;
              text-align: center;
              @media (max-height: 800px) {
                padding: 15px 0;
              }
              @media (max-width: 800px) {
                padding: 20px 0;
                font-size: 16px;
              }
              label {
                font-size: 11px;
                color: #000;
                line-height: 1;
                padding: 0 10px;
                cursor: pointer;
              }
              img {
                @media (max-height: 800px) {
                  height: 25px;
                }
              }
            }
            &.active {
              a {
                background: $EEorange;
                border-bottom: 1px solid $EEorange;
              }
              i {
                color: white;
              }
              label {
                color: white;
              }
            }
          }
        }
      }
    }
    .collapse-button {
      position: absolute;
      bottom: 50%;
      left: 80%;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 0 20px 20px;
      cursor: pointer;
      @media (max-width: 800px) {
        display: none;
      }
      i {
        color: #000;
        font-size: 15px;
        background: white;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0px 0px 50px 7px rgb(225, 225, 225);
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease-in-out;
        &:hover {
          background: $EEorange;
          color: #fff;
        }
      }
    }
  }
}
</style>