<template>
  <div class="Dashboard">
    <div class="scoreContent">
      <ScoreLibrarySidebar />
      <div class="score-body">
        <ScoreLibraryTopbar :pageTitle="'EE Score Library'" />
        <div class="right-widget">
          <div class="dashboard-items">
            <router-link
              :to="{ name: 'ScoreLibraryUpload', params: { id: library.id }}"
              class="dashboard-item item-1"
              v-for="library in libraries"
              :key="library.id"
            >
              <img :src="$store.state.assets_base_url+library.image" />
              <label for v-html="changeText(library.name)"></label>
            </router-link>
            <router-link :to="{name: 'ManageLibraries'}" class="dashboard-item item-8">
              <label for>
                <i class="fa fa-plus"></i>
              </label>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <AdminRegFooter />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ScoreLibraryDashboard",
  components: {},
  data() {
    return {
      libraries: []
    };
  },
  methods: {
    changeText(text) {
      // change " & " to "&nbsp;& "
      return text.replace(/ & /g, "&nbsp;& ");
    },
    getLibraries() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LIBRARIES")
        .then(res => {
          this.$stopLoader();
          this.libraries = res.data.modules;
        })
        .catch(err => {
          // if error code is 403, redirect to login
          console.log(err);
          if (err.response.status == 403) {
            this.$showNotification("Please login!!!");
            this.$adminlogout();
          } else {
            if (err.error) {
              this.$showNotification(err.error);
            } else {
              this.$showNotification("Something went wrong!!!");
            }
          }
          this.$stopLoader();
        });
    }
  },
  mounted() {
    this.getLibraries();
  }
};
</script>
<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.Dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .score-body {
    .right-widget {
      display: flex;
      height: 75vh;
      overflow-y: auto;
      // justify-content: center;
      .dashboard-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        margin-top: 2rem;
        width: 90%;
        @media (max-width: 1400px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 800px) {
          grid-template-columns: repeat(1, 1fr);
          padding-bottom: 4rem;
        }
        @media (max-height: 800px) {
          padding-bottom: 4rem;
          flex-wrap: wrap;
        }
        .dashboard-item {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          text-decoration: none;
          box-shadow: 0px 0px 15px 4px #f2f2f2;
          @media (max-width: 800px) {
            height: 140px;
          }
          @media (max-height: 800px) {
            height: 180px;
          }
          &.fit-2-rows {
            grid-row: span 2;
          }
          &.item-8 {
            background: white;
            width: 100%;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media (max-width: 800px) {
              height: 70px;
            }
            label {
              font-size: 3rem;
              color: $EEskyblue;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              position: relative;
              top: auto;
              left: auto;
              cursor: pointer;
              @media (max-width: 800px) {
                font-size: 2rem;
              }
            }
            &:hover {
              background: #f3ffff;
              transition: all 0.6s ease-in-out;
              box-shadow: 0px 0px 7px 5px #0f8a8a0e;
              cursor: pointer;
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // make image darker
            filter: brightness(0.75);
          }
          label {
            font-size: 1.4rem;
            font-weight: 600;
            position: absolute;
            left: 25px;
            top: 25px;
            color: #fff;
            width: 70px;
            text-align: left;
            &.textColored {
              color: #a55eea;
            }
            @media (max-width: 768px) {
              font-size: 1.2rem;
              width: 50px;
            }
            @media (max-width: 425px) {
              font-size: 1rem;
              width: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
