// USER MANAGEMENT

// REGIONS
export const GET_REGIONS_URL = "/admin/regions";
export const ADD_REGION_URL = "/admin/region/create";
export const EDIT_REGION_URL = "/admin/region/edit";
export const UPDTE_REGION_URL = "/admin/region/update";
export const DELETE_REGIONS_URL = "/admin/regions/delete";
export const UPDATE_STATUS_REGION_URL = "/admin/region/update/status";

// Users
export const POST_USERS_GET_URL = "/admin/users";
export const POST_USER_UPDATE_URL = "/admin/user/update";
export const POST_USER_EDIT_URL = "/admin/user/edit";
export const POST_USER_EXPORT_URL = "/admin/users/export";
export const POST_USER_UPDATE_STATUS_URL = "/admin/user/update/status";
export const POST_USERS_DELETE_URL = "/admin/users/delete";

// channels
export const POST_CHANNEL_CREATE_URL = "/admin/channel/create";
export const POST_CHANNEL_UPDATE_URL = "/admin/channel/update";
export const POST_CHANNEL_EDIT_URL = "/admin/channel/edit";
export const POST_GET_CHANNELS_URL = "/admin/channels";
export const POST_CHANNELS_EXPORT_URL = "/admin/channel/export";
export const POST_CHANNELS_DELETE_URL = "/admin/channels/delete";
export const POST_CHANNEL_STATUS_UPDATE_URL = "/admin/channel/update/status";
export const POST_CHANNEL_REPORT_URL = "/admin/channel/report";
export const GET_CHANNELS_UNASSIGNED_EDUCATORS_URL =
  "/admin/educators/not/assigned";

// School and Teacher

export const POST_EDUCATOR_CREATE_URL = "/admin/educator/create";
export const POST_EDUCATOR_UPDATE_URL = "/admin/educator/update";
export const POST_EDUCATOR_EDIT_URL = "/admin/educator/edit";
export const POST_GET_EDUCATORS_URL = "/admin/educators";
export const POST_EDUCATORS_EXPORT_URL = "/admin/educator/export";
export const POST_EDUCATORS_DELETE_URL = "/admin/educators/delete";
export const POST_EDUCATOR_STATUS_UPDATE_URL = "/admin/educator/update/status";
export const POST_EDUCATOR_REPORT_URL = "/admin/educator/report";
export const GET_EDUCATORS_UNASSIGNED_USERS_URL = "/admin/users/not/assigned";

// subscription keys

export const POST_KEYS_GENERATE_URL = "/admin/subscriptions/genelete";
export const POST_GET_KEYS_URL = "/admin/subscriptions";
export const POST_KEYS_STATUS_URL = "/admin/subscriptions/key/toggle";
export const POST_KEYS_EDUCATOR_URL = "/admin/subscriptions/educators";
export const POST_KEYS_DELETE_URL = "/admin/subscriptions/selected/delete";
export const POST_KEYS_EXPORT1_URL = "/admin/subscriptions/export/excel";
export const GET_KEY_URL = "/admin/subscriptions/key/";
export const PUT_KEY_URL = "/admin/subscriptions/key/update/";
