import {
  AXIOS_POST,
  AXIOS_POST_DOWNLOAD,
  AXIOS_GET,
  AXIOS_PUT_KEYS,
} from "./../../Config/Axios_UserMagment/axios.js";
import {
  GET_KEY,
  PUT_KEY,
  POST_KEYS_GENERATE,
  POST_GET_KEYS,
  POST_KEYS_STATUS,
  POST_KEYS_EDUCATOR,
  POST_KEYS_DELETE,
  POST_KEYS_EXPORT1,
} from "./../actions/Keys.js";
import {
  GET_KEY_URL,
  PUT_KEY_URL,
  POST_KEYS_GENERATE_URL,
  POST_GET_KEYS_URL,
  POST_KEYS_STATUS_URL,
  POST_KEYS_EDUCATOR_URL,
  POST_KEYS_DELETE_URL,
  POST_KEYS_EXPORT1_URL,
} from "./../Variables.js";

const state = {
  searchTerm: null,
};
const mutations = {};
const getters = {};
const actions = {
  [GET_KEY]: ({ rootState }, payload) => {
    return AXIOS_GET(GET_KEY_URL + payload, rootState.token);
  },
  [PUT_KEY]: ({ rootState }, payload) => {
    return AXIOS_PUT_KEYS(
      PUT_KEY_URL + payload.id,
      payload.data,
      rootState.token
    );
  },
  [POST_KEYS_GENERATE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_KEYS_GENERATE_URL, payload, rootState.token);
  },
  [POST_GET_KEYS]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_GET_KEYS_URL, payload, rootState.token);
  },
  [POST_KEYS_STATUS]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_KEYS_STATUS_URL, payload, rootState.token);
  },
  [POST_KEYS_EDUCATOR]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_KEYS_EDUCATOR_URL, payload, rootState.token);
  },
  [POST_KEYS_EXPORT1]: ({ rootState }, payload) => {
    return AXIOS_POST_DOWNLOAD(POST_KEYS_EXPORT1_URL, payload, rootState.token);
  },
  [POST_KEYS_DELETE]: ({ rootState }, payload) => {
    return AXIOS_POST(POST_KEYS_DELETE_URL, payload, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
