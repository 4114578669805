import Vue from "vue";
import Vuex from "vuex";
import ControlTab from "./UserManagment/modules/ControlTab";
import UserFromUserMgt from "./UserManagment/modules/User";
import AdminstratorTab from "./Adminstrator/modules/AdminstratorTab";
import Channel from "./UserManagment/modules/Channel";
import Educators from "./UserManagment/modules/Educators";
import CommonFromUserMgt from "./UserManagment/modules/Common";
import login from "./Auth/modules/login";
import profile from "./Auth/modules/profile";
import upload from "./EElibrary/modules/upload";
import libraries from "./EElibrary/modules/libraries";
import common from "./EElibrary/modules/common";
import scores from "./EElibrary/modules/scores";
import Keys from "./UserManagment/modules/Keys";
import Adminstrator from "./Adminstrator/modules/Adminstrator";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    showNotification: false,
    token: null,
    searchTerm: "",
    searchadminTerm: "",
    notificationText: "",
    assets_base_url: "https://eemusic.s3.ap-southeast-1.amazonaws.com/",
    scores_base_url: "https://eemusic-scores.s3.ap-southeast-1.amazonaws.com/",
    navCollapsed: false,
  },
  mutations: {},
  actions: {},
  modules: {
    ControlTab,
    UserFromUserMgt,
    AdminstratorTab,
    Channel,
    Educators,
    CommonFromUserMgt,
    login,
    profile,
    upload,
    libraries,
    common,
    scores,
    Keys,
    Adminstrator,
  },
});
