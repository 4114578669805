<template>
    <div class="umgt-edit-channel">
        <h1>
            <span @click="
                backTosubscriptionList"><i class=" fa fa-angle-left"></i></span>
            Manage subscription information
            <span></span>
        </h1>
        <div class="form">
            <Spinner v-if="isLoading" size="60"></Spinner>
            <form @submit.prevent="editKeyInfo" v-if="!isLoading">
                <div class="group">
                    <div class="single">
                        <label for="Contact Name">Account Name</label>
                        <input type="text" v-model="editForm.accountName" placeholder="Name">
                    </div>
                    <div class="single">
                        <label for="ContactNo">Account Mobile No.</label>
                        <input type="text" v-model="editForm.accountMobile" placeholder="Phone number">
                    </div>
                    <div class="single">
                        <label for="ContactEmail">Email</label>
                        <input type="email" v-model="editForm.email" placeholder="email">
                    </div>
                </div>

                <div class="single">
                    <label for="Contact Name">Subscription ID</label>
                    <input type="text" v-model="editForm.key" disabled=" true" placeholder="Key">
                </div>
                <div class="single">
                    <label for="ContactEmail">Status Date</label>
                    <p class="statusText">Active on: <input type="date" :value="editForm.redeemedOn" disabled
                            class="dateInput">
                    </p>
                    <p class="statusText" contenteditable="true">Expires on: <input type="date" v-model="editForm.expiresOn"
                            required class="dateInput">
                    </p>
                </div>

                <div class="single special_single">
                    <label for="Contact Name">Region</label>
                    <select name="" required id="" v-model="editForm.region">
                        <option value="">Select Region
                        </option>
                        <option>SD
                        </option>
                    </select>
                </div>
                <div class="single special_single">
                    <label for="">Status</label>
                    <div class="statusCheck">
                        <label for="status" @click="statusChange(editForm.isRevoked)"
                            :class="editForm.isRevoked ? 'special' : ''">
                            <div></div>
                        </label>
                        <input type="checkbox" id="status">
                    </div>
                </div>
                <div class="action">
                    <button>
                        <Spinner size="18" v-if="isRequesting"></Spinner>
                        <span v-if="!isRequesting">Save changes</span>
                    </button>
                    <button type="button" @click="
                        backTosubscriptionList">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Spinner from 'vue-simple-spinner'
export default {
    data() {
        return {
            subscriptionId: this.$store.state.ControlTab.SubscriptionsTabSwitcher.editSubscriptions.id,
            isRequesting: false,
            isLoading: true,
            editForm: {
                accountName: '',
                accountMobile: '',
                email: '',
                expiresOn: '',
                region: '',
                isRevoked: true,
                key: '',
                redeemedOn: '',
                isUserFound: ''
            }
        }
    },
    components: {
        Spinner,
    },
    mounted() {
        let vm = this;
        vm.getKeyInfo();
    },
    methods: {

        backTosubscriptionList() {
            let vm = this;
            vm.$store.commit('LIST_SUBSCRIPTIONS')
        },
        getKeyInfo() {
            let vm = this;
            vm.$store.dispatch("GET_KEY", vm.$store.state.ControlTab.SubscriptionsTabSwitcher.editSubscriptions.id.id).then(res => {
                vm.isLoading = false;
                vm.editForm.accountName = res.data.userAccount.fullName
                vm.editForm.accountMobile = res.data.userAccount.phone
                vm.editForm.region = res.data.userAccount.region
                vm.editForm.isRevoked = res.data.isRevoked
                vm.editForm.expiresOn = moment(res.data?.expireBy).utc().format('YYYY-MM-DD')
                vm.editForm.email = res.data.userAccount.email
                vm.editForm.key = res.data.key
                vm.editForm.redeemedOn = moment(res.data.key.redeemedOn).utc().format('YYYY-MM-DD')
                vm.editForm.isUserFound = res.data.key.isUserFound
            }).catch((e) => {
                vm.$toast.warning('Something went wrong!')
            })
        },
        editKeyInfo() {
            let vm = this;
            vm.isRequesting = true;
            vm.$store.dispatch("PUT_KEY", {
                id: vm.$store.state.ControlTab.SubscriptionsTabSwitcher.editSubscriptions.id.id,
                data: vm.editForm
            }).then(res => {
                vm.isRequesting = false;
                vm.$toast.success('Key information Updated!')
                vm.$emit('updateKey')
                vm.backTosubscriptionList();
            }).catch((e) => {
                vm.isRequesting = false;
                vm.$toast.warning('Something went wrong!')
            })
        },
        statusChange(status) {
            let vm = this;
            vm.editForm.isRevoked = !vm.editForm.isRevoked
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../stylesConst/fonts.scss';

.umgt-edit-channel {
    padding-left: 30px;
    padding-right: 30px;

    h1 {
        color: $secondary_font_color;
        text-align: center;
        font-size: 16px;
        margin-bottom: 40px;
        margin-top: 40px;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        justify-content: space-between;

        i {
            cursor: pointer
        }
    }

    .form {
        .single {

            position: relative;

            .channelReport {
                margin-top: -30px;
                font-size: 15px;
                margin-bottom: 32px;

                i {
                    margin-right: 8px;
                }

                span {
                    text-decoration: underline;
                }

                cursor: pointer;
            }

            label {
                display: block;
                font-size: 18px;
                color: $primary_font_color;
                font-weight: bold;
                margin-bottom: 15px;

            }

            button {
                margin-bottom: 50px;
                padding: 10px;
                width: 200px;
                text-align: start;
                cursor: pointer;

            }

            table {
                width: 300px;
                max-height: 200px !important;
                overflow-y: auto;
                font-size: 14px;

                tr:nth-child(odd) {
                    background: rgb(232, 232, 232);
                }

                tr {
                    td {
                        padding: 8px 4px;
                    }
                }
            }

            .schoolAdd {
                height: 200px;
                width: 250px;
                overflow-y: auto;
                overflow-x: hidden;
                border-radius: 4px;
                position: absolute;
                top: 40px;
                left: 200px;
                z-index: 100;
                background: white;
                box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);

                .search {
                    width: 100%;
                    height: 32px;
                    display: flex;
                    padding: 4px;
                    border-bottom: 1px solid rgb(232, 232, 232);
                    position: sticky;
                    top: 0;
                    background: white;

                    i {
                        padding-left: 4px;
                    }

                    input {
                        width: 100%;
                        padding-left: 2px;
                        height: 100%;
                        border: none;
                        font-size: 16px;

                        &.dateInput {
                            border: none !important;
                            padding: 0 !important;
                            margin: 0 !important;

                        }

                    }
                }

                ul {
                    list-style: none;
                    padding: 4px;

                    li {
                        padding: 4px 6px;
                        font-size: 16px;

                        &:hover {
                            background: $secondary_hover_color;
                        }
                    }
                }
            }
        }

        .action {
            display: flex;
            column-gap: 10px;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 40px;

            button {
                padding: 8px;
                width: 120px;
                border-radius: 4px;
                background: $primary_bg_color;
                font-weight: bold;
                color: $secondary_font_color;
                cursor: pointer;

                &:last-of-type {
                    background: rgb(232, 232, 232);
                }

                border: none;
            }
        }

        .group {
            display: flex;
            flex-wrap: wrap;
            column-gap: 30px;

            .permission {
                font-size: 14px;

                input {
                    margin-right: 7px;
                }
            }
        }

        input {
            padding: 10px;
            border: none;
            outline: none;
            margin-bottom: 50px;
            border: 1px solid rgb(205, 205, 205);
            cursor: pointer;

            &.dateInput {
                border: none !important;
                padding: 0 !important;
                margin: 0 !important;

            }

            &:focus {
                border: 1px solid $primary_bg_color;

            }


        }

        select {
            padding: 10px;
            border: none;
            outline: none;
            width: 200px;
            border-radius: 4px;
            cursor: pointer;
            border: 1px solid rgb(205, 205, 205);

            &:focus {
                border: 1px solid $primary_bg_color;

            }

            option {
                padding: 10px 2px;
            }
        }
    }

    .permissionheader {
        color: $primary_font_color;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0;
    }
}

.statusCheck {

    label {
        height: 20px;
        width: 40px;
        background: rgb(194, 194, 194);
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        div {
            height: 20px;
            width: 20px;
            background: rgb(235, 233, 233);
            border-radius: 50%;
            position: relative;
            right: 0;
            transition: all 0.2s ease;

        }

    }

    input {
        visibility: hidden;
    }
}

.statusText {
    font-size: 14px;
    color: $secondary_font_color
}

.special {
    background: $primary_bg_color !important;

    div {
        right: -20px !important;
    }
}

.special_single {
    margin-top: 14px;
}
</style>