<template>
  <div class="Login">
    <router-link to="/" class="logo-img">
      <img alt="Vue logo" :src="require('@/assets/logo/EEmusic-Logo.png')" />
    </router-link>
    <div class="login-wrapper">
      <div class="left-section">
      <img alt="Vue logo" :src="require('@/assets/eescorelogin.png')" />
      </div>
      <div class="right-section">
        <label for class="title">EE Music Library</label>
        <form action class="login">
          <!-- //input for username and password with icons in inputs -->
          <div class="input-wrapper">
            <div class="input-icon">
              <i class="fas fa-user"></i>
            </div>
            <input type="text" placeholder="Email" />
          </div>
          <div class="input-wrapper">
            <div class="input-icon">
              <i class="fas fa-lock"></i>
            </div>
            <input type="password" placeholder="Password" />
          </div>
          <button class="login-btn" @click="login()">Sign In</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Login",
  components: {},
  methods: {
    login() {
      this.$router.push({ name: "ScoreLibraryDashboard" });
    }
  }
};
</script>
<style lang="scss" scoped>
$EEskyblue : #3dd1d1;
.Login {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  .logo-img{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img{
      margin-right: 3rem;
      width: 120px;
      height: 100px;
      object-fit: contain;
    }
  }
  .login-wrapper {
    width: 100vw;
    display: grid;
    grid-template-columns: 60% 40%;
    border-radius: 10px;
    overflow: hidden;
    .left-section {
      background-image: url("/assets/eescorelogin.png");
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      img {
        width: 70%;
        height: 100%;
        object-fit: contain;
        opacity: .6;
      }
    }
    .right-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 4rem;
      margin-top: 3rem;
      .title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 3.5rem;
        margin-top: 2rem;
        color: $EEskyblue;
      }
      .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
        .input-wrapper {
          display: flex;
          align-items: center;
          width: 300px;
          height: 40px;
          border-radius: 40px;
          background-color: #fff;
          box-shadow: 0px 0px 10px 0px rgba(205, 205, 205, 0.75);
          overflow: hidden;
          .input-icon {
            width: 45px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 5px 0 0 5px;
            padding-left: 10px;
            i {
              color: rgb(170, 170, 170);
            }
          }
          input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            border-radius: 40px;
            padding-left: 10px;
            font-size: 0.9rem;
            font-weight: 200;
          }
        }
        .forgot-password {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-top: 0.8rem;
          a {
            text-decoration: none;
            color: #444;
            font-size: 0.75rem;
            font-weight: 200;
          }
        }
        .login-btn {
          width: 100%;
          padding: 10px 15px;
          border: none;
          outline: none;
          border-radius: 40px;
          background-color: $EEskyblue;
          color: #fff;
          font-size: 1rem;
          font-weight: 600;
          cursor: pointer;
        }
        .remember-me {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-start;
          input {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            border: 1px solid #000;
          }
          label {
            font-size: 0.8rem;
            font-weight: 200;
            color: #444;
          }
        }
      }
    }
  }
}
</style>
