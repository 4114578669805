<template>
  <div class="umgt-edit-channel">
    <h1>
      <span @click="backToSchlTeachertab"
        ><i class="fa fa-angle-left"></i
      ></span>
      Edit School/Teacher Information #{{ eduId }}
      <span></span>
    </h1>
    <div class="form">
      <form @submit.prevent="SendEditInfo">
        <div class="group">
          <div class="single">
            <label for="channelName">School/Teacher Name</label>
            <input
              type="text"
              required
              placeholder="Enter name"
              v-model="editForm.EducatorName"
            />
            <div class="channelReport" @click="showToSchlTeacherReport(eduId)">
              <i class="fa-solid fa-clipboard-list"></i><span>View Report</span>
            </div>
          </div>
          <div class="single">
            <label for="Contact Name">Category</label>
            <select name="" id="">
              <option value="School">School</option>
              <option value="Teacher">Teacher</option>
            </select>
          </div>
        </div>

        <div class="group">
          <div class="single">
            <label for="Contact Name">Contact Name</label>
            <input
              type="text"
              required
              placeholder="Enter contact name"
              v-model="editForm.contactName"
            />
          </div>
          <div class="single">
            <label for="ContactNo">Contact No.</label>
            <input
              type="Enter contact number"
              required
              placeholder="458655"
              v-model="editForm.contactNumber"
            />
          </div>
          <div class="single">
            <label for="ContactEmail">Contact Email</label>
            <input
              type="email"
              required
              placeholder="Enter email"
              v-model="editForm.contactEmail"
            />
          </div>
        </div>
        <div class="group">
          <div class="single">
            <label for="Contact Name">Link to channel</label>
            <select v-model="editForm.channelId">
              <option value="">None</option>
              <option
                v-for="channel in channelsList"
                :key="channel.channelId"
                :value="channel.channelId"
              >
                {{ channel.channelName }}
              </option>
            </select>
          </div>
          <div class="single">
            <label for="Contact Name">Region</label>
            <select name="" required id="" v-model="editForm.region">
              <option
                v-for="Region in RegionList"
                :key="Region.code"
                :value="Region.region"
              >
                {{ Region.region }}
              </option>
            </select>
          </div>
          <div class="single">
            <label for="">Status</label>
            <div class="statusCheck">
              <label
                for="status"
                @click="educatorStatus"
                :class="eduActive ? 'special' : ''"
              >
                <div></div>
              </label>
              <input type="checkbox" id="status" />
            </div>
          </div>
        </div>
        <div class="single">
          <label for="ContactEmail">Users</label>
          <button type="button" @click="showUserList = !showUserList">
            + Add User
          </button>
          <template v-if="UserList.length > 0">
            <div class="schoolAdd" v-if="showUserList">
              <div class="search">
                <i class="fa fa-search"></i>
                <input
                  type="text"
                  v-model="searchTerm"
                  placeholder="Search user"
                />
              </div>
              <ul>
                <template v-for="sch in filteredUser()">
                  <li
                    :key="sch.studentId"
                    v-if="sch.studentName.length > 2"
                    @click="selectUser(sch, $event)"
                  >
                    {{ sch.studentName }}
                  </li>
                </template>
              </ul>
            </div>
          </template>
          <template v-else>
            <div class="schoolAdd" v-if="showUserList">
              <ul>
                <li>
                  All user have been assigned to respective school/teacher;
                  proceed to link them later
                </li>
              </ul>
            </div>
          </template>
        </div>

        <div class="single">
          <label for="ContactEmail">Users Name</label>
          <table>
            <tr
              v-for="newSch in newUserList"
              :key="newSch.id"
              class="delete_selected_list"
            >
              <td @click="unselectUser(newSch, $event)">
                {{ newSch.studentName }} <i class="fa fa-trash"></i>
              </td>
            </tr>
          </table>
        </div>

        <div class="action">
          <button>
            <Spinner size="18" v-if="isRequesting"></Spinner>
            <span v-if="!isRequesting">Save changes</span>
          </button>
          <button type="button" @click="backToSchlTeachertab">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Spinner from "vue-simple-spinner";
export default {
  name: "Edit Teacher/School",
  components: {
    Spinner,
  },
  data() {
    return {
      eduId:
        this.$store.state.ControlTab.SchlTeacherTabSwitcher.editSchlTeacher.id
          .id,
      showUserList: false,
      eduActive:
        this.$store.state.ControlTab.SchlTeacherTabSwitcher.editSchlTeacher.id
          .educatorData.status,
      RegionList: [],
      channelsList: [],
      isRequesting: false,
      searchTerm: "",
      UserList: [],
      newUserList: [],
      editForm: {
        educatorId:
          this.$store.state.ControlTab.SchlTeacherTabSwitcher.editSchlTeacher.id
            .educatorData.educatorId,
        EducatorName:
          this.$store.state.ControlTab.SchlTeacherTabSwitcher.editSchlTeacher.id
            .educatorData.educatorName,
        contactName:
          this.$store.state.ControlTab.SchlTeacherTabSwitcher.editSchlTeacher.id
            .educatorData.contactName,
        contactNumber:
          this.$store.state.ControlTab.SchlTeacherTabSwitcher.editSchlTeacher.id
            .educatorData.contactNumber,
        contactEmail:
          this.$store.state.ControlTab.SchlTeacherTabSwitcher.editSchlTeacher.id
            .educatorData.contactEmail,
        channelId: "",
        region:
          this.$store.state.ControlTab.SchlTeacherTabSwitcher.editSchlTeacher.id
            .educatorData.region,
      },
    };
  },
  created() {
    let vm = this;
    vm.getRegions();
    vm.getEditInfo();
    vm.getUnsignnedUser();
    vm.getAllChannels();
  },

  methods: {
    backToSchlTeachertab() {
      let vm = this;
      vm.$store.commit("VIEW_SCHLTEACHER_LIST");
    },
    showToSchlTeacherReport(id) {
      let vm = this;
      vm.$store.commit("VIEW_SCHLTEACHER_REPORT", { id });
    },
    selectUser(schl, e) {
      let vm = this;
      vm.UserList.splice(vm.UserList.indexOf(schl), 1);
      vm.newUserList.unshift(schl);
    },
    unselectUser(schl, e) {
      let vm = this;
      vm.newUserList.splice(vm.newUserList.indexOf(schl), 1);
      vm.UserList.unshift(schl);
    },
    getRegions() {
      let vm = this;
      vm.$store
        .dispatch("GET_REGIONS")
        .then((res) => {
          vm.RegionList = res.data.regions;
        })
        .catch((e) => {
          // console.log(e)
        });
    },
    getAllChannels() {
      let vm = this;
      vm.$store
        .dispatch("POST_GET_CHANNELS", {
          Page: 1,
          Size: 100,
          OrganizationId: 2,
          searchQuery: "",
        })
        .then((res) => {
          vm.channelsList = res.data?.channels;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    educatorStatus(e) {
      let vm = this;
      if (vm.eduActive) {
        vm.eduActive = 0;
        e.target.classList.remove("special");
      } else {
        vm.eduActive = 1;
        e.target.classList.add("special");
      }
    },
    getUnsignnedUser() {
      let vm = this;
      vm.$store
        .dispatch("GET_EDUCATORS_UNASSIGNED_USERS")
        .then((res) => {
          vm.UserList = res.data.students;
        })
        .catch((e) => {
          // console.log(e)
        });
    },
    filteredUser(e) {
      let vm = this;
      let listToreturn = vm.UserList.filter(function (el) {
        if (el.studentName) {
          return el.studentName.toLowerCase().includes(vm.searchTerm.toLowerCase());
        }
      });
      return listToreturn;
    },
    getEditInfo() {
      let vm = this;
      vm.$store
        .dispatch("POST_EDUCATOR_EDIT", { educatorId: vm.eduId })
        .then((res) => {
          // console.log('edit : ', res.data)
          // vm.newUserList = res.data.educator.students;
          vm.newUserList = res.data.educator.users;
          vm.editForm = {
            educatorId: res.data.educator.id,
            EducatorName: res.data.educator.name,
            contactName: res.data.educator.contactName,
            contactNumber: res.data.educator.contactNumber,
            contactEmail: res.data.educator.contactEmail,
            channelId: res.data.educator.channelId,
            region: res.data.educator.region,
          };
        })
        .catch((e) => {
          this.$toast.error("Something went wrong");
        });
    },
    SendEditInfo() {
      let vm = this;
      vm.isRequesting = true;
      let userIds = [];
      vm.newUserList.forEach((el) => {
        userIds.push(el.studentId);
      });
      vm.editForm.students = [];
      vm.editForm.users = userIds;
      vm.editForm.status = vm.eduActive;
      // console.log(vm.editForm)
      vm.$store
        .dispatch("POST_EDUCATOR_UPDATE", vm.editForm)
        .then((res) => {
          vm.$toast.success("Educator updated successful");
          vm.isRequesting = false;
          vm.$emit("triggered");
          vm.backToSchlTeachertab();
        })
        .catch((e) => {
          vm.isRequesting = false;
          vm.$toast.error("Something went wrong");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../stylesConst/fonts.scss";

.umgt-edit-channel {
  padding-left: 30px;
  padding-right: 30px;

  h1 {
    color: $secondary_font_color;
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
    margin-top: 40px;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    justify-content: space-between;

    i {
      cursor: pointer;
    }
  }

  .form {
    .single {
      position: relative;

      .channelReport {
        margin-top: -30px;
        font-size: 15px;
        margin-bottom: 32px;

        i {
          margin-right: 8px;
        }

        span {
          text-decoration: underline;
        }

        cursor: pointer;
      }

      label {
        display: block;
        font-size: 18px;
        color: $primary_font_color;
        font-weight: bold;
        margin-bottom: 15px;
      }

      button {
        margin-bottom: 50px;
        padding: 10px;
        width: 200px;
        text-align: start;
        cursor: pointer;
      }

      table {
        width: 300px;
        max-height: 200px !important;
        overflow-y: auto;
        font-size: 14px;

        tr:nth-child(odd) {
          background: rgb(232, 232, 232);
        }

        tr {
          td {
            padding: 8px 4px;
          }
        }

        .delete_selected_list {
          padding: 0 6px;

          td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            i {
              color: red;
            }
          }
        }
      }

      .schoolAdd {
        height: 200px;
        width: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 4px;
        position: absolute;
        top: 40px;
        left: 200px;
        z-index: 100;
        background: white;
        box-shadow: 2px 3px 12px rgb(216, 216, 216),
          -2px -3px 12px rgb(216, 216, 216);

        .search {
          width: 100%;
          height: 32px;
          display: flex;
          padding: 4px;
          border-bottom: 1px solid rgb(232, 232, 232);
          position: sticky;
          top: 0;
          background: white;

          i {
            padding-left: 4px;
          }

          input {
            width: 100%;
            padding-left: 2px;
            height: 100%;
            border: none;
            font-size: 16px;
          }
        }

        ul {
          list-style: none;
          padding: 4px;

          li {
            padding: 4px 6px;
            font-size: 16px;

            &.selected {
              background: $secondary_hover_color;
            }
          }
        }
      }
    }

    .action {
      display: flex;
      column-gap: 10px;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;

      button {
        padding: 8px;
        width: 120px;
        border-radius: 4px;
        background: $primary_bg_color;
        font-weight: bold;
        color: $secondary_font_color;
        cursor: pointer;

        &:last-of-type {
          background: rgb(232, 232, 232);
        }

        border: none;
      }
    }

    .group {
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
    }

    input {
      padding: 10px;
      border: none;
      outline: none;
      margin-bottom: 50px;
      border: 1px solid rgb(205, 205, 205);
      cursor: pointer;

      &:focus {
        border: 1px solid $primary_bg_color;
      }
    }

    select {
      padding: 10px;
      border: none;
      outline: none;
      width: 200px;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid rgb(205, 205, 205);

      &:focus {
        border: 1px solid $primary_bg_color;
      }

      option {
        padding: 10px 2px;
      }
    }
  }
}

.statusCheck {
  display: flex;
  justify-content: center;

  label {
    height: 20px;
    width: 40px;
    background: rgb(194, 194, 194);
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;

    div {
      height: 20px;
      width: 20px;
      background: rgb(235, 233, 233);
      border-radius: 50%;
      position: relative;
      right: 0;
      transition: all 0.2s ease;
    }
  }

  input {
    visibility: hidden;
  }
}

.special {
  background: $primary_bg_color !important;

  div {
    right: -20px !important;
  }
}
</style>