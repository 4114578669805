import {
  AXIOS_POST,
  AXIOS_GET,
  AXIOS_PUT,
  AXIOS_DELETE,
} from "./../../Config/Axios_Auth/axios.js";
import { ADMIN_LOGIN, SEND_PASSWORD_EMAIL } from "./../actions/login.js";
import { ADMIN_LOGIN_URL, SEND_PASSWORD_EMAIL_URL } from "./../Variables.js";

let token = localStorage.getItem("EEadmin_token");

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [ADMIN_LOGIN]: (state, payload) => {
    return AXIOS_POST(ADMIN_LOGIN_URL, payload, (token = null));
  },
  [SEND_PASSWORD_EMAIL]: (state, payload) => {
    return AXIOS_POST(SEND_PASSWORD_EMAIL_URL, payload, (token = null));
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
