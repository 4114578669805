<template>
    <div class="content_wrapper">
        <!-- <TopNav /> -->
        <TopNav2 />
        <div class="board_container">
            <Inbox></Inbox>
            <Bugs></Bugs>
            <ActiveContact></ActiveContact>
            <ExternalRequests></ExternalRequests>
            <Suggestions></Suggestions>
            <FeedBack></FeedBack>
        </div>
    </div>
</template>

<script>
import ActiveContact from './components/ActivaContact/ActiveContact.vue'
import Bugs from './components/Bugs/Bugs.vue'
import ExternalRequests from './components/ExternalRequests/ExternalRequests.vue'
import FeedBack from './components/Feedback/FeedBack.vue'
import Inbox from './components/Inbox/Inbox.vue'
import Suggestions from './components/Suggestions/Suggestions.vue'
import TopNav from './components/TopNav.vue'
import TopNav2 from './components/TopNav2.vue'
export default {
    name: 'Kanbanboard',
    components: { TopNav, Inbox, ActiveContact, ExternalRequests, Suggestions, FeedBack, Bugs, TopNav2 },
}
</script>

<style lang="scss" scoped>
@import "./components/StylesConst/fonts.scss";

.content_wrapper {
    text-align: left;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: relative;
    background: #f7f7f9;

    .board_container {
        height: 88%;
        margin-bottom: 10px;
        display: flex;
        width: 97%;
        overflow: auto;
        border-radius: 8px;
        border: 2px solid secondary_font_color;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 10px;
        background: white;
        box-shadow: $primary_box_shadow;
        ;
        transform: scale(0.9);

    }
}
</style>