<template>
  <div class="PreviewScore">
    <div ref="sheetMusicContainer" id="sheetMusicContainer"></div>
  </div>
</template>

<script>
import { OpenSheetMusicDisplay } from "opensheetmusicdisplay";
export default {
  name: "PreviewScore",
  props: {
    filename: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      myFilename: "",
    };
  },
  methods: {
    loadPreview() {
      let div = document.getElementsByClassName("PreviewScore");
      console.log(div);
      this.$refs["sheetMusicContainer"].innerHTML = "";
      const osmd = new OpenSheetMusicDisplay(this.$refs["sheetMusicContainer"]);
      osmd.load(this.myFilename).then(() => {
        osmd.render();
      });
    },
  },
  created() {},
  mounted() {
    if (
      this.filename.split(".").pop() == "xml" ||
      this.filename.split(".").pop() == "musicxml"
    ) {
      this.myFilename = this.filename;
      this.loadPreview();
    } else {
      // add the extension to the filename
      this.myFilename = this.filename + ".musicxml";
      this.loadPreview();
    }
  },
  watch: {
    // filename: function () {
    //   if (
    //     this.filename.split(".").pop() == "xml" ||
    //     this.filename.split(".").pop() == "musicxml"
    //   ) {
    //     this.loadPreview();
    //   } else {
    //     // add the extension to the filename
    //     this.filename = this.filename + ".musicxml";
    //     this.loadPreview();
    //   }
    // },
    // filename with old and new value
    filename: function (oldValue, newValue) {
      if (
        this.myFilename == newValue ||
        this.myFilename == newValue + ".musicxml"
      ) {
        return;
      } else {
        if (
          this.filename.split(".").pop() == "xml" ||
          this.filename.split(".").pop() == "musicxml"
        ) {
          this.myFilename = this.filename;
          this.loadPreview();
        } else {
          // add the extension to the filename
          this.myFilename = this.filename + ".musicxml";
          this.loadPreview();
        }
      }
      // if (oldValue == "") {
      //   if (
      //     this.filename.split(".").pop() == "xml" ||
      //     this.filename.split(".").pop() == "musicxml"
      //   ) {
      //     this.loadPreview();
      //   } else {
      //     // add the extension to the filename
      //     this.filename = this.filename + ".musicxml";
      //     this.loadPreview();
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.PreviewScore {
  width: 100%;
  height: 100%;
  .sheetMusicContainer {
    width: 100%;
    height: 100%;
    .title {
      display: none;
    }
  }
}
</style>