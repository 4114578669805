<template>
    <div class="umgt-topNav">
        <div class="umgt-back" @click="goBack">
            <i class="fa fa-angle-left"></i>
            <h4>Email Support</h4>
        </div>
        <div class="umgt-profile">
            <div class="umgt-icon-container">
                <i class="fa fa-user"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            let vm = this;
            vm.$router.push('/dashboard')
        }
    },

}
</script>

<style lang="scss" scoped>
@import './StylesConst/fonts.scss';

.umgt-topNav {
    height: 80px;
    font-weight: bold;
    color: $primary_font_color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;

    .umgt-back {
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
            margin-right: 10px;
        }

        h3 {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .umgt-profile {
        .umgt-icon-container {
            position: relative;
            height: 50px;
            width: 50px;
            display: grid;
            place-content: center;
            font-size: 16px;
            background: $primary_bg_color;
            color: white;
            border-radius: 50%;
            right: 20px;
            cursor: pointer;
        }
    }

}
</style>