<template>
    <div class="umgt-add-channel">
        <h1><span @click="backToadminList"><i class=" fa fa-angle-left"></i></span>
            Add New Admin
            <span></span>
        </h1>
        <div class="form">
            <form @submit.prevent="handleSubmit">
                <div class="group">
                    <div class="single">
                        <label for="profileImage" id="uploadimage"> + Upload Image
                        </label>
                        <label for="profileImage" class="Pimage">
                            <img :src="imagePreview">
                            <div class="overlay">
                                + upload
                            </div>
                        </label>
                        <div class="avatar">
                            <label for="">
                                <input type="file" id="profileImage" @change="imageUrls" hidden>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="group">
                    <div class="single">
                        <label for="Contact Name">Fullname</label>
                        <input type="text" v-model="addForm.fullName" required placeholder="Fullname">
                    </div>
                    <div class="single">
                        <label for="ContactEmail">Username</label>
                        <input type="text" v-model="addForm.username" required placeholder="Username">
                    </div>
                </div>
                <div class="group">
                    <div class="single">
                        <label for="Contact Name">Email</label>
                        <input type="email" v-model="addForm.email" required placeholder="Email">
                    </div>
                    <div class="single">
                        <label for="Contact Name">Password</label>
                        <input type="password" required v-model="addForm.password" placeholder="password">
                    </div>
                    <div class="single">
                        <label for="ContactEmail">Mobile</label>
                        <input type="text" required v-model="addForm.mobile" placeholder="Mobile">
                    </div>
                </div>
                <div class="group">
                    <div class="single">
                        <label for="Contact Name">Job Title</label>
                        <input type="text" required v-model="addForm.jobTitle" placeholder="Job title">
                    </div>
                    <div class="single">
                        <label for="Contact Name">Gender</label>
                        <select required v-model="addForm.gender">
                            <option value=0>Male</option>
                            <option value=1>Female</option>
                        </select>
                    </div>
                </div>
                <h1 class="permissionheader">Permission</h1>
                <div class="group">
                    <div class="permission" v-for="permission in permissionData" :key="permission.code">
                        <input type="checkbox" id="Scorelibrary" :value="permission.code" v-model="addForm.permissions">
                        <label for="Scorelibrary">{{ permission.name }} </label>
                    </div>
                </div>
                <div class="action">
                    <button type="button" v-if="isRequesting">
                        <Spinner size="18"></Spinner>
                    </button>
                    <button v-if="!isRequesting">
                        <span>Save changes</span>
                    </button>
                    <button type="button" @click="backToadminList">Cancel</button>

                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import Logo from '../../../../../../src/assets/logo/adProfile.png'
export default {
    data() {
        return {
            toggleAddschool: false,
            imagePreview: `${Logo}`,
            isRequesting: false,
            permissionData: [],
            addForm: {
                fullName: '',
                username: '',
                password: '',
                email: '',
                mobile: '',
                gender: '',
                age: '',
                avatarBase64: '',
                permissions: []
            }
        }
    },
    components: {
        Spinner,
    },
    mounted() {
        let vm = this;
        vm.getpermission()
    },
    methods: {
        backToadminList() {
            let vm = this;
            vm.toggleAddschool = false;
            vm.$store.commit('VIEW_ADMIN_LIST')
        },
        handleSubmit() {
            let vm = this;
            if (vm.imagePreview != Logo) {
                vm.isRequesting = true
                vm.addForm.gender = +vm.addForm.gender
                vm.$store.dispatch('POST_ADD_ADMIN', vm.addForm).then((res) => {
                    vm.isRequesting = false
                    vm.$toast.success("Admin created!")
                    vm.$emit('triggered')
                    vm.backToadminList()
                }).catch((e) => {
                    vm.isRequesting = false;
                    console.log(e)
                    if (e.response.data.error) {
                        vm.$toast.warning(e.response.data.error)
                    } else {
                        vm.$toast.warning("Something went wrong")
                    }

                })
            } else {
                vm.$toast.warning("Profile Image is required!")
            }

        },
        imageUrls(e) {
            e.preventDefault();
            let vm = this;
            let file = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function () {
                vm.addForm.avatarBase64 = reader.result.split(',')[1]
            }
            reader.readAsDataURL(file);
            vm.imagePreview = URL.createObjectURL(file)
        },
        getpermission() {
            let vm = this;
            vm.$store.dispatch('GET_PERMISSION').then((res) => {
                vm.permissionData = res.data.permissions
            }).catch((e) => {

            })
        }
    },
}
</script>

<style lang="scss" scoped>
@import './../stylesConst/fonts.scss';

.umgt-add-channel {
    padding-left: 30px;
    padding-right: 30px;

    h1 {
        color: $secondary_font_color;
        font-weight: 600;
        text-align: center;
        font-size: 16px;
        margin-bottom: 40px;
        margin-top: 40px;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;

        i {
            cursor: pointer
        }
    }

    .form {
        .permissionheader {
            color: $primary_font_color;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 0;
        }

        .single {

            position: relative;

            label {
                display: block;
                font-size: 18px;
                color: $primary_font_color;
                font-weight: bold;
                margin-bottom: 15px;

            }

            button {
                margin-bottom: 50px;
                padding: 10px;
                width: 200px;
                text-align: start;
                cursor: pointer;

            }

            table {
                width: 300px;
                max-height: 200px !important;
                overflow-y: auto;
                font-size: 14px;

                tr:nth-child(odd) {
                    background: rgb(232, 232, 232);
                }

                tr {
                    td {
                        padding: 8px 4px;
                    }
                }
            }

            .schoolAdd {
                height: 200px;
                width: 250px;
                overflow-y: auto;
                overflow-x: hidden;
                border-radius: 4px;
                position: absolute;
                top: 40px;
                left: 200px;
                z-index: 100;
                background: white;
                box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);

                .search {
                    width: 100%;
                    height: 32px;
                    display: flex;
                    padding: 4px;
                    border-bottom: 1px solid rgb(232, 232, 232);
                    position: sticky;
                    top: 0;
                    background: white;

                    i {
                        padding-left: 4px;
                    }

                    input {
                        width: 100%;
                        padding-left: 2px;
                        height: 100%;
                        border: none;
                        font-size: 16px;

                    }
                }

                ul {
                    list-style: none;
                    padding: 4px;

                    li {
                        padding: 4px 6px;
                        font-size: 16px;

                        &:hover {
                            background: $primary_hover_color;
                        }
                    }
                }
            }
        }

        .action {
            display: flex;
            column-gap: 10px;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 40px;

            button {
                padding: 8px;
                width: 120px;
                border-radius: 4px;
                background: $primary_bg_color;
                font-weight: bold;
                color: $secondary_font_color;
                cursor: pointer;

                &:last-of-type {
                    background: rgb(232, 232, 232);
                }

                border: none;
            }
        }

        .group {
            display: flex;
            flex-wrap: wrap;
            column-gap: 30px;

            .permission {
                font-size: 14px;

                input {
                    margin-right: 7px;
                }
            }
        }

        input {
            padding: 10px;
            border: none;
            outline: none;
            margin-bottom: 50px;
            border: 1px solid rgb(205, 205, 205);
            cursor: pointer;


            &:focus {
                border: 1px solid $primary_bg_color;

            }


        }

        select {
            padding: 10px;
            border: none;
            outline: none;
            width: 200px;
            border-radius: 4px;
            cursor: pointer;
            border: 1px solid rgb(205, 205, 205);

            &:focus {
                border: 1px solid $primary_bg_color;

            }

            option {
                padding: 10px 2px;
            }
        }
    }
}

.statusCheck {
    display: flex;
    justify-content: center;

    label {
        height: 20px;
        width: 40px;
        background: rgb(194, 194, 194);
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        div {
            height: 20px;
            width: 20px;
            background: rgb(235, 233, 233);
            border-radius: 50%;
            position: relative;
            right: 0;
            transition: all 0.2s ease;

        }

    }

    input {
        visibility: hidden;
    }
}

.special {
    background: $primary_bg_color !important;

    div {
        right: -20px !important;
    }
}

.Pimage {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: 0.5s ease;
    cursor: pointer;

    &:hover {
        .overlay {
            top: 0;
        }
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
        object-position: center;
    }

    .overlay {
        position: absolute;
        height: 80px;
        margin-bottom: -80px;
        width: 80px;
        background: rgba(0, 0, 0, 0.463);
        z-index: 11;
        display: grid;
        place-content: center;
        font-size: 12px;
    }
}

label#uploadimage {
    cursor: pointer
}
</style>