<template>
  <div class="Loading">
    <div class="loading-spinner">
      <div class="loading-spinner__circle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  methods: {
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
$EEskyblue: #3dd1d1;
.Loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid $EEskyblue;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
</style>