var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ScoreLibrarySidebar"},[_c('div',{staticClass:"sidebar-widget",class:_vm.navCollapse ? 'collapsed' : ''},[_c('div',{staticClass:"sidebar-dialog"},[_c('router-link',{staticClass:"img-section",attrs:{"to":{ name: 'Dashboard' }}},[(_vm.user.fileName)?_c('img',{attrs:{"src":_vm.$store.state.assets_base_url + _vm.user.fileName}}):_c('img',{attrs:{"src":"/img/stamp.png","alt":"Avatar"}})]),_c('div',{staticClass:"links-section"},[_c('ul',{staticClass:"links"},[(_vm.checkPermission('SL'))?_c('li',{class:_vm.activeLink == 'score-library' ? 'active' : ''},[_c('router-link',{attrs:{"to":{ name: 'ScoreLibraryDashboard' }}},[_c('img',{attrs:{"src":_vm.activeLink == 'score-library'
                    ? '/img/library/White/score.png'
                    : '/img/library/Grey/score.png',"alt":""}}),_c('label',[_vm._v("Score library")])])],1):_vm._e(),(_vm.checkPermission('UM'))?_c('li',{class:_vm.activeLink == 'user-management' ? 'active' : ''},[_c('router-link',{attrs:{"to":{ name: 'user-management' }}},[_c('img',{attrs:{"src":_vm.activeLink == 'user-management'
                    ? '/img/library/White/user.png'
                    : '/img/library/Grey/user.png',"alt":""}}),_c('label',[_vm._v("User Management")])])],1):_vm._e(),(_vm.checkPermission('DA'))?_c('li',{class:_vm.activeLink == 'analytics' ? 'active' : ''},[_c('router-link',{attrs:{"to":{
                name: 'AnalyticsOverview',
                params: { item: 'overview' },
              }}},[_c('img',{attrs:{"src":_vm.activeLink == 'analytics'
                    ? '/img/library/White/analytics.png'
                    : '/img/library/Grey/analytics.png',"alt":""}}),_c('label',[_vm._v("Data Analytics")])])],1):_vm._e(),(_vm.checkPermission('ES'))?_c('li',{class:_vm.activeLink == 'support' ? 'active' : ''},[_c('router-link',{attrs:{"to":"#"}},[_c('img',{attrs:{"src":_vm.activeLink == 'support'
                    ? '/img/library/White/support.png'
                    : '/img/library/Grey/support.png',"alt":""}}),_c('label',[_vm._v("Support")])])],1):_vm._e(),_c('li',{class:_vm.activeLink == 'settings' ? 'active' : ''},[_c('router-link',{attrs:{"to":{ name: 'AdminProfile' }}},[_c('img',{attrs:{"src":_vm.activeLink == 'settings'
                    ? '/img/library/White/settings.png'
                    : '/img/library/Grey/settings.png',"alt":""}}),_c('label',[_vm._v("Set Up")])])],1)])])],1),_c('div',{staticClass:"collapse-button",on:{"click":function($event){return _vm.collapseNavbar()}}},[_c('i',{staticClass:"fa-solid fa-chevron-left"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }