<template>
    <div class="umgt-add-subscriptions">
        <div class="generate_container">
            <h1><span @click="hideGenerate"><i class=" fa fa-angle-left"></i></span>
                Generate Subscription Keys
                <span></span>
            </h1>
            <div class="form">
                <form @submit.prevent="generateKeys" action="">
                    <div class="single">
                        <label for="Contact Name">Assign Key to*</label>
                        <select name="" required v-model="genForm.educatorId">
                            <option value="" default-selected selected disabled>Select school/teacher
                            </option>
                            <option v-for="edu in educatorsData" :value="edu.educatorId" :key="edu.educatorName">{{
                                edu.educatorName }}</option>
                        </select>
                    </div>
                    <div class="single">
                        <label for="Contact Name">Set Subscription Duration*</label>
                        <select name="" required v-model="genForm.expirationDays">
                            <option value="" selected disabled>Set duration
                            </option>
                            <option :value="1 * 366">1 year
                            </option>
                            <option :value="2 * 366">2 year
                            </option>
                            <option :value="3 * 366"> 3 year
                            </option>
                        </select>
                    </div>
                    <div class="single">
                        <label for="Contact Name">Enter number of keys to generate (max: 100)*</label>
                        <input type="number" v-model="genForm.numberOfKeys" min="1" max="100" required placeholder="--" />
                    </div>
                    <div class="single" v-if="!showGenKeys">
                        <Button class="generate">Generate Subscription Keys</Button>
                    </div>
                    <p class="generated" v-if="showGenKeys">Subscription keys generated</p>
                </form>
                <div class="single" v-if="showGenKeys">
                    <div class="keys" v-if="genKeys.length">
                        <div v-for="keyg in genKeys" :key="keyg">{{ keyg }}</div>
                    </div>
                    <div class="keys" v-else>
                        <div>
                            <Spinner size="18"></Spinner>
                        </div>
                    </div>
                </div>
                <div class="close-btns" v-if="showGenKeys">
                    <button class="close" @click="hideGenerate">Close</button>
                    <button class="copy" @click="copyKeys()">Copy list</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner';
export default {
    data() {
        return {
            toggleAddschool: false,
            educatorsData: [],
            isLoading: true,
            showGenKeys: false,
            genKeys: [],
            genForm: {
                educatorId: '',
                expirationDays: '',
                numberOfKeys: 0
            }
        }
    },
    components: {
        Spinner
    },
    mounted() {
        let vm = this;
        vm.getAllEducators()
    },
    methods: {
        hideGenerate() {
            let vm = this;
            vm.$store.commit('HIDE_GENERATE_SUBSCRIPTIONS')
        },
        getAllEducators(id = 0) {
            let vm = this;
            vm.educatorsData = []
            vm.$store.dispatch('POST_GET_EDUCATORS', {
                "Page": 1,
                "Size": 100,
                "OrganizationId": id,
                "searchQuery": ""
            }).then(res => {
                vm.educatorsData = res.data.educators;
                console.log(vm.educatorsData)
                vm.isLoading = false;
            }).catch(e => {
                vm.isLoading = false;
            })
        },
        generateKeys() {
            let vm = this;
            vm.showGenKeys = true;
            vm.genForm.numberOfKeys = +vm.genForm.numberOfKeys
            vm.$store.dispatch('POST_KEYS_GENERATE', vm.genForm).then(res => {
                vm.genKeys = res.data.keys
                vm.$emit('listRefreshed')
            }).catch(e => {
                vm.isLoading = false
                vm.$toast.warning("Something went wrong!")
            }
            )

        },
        copyKeys() {
            let vm = this;
            let copyText = document.createElement('textarea');
            copyText.value = vm.genKeys.join('\n');
            document.body.appendChild(copyText);
            copyText.select();
            document.execCommand("copy");
            document.body.removeChild(copyText);
            vm.$toast.success("Copied to clipboard!")
        }
    },
}
</script>

<style lang="scss" scoped>
@import './../stylesConst/fonts.scss';

.umgt-add-subscriptions {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    place-content: center;
    background: rgba(0, 0, 0, 0.344);
    z-index: 10001;

    .generate_container {
        width: 400px;
        background: white;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 15px;


        h1 {
            color: $secondary_font_color;
            font-weight: 600;
            text-align: center;
            font-size: 14px;
            margin-bottom: 40px;
            margin-top: 40px;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;

            i {
                cursor: pointer
            }
        }

        .form {

            .single {

                position: relative;

                .keys {
                    width: 100%;
                    height: 200px;
                    overflow: hidden;
                    overflow-y: auto;
                    background: rgba(82, 82, 82, 0.516);
                    border-radius: 4px;
                    margin-bottom: 14px;
                    padding: 10px;
                    color: white;

                    div {
                        margin-bottom: 5px;
                        font-size: 14px;
                    }
                }

                button.generate {
                    width: 100%;
                    border: none;
                    border-radius: 4px;
                    background: $primary_bg_color;
                    display: grid;
                    place-content: center;
                    font-weight: 600;
                    margin-bottom: 14px;
                }

                label {
                    display: block;
                    font-size: 14px;
                    color: $primary_font_color;
                    font-weight: bold;
                    margin-bottom: 10px;

                }

                button {
                    margin-bottom: 50px;
                    padding: 10px;
                    width: 200px;
                    text-align: start;
                    cursor: pointer;

                }

                table {
                    width: 300px;
                    max-height: 200px !important;
                    overflow-y: auto;
                    font-size: 14px;

                    tr {
                        td {
                            padding: 8px 4px;
                        }
                    }
                }

                .schoolAdd {
                    height: 200px;
                    width: 250px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    border-radius: 4px;
                    position: absolute;
                    top: 40px;
                    left: 200px;
                    z-index: 100;
                    background: white;
                    box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);

                    .search {
                        width: 100%;
                        height: 32px;
                        display: flex;
                        padding: 4px;
                        border-bottom: 1px solid rgb(232, 232, 232);
                        position: sticky;
                        top: 0;
                        background: white;

                        i {
                            padding-left: 4px;
                        }

                        input {
                            width: 100%;
                            padding-left: 2px;
                            height: 100%;
                            border: none;
                            font-size: 16px;

                        }
                    }

                    ul {
                        list-style: none;
                        padding: 4px;

                        li {
                            padding: 4px 6px;
                            font-size: 16px;

                            &:hover {
                                background: $primary_hover_color;
                            }
                        }
                    }
                }
            }

            .action {
                display: flex;
                column-gap: 10px;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 40px;

                button {
                    padding: 8px;
                    width: 120px;
                    border-radius: 4px;
                    background: $primary_bg_color;
                    font-weight: bold;
                    color: $secondary_font_color;
                    cursor: pointer;

                    &:last-of-type {
                        background: rgb(232, 232, 232);
                    }

                    border: none;
                }
            }

            .group {
                display: flex;
                flex-wrap: wrap;
                column-gap: 30px;

                .permission {
                    font-size: 14px;

                    input {
                        margin-right: 7px;
                    }
                }
            }

            input {
                padding: 10px;
                border: none;
                outline: none;
                margin-bottom: 10px;
                border: 1px solid rgb(205, 205, 205);
                cursor: pointer;
                margin-bottom: 12px;

                &:focus {
                    border: 1px solid $primary_bg_color;

                }


            }

            select {
                padding: 10px;
                border: none;
                outline: none;
                width: 200px;
                border-radius: 4px;
                margin-bottom: 12px;
                cursor: pointer;
                border: 1px solid rgb(205, 205, 205);

                &:focus {
                    border: 1px solid $primary_bg_color;

                }

                option {
                    padding: 10px 2px;
                }
            }
        }
    }


}

.statusCheck {
    display: flex;
    justify-content: center;

    label {
        height: 20px;
        width: 40px;
        background: rgb(194, 194, 194);
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        div {
            height: 20px;
            width: 20px;
            background: rgb(235, 233, 233);
            border-radius: 50%;
            position: relative;
            right: 0;
            transition: all 0.2s ease;

        }

    }

    input {
        visibility: hidden;
    }
}

.special {
    background: $primary_bg_color !important;

    div {
        right: -20px !important;
    }
}
.generated{
    font-size: 13px;
    margin-bottom: 7px;
}
.close-btns{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    button{
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: 14px;
        color: black;
        font-weight: 600;
        padding: 5px 20px;
        &.copy{
            background: $primary_bg_color;
            color: white;
            border-radius: 3px;
        }
        &.close{
            padding: 0;
        }
    }
}
</style>