import {
  AXIOS_POST,
  AXIOS_GET,
  AXIOS_PUT,
  AXIOS_DELETE,
  AXIOS_POST_FILE,
} from "../../Config/Axios_EELibrary/axios.js";
import { UPLOAD_FILE } from "./../actions/upload.js";
import { UPLOAD_FILE_URL } from "./../Variables.js";

let token = localStorage.getItem("EEadmin_token");

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [UPLOAD_FILE]: ({state, rootState}, payload) => {
    return AXIOS_POST_FILE(UPLOAD_FILE_URL, payload, rootState.token);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
