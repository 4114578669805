<template>
    <div class="umgt-nav-container">
        <div class="hideshow" @click="$emit('hideLeftNav')">
            <i class="fa fa-angle-left"></i>
        </div>
        <div class="umgt-logo-container">
            <img src="../../../../../assets/logo/stamp.png" alt="">
        </div>
        <ul class="umgt-ul">
            <li class="umgt-li activeli">
                <router-link :to="{ name: 'user-management' }"><i class="fa fa-gear"></i>
                    <p>User management</p>
                </router-link>
            </li>
            <li class="umgt-li">
                <router-link :to="{ name: 'AnalyticsOverview' }">
                    <i class="fa fa-chart-line"></i>
                    <p>Analytics</p>
                </router-link>
            </li>
            <li class="umgt-li">
                <router-link :to="{ name: 'ScoreLibraryDashboard' }">
                    <i class="fa-solid fa-music"></i>
                    <p>Score Library</p>
                </router-link>
            </li>
            <li class="umgt-li">
                <router-link :to="{ name: 'AdminProfile' }">
                    <i class="fa fa-user"></i>
                    <p>Profile</p>
                </router-link>
            </li>
            <li class="umgt-li">
                <router-link :to="{ name: 'manage-subscription' }">
                    <i class="fa-solid fa-key"></i>
                    <p>Key management</p>
                </router-link>
            </li>
        </ul>

    </div>
</template>

<script>

export default {

}
</script>

<style lang="scss" scoped>
@import './stylesConst/fonts.scss';

.umgt-nav-container {
    width: 80px;
    border-radius: 8px;
    background: $primary_bg_color;
    height: 95%;
    padding-left: 6px;
    padding-right: 6px;
    position: relative;

    .umgt-logo-container {
        height: 200px;
        display: grid;
        place-content: center;

        img {
            width: 70px;
            object-fit: cover;
        }
    }

    .umgt-ul {
        list-style: none;
        text-align: center;
        display: grid;
        height: calc(100% - 200px);
        font-size: 18px;



        .umgt-li {
            border-top: 1px solid gray;
            display: grid;
            place-content: center;
            font-size: 20px;

            a {
                text-decoration: none;
                color: $secondary_font_color
            }
        }
    }

    .hideshow {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: grid;
        place-content: center;
        background: white;
        color: rgb(176, 174, 174);
        position: absolute;
        top: 50%;
        right: -20px;
        z-index: 10000;
        border: 1px solid rgb(176, 174, 174);
        cursor: pointer;
        animation: IconRote 1s ease 0.2s forwards;
        transition: 0.3s ease;

        &:hover {
            background: #ffd2d5;
            color: #000;
        }

        @keyframes IconRote {
            0% {
                transform: rotate(180deg);
            }

            100% {
                transform: rotate(0deg);
            }
        }
    }
}

li {
    p {
        font-size: 10px !important
    }
}

.activeli {
    background: #ffd2d5;
    margin-left: -5px;
    margin-right: -5px;


}
</style>