<template>
  <div class="Forgot">
    <div class="login-wrapper">
      <div class="left-section">
        <div class="left-inner">
          <img
            alt="Vue logo"
            :src="require('@/assets/logo/EEmusic-Logo.png')"
          />
          <!-- <label>Forgot Password?</label> -->
        </div>
      </div>
      <div class="right-section" v-if="forgot == 1">
        <label for class="title">Forgot Password?</label>
        <p class="sub-p">No worries, we'll send you reset instructions.</p>
        <form action class="login" @submit.prevent="sendEmail()">
          <!-- //input for username and password with icons in inputs -->
          <div class="input-wrapper">
            <div class="input-icon">
              <i class="fas fa-user"></i>
            </div>
            <input type="text" placeholder="Email" v-model="user.email" />
          </div>
          <button class="login-btn">Reset Password</button>
          <div class="forgot-password">
            <router-link :to="{ name: 'Login' }">Back to log in</router-link>
          </div>
        </form>
      </div>
      <div class="right-section" v-if="forgot == 2">
        <label for class="title">Check your email</label>
        <p class="sub-p">We sent a password reset link to {{ user.email }}</p>
        <form action class="login">
          <!-- open email app to check for email -->
          <button class="login-btn" @click.prevent="login()">
            Open email app
          </button>
          <p class="resend">
            Didn't receive the email?
            <a href @click.prevent="resendEmail()">Click to resend</a>
          </p>
          <div class="forgot-password">
            <router-link :to="{ name: 'Login' }">Back to log in</router-link>
          </div>
        </form>
      </div>
      <div class="right-section" v-if="forgot == 3">
        <label for class="title">Set new password</label>
        <p class="sub-p">
          Your new password must be different to previously used password.
        </p>
        <form action class="login" @submit.prevent="setPasssword()">
          <!-- //input for username and password with icons in inputs -->
          <div class="input-wrapper">
            <div class="input-icon">
              <i class="fas fa-lock"></i>
            </div>
            <input
              type="password"
              placeholder="Password"
              v-model="user.npassword"
            />
          </div>
          <div class="input-wrapper">
            <div class="input-icon">
              <i class="fas fa-lock"></i>
            </div>
            <input
              type="password"
              placeholder="Confirm password"
              v-model="user.cpassword"
            />
          </div>
          <button class="login-btn">Reset Password</button>
          <div class="forgot-password">
            <router-link :to="{ name: 'Login' }">Back to log in</router-link>
          </div>
        </form>
      </div>
      <div class="right-section" v-if="forgot == 4">
        <label for class="title">Password reset</label>
        <p class="sub-p">
          Your password has been successfully reset.<br />Click below to log in
          magically
        </p>
        <form action class="login">
          <!-- //input for username and password with icons in inputs -->
          <div class="input-wrapper" v-show="false">
            <div class="input-icon">
              <i class="fas fa-user"></i>
            </div>
            <input type="text" placeholder="Email" v-model="user.email" />
          </div>
          <button class="login-btn" @click.prevent="login()">Continue</button>
          <div class="forgot-password">
            <router-link :to="{ name: 'Login' }">Back to log in</router-link>
          </div>
        </form>
      </div>
    </div>
    <AdminRegFooter />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Forgot",
  components: {},
  data() {
    return {
      user: {
        email: "",
      },
      loggedUser: {},
      showNotification: false,
      notificationText: "",
      forgot: 1,
    };
  },
  methods: {
    setPasssword() {
      if (this.user.npassword && this.user.cpassword) {
        if (this.user.npassword != this.user.cpassword) {
          this.$showNotification("Passwords do not match");
          return;
        }
        if (this.user.npassword.length < 8) {
          this.$showNotification("Password must be at least 8 characters");
          return;
        }
        this.$showNotification("Password reset successfully");
        this.forgot = 4;
      } else {
        this.$showNotification("Please fill all fields");
      }
    },
    sendEmail() {
      // prevent refresh
      if (this.user.email) {
        // this.$startLoader();
        this.$store
          .dispatch("SEND_PASSWORD_EMAIL", this.user)
          .then((res) => {
            this.forgot = 2;
            // this.$stopLoader();
            this.$showNotification("Email sent successfully");
          })
          .catch((err) => {
            console.log("f");
          });
      } else {
        this.$showNotification("Please fill the email field");
      }
    },
  },
  mounted() {
    // check if the token parameter is present in the url
    if (this.$route.params.token) {
      this.forgot = 3;
    } else {
      this.forgot = 1;
    }
  },
};
</script>
<style lang="scss" scoped>
.Forgot {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .login-wrapper {
    margin-top: 10%;
    width: 800px;
    display: grid;
    grid-template-columns: 50% 50%;
    background: #d9d9d9;
    box-shadow: -1px 10px 20px 3px rgb(240, 240, 240);
    border-radius: 10px;
    overflow: hidden;
    @media screen and (max-width: 800px) {
      width: 100%;
      grid-template-columns: 100%;
      width: 90%;
    }
    .left-section {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      .left-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;
        @media screen and (max-width: 800px) {
          row-gap: 10px;
        }
        img {
          width: 120px;
          height: 100px;
          object-fit: contain;
          @media screen and (max-width: 800px) {
            width: 100px;
            height: 80px;
          }
        }

        label {
          color: #000;
          font-size: 2rem;
          font-weight: 200;
          margin-top: 10px;
          @media screen and (max-width: 800px) {
            font-size: 1.5rem;
            margin-top: 0;
          }
        }
      }
    }

    .right-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 4rem;

      .title {
        font-size: 2rem;
        font-weight: 700;
        margin-top: 2rem;
        @media screen and (max-width: 800px) {
          font-size: 1.5rem;
        }
      }
      .sub-p {
        margin-bottom: 3.5rem;
        padding: 0 1.5rem;
        font-size: 0.8rem;
        @media screen and (max-width: 800px) {
          font-size: 0.8rem;
          margin-bottom: 1rem;
          padding: 0 1rem;
        }
      }
      .resend {
        padding: 0 1.5rem;
        font-size: 0.8rem;
        @media screen and (max-width: 800px) {
          font-size: 0.8rem;
        }
      }

      .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;

        .input-wrapper {
          display: flex;
          align-items: center;
          width: 300px;
          height: 40px;
          border-radius: 40px;
          background-color: #fff;
          box-shadow: 0px 0px 10px 0px rgba(205, 205, 205, 0.75);
          overflow: hidden;

          .input-icon {
            width: 45px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 5px 0 0 5px;
            padding-left: 10px;

            i {
              color: rgb(170, 170, 170);
            }
          }

          input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            border-radius: 40px;
            padding-left: 10px;
            font-size: 0.9rem;
            font-weight: 200;
          }
        }

        .forgot-password {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-top: 0.4rem;

          @media screen and (max-width: 800px) {
            margin-top: 0.25rem;
          }
          a {
            text-decoration: none;
            color: #444;
            font-size: 0.75rem;
            font-weight: 200;
          }
        }

        .login-btn {
          width: 300px;
          padding: 15px;
          border: none;
          outline: none;
          border-radius: 40px;
          background-color: #777;
          color: #fff;
          font-size: 0.8rem;
          font-weight: 600;
          cursor: pointer;
        }

        .remember-me {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-start;

          input {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            border: 1px solid #000;
          }

          label {
            font-size: 0.8rem;
            font-weight: 200;
            color: #444;
          }
        }
      }
    }
  }
}
</style>
