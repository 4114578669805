<template>
  <div class="DetailedAnalytics">
    <div class="analytics-content">
      <div class="analytic-dashes">
        <div class="page-jumbotron">
          <p class="analytic-title">Detailed Statistics</p>
          <form action="">
            <label for="">View By</label>
            <select name="" id="" v-model="anaalyticsView">
              <option value="Pie">Pie Chart</option>
              <option value="Line">Line Chart</option>
            </select>
          </form>
        </div>
        <div class="dash-items">
          <div class="analytic-dash">
            <label for="title" class="title">Age</label>
            <div class="stats">
              <PieChart
                :series="AgeSeries"
                :labels="AgeLabels"
                v-if="anaalyticsView == 'Pie'"
              />
              <LineChart
                :series="{ data: AgeSeries, name: 'Age' }"
                :labels="AgeLabels"
                v-if="anaalyticsView == 'Line'"
              />
            </div>
          </div>
          <div class="analytic-dash">
            <label for="title" class="title">Current Grade statistics</label>
            <div class="stats">
              <PieChart
                :series="GradeSeries"
                :labels="GradeLabels"
                v-if="anaalyticsView == 'Pie'"
              />
              <LineChart
                :series="{ data: GradeSeries, name: 'Grade' }"
                :labels="GradeLabels"
                v-if="anaalyticsView == 'Line'"
              />
            </div>
          </div>
          <div class="analytic-dash">
            <label for="title" class="title">Avg Usage Frequency</label>
            <div class="stats">
              <PieChart
                :series="UsageFrequencySeries"
                :labels="UsageFrequencyLabels"
                v-if="anaalyticsView == 'Pie'"
              />
              <LineChart
                :series="{ data: UsageFrequencySeries, name: 'Freq' }"
                :labels="UsageFrequencyLabels"
                v-if="anaalyticsView == 'Line'"
              />
            </div>
          </div>
          <div class="analytic-dash">
            <label for="title" class="title">Avg Session Length</label>
            <div class="stats">
              <PieChart
                :series="SessionLengthSeries"
                :labels="SessionLengthLabels"
                v-if="anaalyticsView == 'Pie'"
              />
              <LineChart
                :series="{ data: SessionLengthSeries, name: 'Length' }"
                :labels="SessionLengthLabels"
                v-if="anaalyticsView == 'Line'"
              />
            </div>
          </div>
          <div class="analytic-dash">
            <label for="title" class="title">Avg Stage Completed</label>
            <div class="stats">
              <PieChart
                :series="StageCompletedSeries"
                :labels="StageCompletedLabels"
                v-if="anaalyticsView == 'Pie'"
              />
              <LineChart
                :series="{ data: StageCompletedSeries, name: 'Stages' }"
                :labels="StageCompletedLabels"
                v-if="anaalyticsView == 'Line'"
              />
            </div>
          </div>
          <div class="analytic-dash">
            <label for="title" class="title">User Dropped within</label>
            <div class="stats">
              <PieChart
                :series="UserDroppedSeries"
                :labels="UserDroppedLabels"
                v-if="anaalyticsView == 'Pie'"
              />
              <LineChart
                :series="{ data: UserDroppedSeries, name: 'Duration' }"
                :labels="UserDroppedLabels"
                v-if="anaalyticsView == 'Line'"
              />
            </div>
          </div>

          <div class="analytic-dash">
            <label for="title" class="title">Renewal users</label>
            <div class="stats">
              <PieChart
                :series="RenewalUsersSeries"
                :labels="RenewalUsersLabels"
                v-if="anaalyticsView == 'Pie'"
              />
              <LineChart
                :series="{ data: RenewalUsersSeries, name: 'Renewal' }"
                :labels="RenewalUsersLabels"
                v-if="anaalyticsView == 'Line'"
              />
            </div>
          </div>
          <div class="analytic-dash">
            <label for="title" class="title">
              Target users Paid user / user pool
            </label>
            <div class="stats">
              <PieChart
                :series="TargetUsersSeries"
                :labels="TargetUsersLabels"
                v-if="anaalyticsView == 'Pie'"
              />
              <LineChart
                :series="{ data: TargetUsersSeries, name: 'Target' }"
                :labels="TargetUsersLabels"
                v-if="anaalyticsView == 'Line'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import { Label } from "opensheetmusicdisplay";
export default {
  name: "DetailedAnalytics",
  components: {
    PieChart,
    LineChart,
  },
  data() {
    return {
      anaalyticsView: "Pie",
      AgeSeries: [44, 55, 13, 43, 22, 13, 43, 22],
      AgeLabels: [
        "3y.o",
        "4y.o",
        "5y.o",
        "6y.o",
        "7y.o",
        "8y.o",
        "9y.o",
        "10y.o",
      ],
      GradeSeries: [
        // 10 numbers that sum to 100
        200, 322, 423, 232, 765, 234, 543, 233, 543,
      ],
      GradeLabels: [
        "Pre-Grade",
        "Grade 1",
        "Grade 2",
        "Grade 3",
        "Grade 4",
        "Grade 5",
        "Grade 6",
        "Grade 7",
        "Grade 8",
      ],
      UsageFrequencySeries: [30, 60, 10],
      UsageFrequencyLabels: ["1-2 days", "3-4 days", "5-6 days"],
      SessionLengthSeries: [20, 30, 10, 40],
      SessionLengthLabels: ["1-10 min", "10-20 min", "30-40 min", ">40 min"],
      StageCompletedSeries: [20, 30, 10, 40, 32, 23],
      StageCompletedLabels: [
        "1-4 stages",
        "5-7 stages",
        "8-10 stages",
        "11-15 stages",
        "16-20 stages",
        ">20 stages",
      ],
      UserDroppedSeries: [20, 30, 10, 40, 32],
      UserDroppedLabels: [
        "1 month",
        "3 months",
        "6 months",
        "9 months",
        "12 months",
      ],
      RenewalUsersSeries: [20, 30, 10, 40, 32, 23],
      RenewalUsersLabels: [
        "After Trial",
        "1st year",
        "2nd year",
        "3rd year",
        "4th year",
        "5th year",
      ],
      TargetUsersSeries: [20, 30, 10, 40],
      TargetUsersLabels: [
        "Beginner",
        "Below Grade 3",
        "Grade 4-6",
        "Grade 7-10",
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.page-jumbotron {
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  form {
    display: flex;
    align-items: center;
    column-gap: 10px;
    label {
      font-size: 16px;
      font-weight: 500;
      display: block;
    }
    select {
      width: 200px;
      height: 30px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px;
    }
  }
}
</style>