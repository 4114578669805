<template>
    <div class="umgt-edit-channel">
        <h1>
            <span @click="backToStudentListtab"><i class=" fa fa-angle-left"></i></span>
            Edit User Information #{{ StudentID.id }}
            <span></span>
        </h1>
        <div class="form">

            <Spinner v-if="isLoading" size="60"></Spinner>

            <form v-else @submit.prevent="SendEditInfo()">
                <div class="group">
                    <div class="single">
                        <label for="Contact Name">Account Name</label>
                        <input type="text" v-model="editForm.fullname" placeholder="Jaydon George">
                    </div>
                    <div class="single">
                        <label for="ContactNo">Account Mobile No.</label>
                        <input type="text" v-model="editForm.contactNumber" placeholder="98988">
                    </div>
                    <div class="single">
                        <label for="ContactEmail">Account Email</label>
                        <input type="email" v-model="editForm.contactEmail" placeholder="example@gmail.com">
                    </div>
                </div>
                <div class="group">
                    <div class="single">
                        <label for="Contact Name">Subscription ID</label>
                        <input type="text" disabled :value="editForm.subscriptionCode" placeholder="4775-7454-7443-4433">
                    </div>
                    <div class="single">
                        <label for="Contact Name">Region</label>
                        <select name="" v-model="editForm.region" required id="">
                            <option v-for="Region in RegionList" :key="Region.code" :value="Region.region">{{ Region.region
                            }}
                            </option>
                        </select>
                    </div>
                    <div class="single">
                        <label for="ContactEmail">Status</label>
                        <p class="statusText">Active on: <input type="date" class="dateInput" required disabled
                                v-model="editForm.activeBy">
                        </p>
                        <p class="statusText" contenteditable="true">Expires on: <input type="date" required
                                class="dateInput" v-model="editForm.expireBy">
                        </p>
                    </div>
                </div>
                <div class="group">
                    <div class="single">
                        <label for="Contact Name">Student 1 Profile Name</label>
                        <input type="text" v-model="editForm.profile1.profileName" placeholder="Profile 1">
                    </div>
                    <div class="single">
                        <label for="ContactNo">Student 2 Profile Name</label>
                        <input type="text" v-model="editForm.profile2.profileName"
                            :disabled="editForm.profile2?.profileName == null ? true : false" placeholder="Profile 2">
                    </div>
                </div>
                <div class="single">
                    <label for="Contact Name">Link to School/Teacher</label>
                    <select name="" v-model="editForm.teacherId" id="">
                        <option :value="null">None</option>
                        <option v-for="educatorData in educatorsData" :key="educatorData.educatorId"
                            :value="educatorData.educatorId">{{ educatorData.educatorName
                            }}
                        </option>
                    </select>
                </div>
                <div class="single">
                    <label for="">Status</label>
                    <div class="statusCheck">
                        <label for="status" @click="userStatus" :class="userActive ? 'special' : ''">
                            <div></div>
                        </label>
                        <input type="checkbox" id="status">
                    </div>
                </div>
                <div class="action">
                    <button>
                        <Spinner size="18" v-if="isRequesting"></Spinner>
                        <span v-if="!isRequesting">Save changes</span>
                    </button>
                    <button @click="backToStudentListtab" type="button">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>


import Spinner from 'vue-simple-spinner'
import moment from "moment"
export default {
    components: {
        Spinner
    },
    data() {
        return {
            StudentID: this.$store.state.ControlTab.StudentTabSwitcher.editStudent.id,
            toggleLinkSchTteacher: false,
            RegionList: [],
            educatorsData: [],
            activeBy: '',
            isRequesting: false,
            isLoading: false,
            userActive: 0,
            expireBy: '',
            editForm: {
                UserId: '',
                fullname: '',
                contactNumber: '',
                contactEmail: '',
                contactName: 'contactname',
                subscriptionCode: '',
                subscriptionStatus: '',
                activeBy: '',
                expireBy: '',
                profile1: {
                    profileId: '',
                    profileName: ''
                },
                profile2: {
                    profileId: '',
                    profileName: ''
                },
                teacherId: '',
                region: '',
                status: ''
            }
        }
    },
    mounted() {
        let vm = this;
        vm.getEditInfo();
        vm.getRegions();
        vm.getAllEducators();
    },
    methods: {
        userStatus(e) {
            let vm = this;
            if (vm.userActive) {
                vm.userActive = 0
                e.target.classList.remove('special');
            } else {
                vm.userActive = 1;
                e.target.classList.add('special')
            }
        },
        getRegions() {
            let vm = this;
            vm.$store.dispatch('GET_REGIONS')
                .then(res => {
                    vm.RegionList = res.data.regions
                }).catch(e => {
                    // console.log(e)
                })
        },
        backToStudentListtab() {
            let vm = this;
            vm.$store.commit('VIEW_STUDENT_LIST')
        },
        getAllEducators() {
            let vm = this;
            vm.$store.dispatch('POST_GET_EDUCATORS', {
                "Page": 1,
                "Size": 100,
                "OrganizationId": 0,
                "searchQuery": ""
            }).then(res => {
                vm.educatorsData = res.data.educators;
            }).catch(e => {
                // console.log(e)
            })
        }
        ,
        getEditInfo() {
            let vm = this;
            vm.isLoading = true;
            vm.$store.dispatch('POST_USER_EDIT', { "studentId": vm.StudentID.id })
                .then(res => {
                    vm.userActive = res.data.student.status
                    vm.editForm = {
                        contactNumber: res.data.student.contactNumber,
                        contactEmail: res.data.student.contactEmail,
                        fullname: res.data.student.fullName,
                        UserId: res.data.student.userId,
                        activeBy: moment(res.data.student?.activeBy).utc().format('YYYY-MM-DD'),
                        expireBy: moment(res.data.student?.expireBy).utc().format('YYYY-MM-DD'),
                        contactName: 'contactname',
                        subscriptionCode: res.data.student.subscriptionCode,
                        profile1: {
                            profileId: res.data.student?.profile1?.profileId,
                            profileName: res.data.student?.profile1?.profileName
                        },
                        profile2: {
                            profileId: res.data.student?.profile2?.profileId,
                            profileName: res.data.student?.profile2?.profileName
                        },
                        teacherId: res.data.student.teacherId,
                        region: res.data.student.region,
                        status: res.data.student.status
                    }
                    vm.isLoading = false;
                }).catch(e => {
                    let vm = this;
                    console.log(e)
                    // vm.$toast.error('Something went wrong')
                })
        },
        SendEditInfo() {
            let vm = this;
            vm.isRequesting = true;
            vm.editForm.status = vm.userActive;
            vm.$store.dispatch('POST_USER_UPDATE', vm.editForm)
                .then(res => {
                    vm.$toast.success("User updated successful")
                    vm.isRequesting = false;
                    vm.$emit('triggered');
                    vm.backToStudentListtab();
                }).catch(e => {
                    vm.isRequesting = false;
                    vm.$toast.error("Something went wrong")
                })
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../stylesConst/fonts.scss';

.umgt-edit-channel {
    padding-left: 30px;
    padding-right: 30px;

    h1 {
        color: $secondary_font_color;
        text-align: center;
        font-size: 16px;
        margin-bottom: 40px;
        margin-top: 40px;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        justify-content: space-between;

        i {
            cursor: pointer
        }
    }

    .form {
        .single {

            position: relative;

            .channelReport {
                margin-top: -30px;
                font-size: 15px;
                margin-bottom: 32px;

                i {
                    margin-right: 8px;
                }

                span {
                    text-decoration: underline;
                }

                cursor: pointer;
            }

            label {
                display: block;
                font-size: 18px;
                color: $primary_font_color;
                font-weight: bold;
                margin-bottom: 15px;

            }

            button {
                margin-bottom: 50px;
                padding: 10px;
                width: 200px;
                text-align: start;
                cursor: pointer;

            }

            table {
                width: 300px;
                max-height: 200px !important;
                overflow-y: auto;
                font-size: 14px;

                tr:nth-child(odd) {
                    background: rgb(232, 232, 232);
                }

                tr {
                    td {
                        padding: 8px 4px;
                    }
                }
            }

            .schoolAdd {
                height: 200px;
                width: 250px;
                overflow-y: auto;
                overflow-x: hidden;
                border-radius: 4px;
                position: absolute;
                top: 40px;
                left: 200px;
                z-index: 100;
                background: white;
                box-shadow: 2px 3px 12px rgb(216, 216, 216), -2px -3px 12px rgb(216, 216, 216);

                .search {
                    width: 100%;
                    height: 32px;
                    display: flex;
                    padding: 4px;
                    border-bottom: 1px solid rgb(232, 232, 232);
                    position: sticky;
                    top: 0;
                    background: white;

                    i {
                        padding-left: 4px;
                    }

                    input {
                        width: 100%;
                        padding-left: 2px;
                        height: 100%;
                        border: none;
                        font-size: 16px;

                    }
                }

                ul {
                    list-style: none;
                    padding: 4px;

                    li {
                        padding: 4px 6px;
                        font-size: 16px;

                        &:hover {
                            background: $secondary_hover_color;
                        }
                    }
                }
            }
        }

        .action {
            display: flex;
            column-gap: 10px;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 40px;

            button {
                padding: 8px;
                width: 120px;
                border-radius: 4px;
                background: $primary_bg_color;
                font-weight: bold;
                color: $secondary_font_color;
                cursor: pointer;

                &:last-of-type {
                    background: rgb(232, 232, 232);
                }

                border: none;
            }
        }

        .group {
            display: flex;
            flex-wrap: wrap;
            column-gap: 30px;
        }

        input {
            padding: 10px;
            border: none;
            outline: none;
            margin-bottom: 50px;
            border: 1px solid rgb(205, 205, 205);
            cursor: pointer;

            &.dateInput {
                border: none !important;
                padding: 0 !important;
                margin: 0 !important;

            }


            &:focus {
                border: 1px solid $primary_bg_color;

            }


        }

        select {
            padding: 10px;
            border: none;
            outline: none;
            width: 200px;
            border-radius: 4px;
            cursor: pointer;
            border: 1px solid rgb(205, 205, 205);

            &:focus {
                border: 1px solid $primary_bg_color;

            }

            option {
                padding: 10px 2px;
            }
        }
    }
}

.statusCheck {

    label {
        height: 20px;
        width: 40px;
        background: rgb(194, 194, 194);
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        div {
            height: 20px;
            width: 20px;
            background: rgb(235, 233, 233);
            border-radius: 50%;
            position: relative;
            right: 0;
            transition: all 0.2s ease;

        }

    }

    input {
        visibility: hidden;
    }
}

.statusText {
    font-size: 14px;
    color: $secondary_font_color
}

.special {
    background: $primary_bg_color !important;

    div {
        right: -20px !important;
    }
}
</style>